export const bonusStatus = {
  credited: 1,
  pending: 20,
  pendingPrevious: 21,
  active: 30,
  wagering: 31,
  completed: 40,
  pendingCancellation: 41,
  pendingCancellationBonusTokenInUse: 42,
  expired: 50,
  canceled: 51,
  refused: 52,
  deleted: 100,
};

export const bonusTypes = {
  riskFree: 1,
  deposit: 2,
  freeSpins: 3,
  freeCash: 4,
};

export const bonusStatusTextsCommon = {
  [bonusStatus.credited]: "Credited",
  [bonusStatus.pending]: "Pending",
  [bonusStatus.active]: "Active",
  [bonusStatus.wagering]: "Active",
  [bonusStatus.completed]: "Completed",
  [bonusStatus.pendingCancellation]: "Pending Cancellation",
  [bonusStatus.pendingCancellationBonusTokenInUse]: "Pending Cancellation",
  [bonusStatus.expired]: "Expired",
  [bonusStatus.refused]: "Refused",
  [bonusStatus.canceled]: "Canceled",
};

export const bonusStatusTexts = {
  [bonusTypes.riskFree]: {},
  [bonusTypes.freeCash]: {},
  [bonusTypes.deposit]: {
    [bonusStatus.pending]: "Pending Activation",
  },
  [bonusTypes.freeSpins]: {},
};

export const getBonusStatusText = (type, status) => {
  return (
    bonusStatusTexts?.[type]?.[status] || bonusStatusTextsCommon[status] || "Unknown"
  );
};

export const isActiveBonus = (status) => {
  return [bonusStatus.active, bonusStatus.wagering].includes(status);
};
