import {
  WELCOME_BONUS_CLEAR,
  WELCOME_BONUS_ERROR,
  WELCOME_BONUS_LOADED,
  WELCOME_BONUS_LOADING,
} from "../actions/actionTypes";

const initialState = {
  list: [],
  loading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case WELCOME_BONUS_LOADING:
      return { ...state, loading: true };
    case WELCOME_BONUS_LOADED:
      return {
        ...state,
        loading: false,
        error: null,
        list: action?.payload?.data || [],
      };
    case WELCOME_BONUS_ERROR:
      return {
        ...state,
        loading: false,
        list: [],
        error: action?.payload?.error,
      };
    case WELCOME_BONUS_CLEAR:
      return initialState;
    default:
      return state;
  }
};
