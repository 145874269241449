import {
  BETS_CLEAR,
  BETS_COMPLETE_BET,
  BETS_LOADED,
  BETS_LOADING,
  BETS_LOAD_ERROR,
  BETS_PROGRESS,
  BETS_REMOVE_EVENT,
} from "actions/actionTypes";

const initialState = {
  loading: false,
  loaded: false,
  bets: [],
  error: null,
  progress: null,
  status: null,
  hidden: {},
};

export default (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case BETS_COMPLETE_BET:
      return {
        ...state,
        hidden: { ...state.hidden, [payload._id]: true },
        bets: state.bets.filter((x) => x._id !== payload._id),
        progress: null,
      };
    case BETS_LOADING: {
      if (payload.incremental) return state;
      const bets = state.status === payload.status ? state.bets : [];
      return { ...state, bets, loading: true, status: payload.status };
    }
    case BETS_LOADED:
      if (payload.status && payload.status !== state.status) return state;
      return {
        ...state,
        loading: payload.incremental ? state.loading : false,
        error: null,
        bets:
          payload.status === "pending" || payload.status === "pending_only"
            ? payload.bets.filter((x) => !state.hidden[x._id])
            : payload.bets,
        loaded: true,
      };
    case BETS_PROGRESS:
      return { ...state, progress: payload };
    case BETS_LOAD_ERROR:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: payload,
        progress: null,
      };
    case BETS_REMOVE_EVENT:
      return {
        ...state,
        bets: state.bets.filter((x) => x.event !== payload.eventId),
      };
    case BETS_CLEAR:
      return initialState;
    default:
      return state;
  }
};
