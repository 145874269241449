import {
  DIVIDENDS_CLAIM_ERROR,
  DIVIDENDS_CLAIM_PROGRESS,
  DIVIDENDS_ERROR,
  DIVIDENDS_LOADED,
  DIVIDENDS_LOADING,
  DIVIDENDS_PROGRESS,
  DIVIDENDS_SET_TOKEN_AMOUNT,
  DIVIDENDS_STAKE_ERROR,
  DIVIDENDS_STAKE_PROGRESS,
  DIVIDENDS_STAKE_RESET,
} from "actions/actionTypes";

const initialState = {
  loading: false,
  loaded: false,
  error: null,
  progress: null,
  data: null,
  tokenAmount: "",
  stakeProgress: null,
  stakeError: null,
  claimProgress: null,
  claimError: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DIVIDENDS_LOADING:
      return { ...state, loading: true };
    case DIVIDENDS_LOADED:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload,
        loaded: true,
      };
    case DIVIDENDS_ERROR:
      return {
        ...state,
        loading: false,
        progress: null,
        error: action.payload,
      };
    case DIVIDENDS_STAKE_ERROR:
      return {
        ...state,
        stakeProgress: null,
        stakeError: action.payload,
      };
    case DIVIDENDS_CLAIM_ERROR:
      return {
        ...state,
        claimProgress: null,
        claimError: action.payload,
      };
    case DIVIDENDS_PROGRESS:
      return { ...state, progress: action.payload };
    case DIVIDENDS_STAKE_PROGRESS:
      return { ...state, stakeError: null, stakeProgress: action.payload };
    case DIVIDENDS_CLAIM_PROGRESS:
      return { ...state, claimError: null, claimProgress: action.payload };
    case DIVIDENDS_SET_TOKEN_AMOUNT:
      return { ...state, tokenAmount: action.payload };
    case DIVIDENDS_STAKE_RESET:
      return { ...state, stakeError: null, stakeProgress: null };
    default:
      return state;
  }
};
