import { loadSport } from "actions/sportActions";

export default (dispatch, getState) => {
  setInterval(() => {
    const sports = getState().sports;
    const monitoring = sports.monitoring;
    // eslint-disable-next-line
    for (const id of Object.getOwnPropertyNames(monitoring)) {
      if ((monitoring[id] || 0) <= 0) continue;
      const sport = sports.byId[id];
      if (!sport || sport.updating) continue;
      dispatch(loadSport(id, false));
    }
  }, 20000);

  setInterval(() => {
    const sports = getState().sports;
    const monitoring = sports.liveMonitoring;
    // eslint-disable-next-line
    for (const id of Object.getOwnPropertyNames(monitoring)) {
      if ((monitoring[id] || 0) <= 0) continue;
      const sport = sports.byId[id];

      if (!sport || sport.liveLoading) continue;
      dispatch(loadSport(id, true));
    }
  }, 2000);
};
