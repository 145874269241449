import {
  USER_EXCLUDE_ERROR,
  USER_EXCLUDE_SAVED,
  USER_EXCLUDE_SAVING,
} from "actions/actionTypes";

const initialState = {
  error: null,
  loading: false,
  saved: false,
};

const excludeReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_EXCLUDE_SAVING:
      return { ...state, loading: true, error: null, saved: false };
    case USER_EXCLUDE_SAVED:
      return { ...state, loading: false, saved: true };
    case USER_EXCLUDE_ERROR:
      return { ...state, loading: false, error: action.payload?.error };
    default:
      return state;
  }
};

export default excludeReducer;
