import baccaratImage from "images/login/landing/coins/baccarat.png";
import plinkoImage from "images/login/landing/coins/plinko.png";
import pokerImage from "images/login/landing/coins/poker.png";
import provablyFairImage from "images/login/landing/coins/provably-fair.png";
import rouletteImage from "images/login/landing/coins/roulette.png";

export const landingCasinoGamesVariants = {
  baccarat: {
    image: baccaratImage,
  },
  poker: {
    image: pokerImage,
  },
  roulette: {
    image: rouletteImage,
  },
  plinko: {
    image: plinkoImage,
  },
  "provably-fair": {
    image: provablyFairImage,
  },
};
