export function find<T, K extends keyof any>(
  collection: T[] | Record<K, T>,
  predicate: (value: T, id: number | K, collection: T[] | Record<K, T>) => boolean
) {
  // Handle both arrays and objects

  if (Array.isArray(collection)) {
    for (let i = 0; i < collection.length; i++) {
      if (predicate(collection[i], i, collection)) {
        return collection[i];
      }
    }
  }

  const isObject = typeof collection === "object" && collection !== null;

  if (isObject) {
    // Handle object
    const objectCollection = collection as Record<K, T>;

    for (const key in objectCollection) {
      if (objectCollection.hasOwnProperty(key)) {
        const value = objectCollection[key];
        if (predicate(value, key, objectCollection)) {
          return value;
        }
      }
    }
  }

  return undefined;
}

export function isEmpty(value: any): boolean {
  if (value == null) return true;

  if (typeof value === "string" || Array.isArray(value)) {
    return value.length === 0;
  }

  if (typeof value === "object") {
    return Object.keys(value).length === 0;
  }

  return false;
}

export function debounce<F extends (...args: any[]) => void>(
  func: F,
  waitFor: number
): (...args: Parameters<F>) => void {
  let timeoutId: number | undefined;

  return function (...args: Parameters<F>) {
    if (timeoutId !== undefined) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => func(...args), waitFor) as unknown as number;
  };
}

export function get<T, Default>(
  object: T,
  path: string | string[],
  defaultValue: Default | undefined = undefined
): any | Default {
  // Convert the path to an array if it's a string
  const pathArray = Array.isArray(path) ? path : path.split(".").map((p) => p.trim());

  // Reduce the path array to get the final value
  const result = pathArray.reduce((acc: any, key: string) => {
    return acc && acc[key] !== undefined ? acc[key] : undefined;
  }, object);

  // Return the result if it's not undefined, otherwise return the default value
  return result !== undefined ? result : defaultValue;
}
