import { getAuthToken } from "api/api";
import DesktopLayout from "layout/desktop";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Redirect } from "react-router";
import { Route, Switch } from "react-router-dom";

import { getGameListCategories } from "selectors/casinoSelectors";

import { casinoAltCoins, getCategoriesWithCoins } from "constants/casino";

import CasinoPage from "pages/CasinoPage/index.ts";
import EventPage from "pages/EventPage/index.ts";
import FaqPage from "pages/FaqPage/index.ts";
import GamePage from "pages/GamePage/index.ts";
import HistoryPage from "pages/HistoryPage/index.ts";
import { landingCasinoGamesVariants } from "pages/LandingCasinoGamesPage/constants.ts";
import LandingCasinoGamesPage from "pages/LandingCasinoGamesPage/index.ts";
import LandingCoinsPage from "pages/LandingCoinsPage/index.ts";
import { landingVariants } from "pages/LandingPage/constants.ts";
import LandingPage from "pages/LandingPage/index.ts";
import LandingWithBonusesPage from "pages/LandingWithBonusesPage/index.ts";
import Page404 from "pages/Page404/index.ts";
import PromoPage from "pages/PromoPage/index.ts";
import RulesPage from "pages/RulesPage/index.ts";
import SportHomePage from "pages/SportHomePage";
import StaticPage from "pages/StaticPage/index.ts";

import { allowedLanguages } from "../constants/i18n";

const Routes = () => {
  const prefix = `/:language(${allowedLanguages.join("|")})?`;
  const categories = useSelector(getGameListCategories);
  const categoriesSlugs = [
    ...categories.map(({ slug }) => slug),
    "all-games",
    "favourite",
    "recent",
  ];

  return (
    <Switch>
      <Route path={`${prefix}/promotions/bonus`} component={LandingWithBonusesPage} />
      <Route
        path={`${prefix}/landing/casino-games/:variant(${Object.keys(
          landingCasinoGamesVariants
        ).join("|")})?`}
        component={LandingCasinoGamesPage}
      />
      <Route
        path={`${prefix}/landing/:variant(${Object.keys(landingVariants).join("|")})?`}
        component={LandingPage}
      />
      <DesktopLayout>
        <Switch>
          <Route exact path={"/404"} component={Page404} />
          <Route path={`${prefix}/activate/:type?/:code?`} component={() => null} />
          <Route path={`${prefix}/faq`} component={FaqPage} />
          <Route path={`${prefix}/promotion`} component={PromoPage} />
          <Route
            path={[`${prefix}/history`, `${prefix}/history/casino`]}
            component={HistoryPage}
          />
          <Route
            path={[`${prefix}/rules/:rulesCategory(sports|bonus)?`]}
            exact
            component={RulesPage}
          />
          <Route
            path={[
              `${prefix}/:staticSlug(faq)`,
              `${prefix}/:staticSlug(privacy-policy)`,
              `${prefix}/:staticSlug(become-affiliate)`,
              `${prefix}/:staticSlug(promo)`,
              `${prefix}/:staticSlug(responsible-gambling)`,
              `${prefix}/:staticSlug(about-us)`,
            ]}
            exact
            component={StaticPage}
          />
          <Route
            path={`${prefix}/casino/:casinoAltCoin(${casinoAltCoins.join("|")})`}
            exact
            component={getAuthToken() ? CasinoPage : LandingCoinsPage}
          />
          <Route
            path={[
              `${prefix}/casino`,
              `${prefix}/casino/:category(${categoriesSlugs.join("|")})`,
              `${prefix}/casino/:categoryWithAltCoin(${getCategoriesWithCoins(
                categoriesSlugs
              ).join("|")})`,
              `${prefix}/casino/:provider`,
              `${prefix}/casino/:category(${categoriesSlugs.join("|")})/:provider`,
            ]}
            exact
            component={CasinoPage}
          />
          <Route path={`${prefix}/casino/:provider/:game`} exact component={GamePage} />
          <Route
            path={[
              `${prefix}/`,
              `${prefix}/:type(live)`,
              `${prefix}/promo/participate/:promoId/:action?/:code?`,
              `${prefix}/promo/participate2/:promoId/:action?/:code?`,
              `${prefix}/:type(sports)`,
              `${prefix}/:type(sports|live)/:sport`,
              `${prefix}/:type(sports|live)/:sport/league/:leagueId`,
              `${prefix}/:type(sports|live)/:sport/:league`,
              `${prefix}/:altcoin(ethereum|tether|eos|litecoin|solana)`,
            ]}
            exact
            component={SportHomePage}
          />
          <Route
            path={[
              `${prefix}/:type(sports|live)/:sport/event/:eventId`,
              `${prefix}/:type(sports|live)/:sport/:league/:event`,
            ]}
            exact
            component={EventPage}
          />
          <Redirect to={`${prefix}/404`} />
        </Switch>
      </DesktopLayout>
    </Switch>
  );
};

Routes.propTypes = {
  prefix: PropTypes.string,
};

export default Routes;
