export const casinoPaginationChangeType = {
  add: "add",
  set: "set",
};

export const casinoAltCoins = ["ethereum", "tether", "eos", "litecoin", "solana", "usdt"];

export const getCategoriesWithCoins = (categories = []) => {
  return casinoAltCoins.reduce((a, v) => {
    const newCategories = categories.map((c) => `${v}-${c}`);

    return [...a, ...newCategories];
  }, []);
};
