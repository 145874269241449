import { faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { Card, CardBody, CardHeader } from "reactstrap";

import { isBrowser } from "utils/browserUtils";

const Accordion = ({
  header,
  children,
  bodyClass,
  headerClass,
  forceOpen,
  className,
  scrollTo,
  startOpen,
  onToggle,
  forceRender = false,
}) => {
  const [open, setOpen] = useState(!!startOpen || undefined);
  const [willScroll, setWillScroll] = useState(!!scrollTo);
  const headerRef = useRef(null);

  const tryScroll = () => {
    if (willScroll && headerRef.current) {
      headerRef.current.scrollIntoView({ block: "center" });
      setWillScroll(false);
    }
  };

  useEffect(() => {
    tryScroll();
  }, [willScroll, headerRef.current]);

  useEffect(() => {
    if (scrollTo) {
      setOpen(undefined);
      setWillScroll(true);
    }
  }, [scrollTo]);

  const toggle = (e) => {
    e.preventDefault();
    let newOpen;

    if (typeof open === "undefined") {
      newOpen = !(forceOpen || scrollTo);
    } else {
      newOpen = !open;
    }

    if (typeof onToggle === "function") {
      onToggle(newOpen);
    }

    setOpen(newOpen);
  };

  const reallyOpen =
    !isBrowser() || (typeof open !== "undefined" ? open : forceOpen || scrollTo);

  return (
    <Card className={cx("accordion", className, { open: reallyOpen })}>
      <CardHeader className={cx(headerClass, { open: reallyOpen })} onClick={toggle}>
        <span ref={scrollTo ? headerRef : undefined}>
          <FontAwesomeIcon size={"sm"} icon={reallyOpen ? faAngleDown : faAngleRight} />
        </span>{" "}
        {typeof header === "function" ? header({ open: reallyOpen }) : header}
      </CardHeader>
      {reallyOpen || forceRender ? (
        <CardBody className={bodyClass}>
          {typeof children === "function" ? children({ open: reallyOpen }) : children}
        </CardBody>
      ) : null}
    </Card>
  );
};

export default Accordion;
