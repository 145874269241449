import Loadable from "react-loadable";

import Loading from "components/common/Loaders/Loading.tsx";

const LazyFaqPage = Loadable({
  loader: () => import(/* webpackChunkName: "faq-page"*/ "./FaqPage.tsx"),
  loading: Loading,
  modules: ["faq-page"],
});

export default LazyFaqPage;
