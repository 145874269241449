export enum CarouselLocation {
  Home = "homepage",
  Casino = "casino",
}

export enum SliderType {
  Home = "home",
  Casino = "casino",
  Promotion = "promotion",
}

export enum ContentBlockType {
  TextExpander = "text-expanded",
  Faq = "qa",
  Html = "html",
}
