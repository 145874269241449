import {
  USER_EMAIL_CONFIRMATION_ERROR,
  USER_EMAIL_CONFIRMATION_SENDING,
  USER_EMAIL_CONFIRMATION_SENT,
} from "actions/actionTypes";

const initialState = {
  shown: false,
  error: null,
  loading: false,
  saved: false,
};

const resendConfirmationReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_EMAIL_CONFIRMATION_SENDING:
      return { ...state, loading: true, error: null, saved: false };
    case USER_EMAIL_CONFIRMATION_SENT:
      return { ...state, loading: false, saved: true };
    case USER_EMAIL_CONFIRMATION_ERROR:
      return { ...state, loading: false, error: action.payload?.error };
    default:
      return state;
  }
};

export default resendConfirmationReducer;
