import { faCircleNotch } from "@fortawesome/free-solid-svg-icons/faCircleNotch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import React from "react";
import { Button as RButton } from "reactstrap";

const Button = ({ primary, secondary, loading, disabled, children, ...props }) => (
  <RButton
    disabled={disabled || loading}
    className={cx("button", {
      "button--primary": !!primary,
      "button--secondary": !!secondary,
    })}
    {...props}
  >
    {loading ? <FontAwesomeIcon icon={faCircleNotch} spin /> : children}
  </RButton>
);

export default Button;
