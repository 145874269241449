const HydrationState = (() => {
  let instance: any = null;
  let state: any = null;

  const createInstance = () => {
    return {
      getState: () => state,
      setState: (data: any) => {
        state = data;
      },
    };
  };

  return {
    getInstance: () => {
      return instance || (instance = createInstance());
    },
  };
})();

export { HydrationState };
