import { FC, ReactNode } from "react";

import { isBrowser } from "utils/browserUtils";

const BrowserOnly: FC<{ children: ReactNode }> = ({ children }) => {
  if (!isBrowser()) return null;
  return children;
};

export default BrowserOnly;
