import { getAccountIsVip } from "selectors/loginSelectors";

import { eventType } from "constants/events";

export const getEvent = (state, eventId) => {
  const event = eventId && state.events.byId[eventId];
  const isVip = !!getAccountIsVip(state);
  if (event && !isVip && event.type === eventType.prematch) {
    event.odds = { ...event.odds };
    let bet = false;
    for (const market of Object.keys(event.odds)) {
      if (market === "bet") continue;
      const odd = event.odds[market];
      if (Array.isArray(odd)) {
        const oddPrepared = odd.filter((x) => typeof x.vip === "undefined" || !x.vip);
        if (!oddPrepared.length) delete event.odds[market];
        if (oddPrepared.some((x) => !!x.bet)) bet = true;
        event.odds[market] = oddPrepared;
      } else if (odd.vip) {
        delete event.odds[market];
      } else if (odd.bet) {
        bet = true;
      }
    }
    if (!bet) event.bet = false;
  }
  return event;
};

export const getLiveSportsCount = (state) => {
  const isVip = getAccountIsVip(state);
  const liveSports = state.sports.liveSports;
  const result = {};
  for (let [sportId, sources] of Object.entries(liveSports)) {
    if (!result[sportId]) result[sportId] = 0;
    Object.values(sources).forEach((x) => {
      result[sportId] += isVip ? x.all : x.all - x.vipOnly;
    });
  }
  return result;
};

export const getLeague = (leagueId) => (state) =>
  leagueId && state.leagues.byId[leagueId];

export const getEventLoadingState = (eventId) => (state) => {
  return (eventId && state.events.loading[eventId]) || {};
};

export const getEventsLoading = (state, eventId) => {
  return state.events?.every((e) => e.loading) ?? false;
};

export const getEventBreadcrumbs = (state) => state.events.breadcrumbs;

export const getEventById = (state) => state.events.eventById;
