import AppContext from "contexts/AppContext";
import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { NavLink as RRNavLink } from "react-router-dom";
import { NavLink as Link, NavLinkProps } from "reactstrap";

import { PAGE_SET_IDS } from "actions/actionTypes";

import { defaultLanguage } from "constants/i18n";

type OwnProps = { relatedIds?: Record<string, string | number>; noLanguage?: boolean };
type Props = OwnProps & NavLinkProps;

const NavLink: FC<Props> = ({ noLanguage, to, onClick, relatedIds, ...props }) => {
  const dispatch = useDispatch();
  const { requestLanguage } = useContext(AppContext);
  const { i18n } = useTranslation();
  const language = requestLanguage || (i18n && i18n.language) || "en";
  const prefix = noLanguage || language === defaultLanguage ? "" : `/${language}`;

  return (
    <Link
      to={`${prefix}${to}`}
      tag={RRNavLink}
      {...props}
      onClick={(e) => {
        if (relatedIds) {
          dispatch({
            type: PAGE_SET_IDS,
            payload: relatedIds,
          });
        }
        if (typeof onClick === "function") {
          onClick(e);
        }
      }}
    />
  );
};

export default NavLink;
