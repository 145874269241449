import { Cloudinary, CloudinaryImage } from "@cloudinary/url-gen";
import { getAuthToken } from "api/api.js";
import React, { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import SwiperCore, { Autoplay, Navigation, Pagination, Virtual } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";

import Slide from "./Slide.tsx";

SwiperCore.use([Virtual, Autoplay, Pagination, Navigation]);

type Slide = {
  img: CloudinaryImage;
  id: string;
  alt: string;
  imageUrl: string;
  text: string;
  external: boolean;
  url: string;
  image: {
    url: string;
    public_id: string;
    width: number;
    height: number;
  };
};

type Props = {
  items: Slide[];
  slideMaxHeight?: number;
};

const cld = new Cloudinary({
  cloud: {
    cloudName: "dpm1uipt5",
  },
});

export const Slider: React.FC<Props> = ({ items = [], slideMaxHeight }) => {
  const sectionRef = useRef<HTMLElement | null>(null);
  const swiperRef = useRef<SwiperCore | null>(null);
  const [isClientSide, setIsClientSide] = useState(false);
  const [isSwiperUpdated, setIsSwiperUpdated] = useState(false);

  const { t } = useTranslation();

  const history = useHistory();

  useEffect(() => {
    setIsClientSide(true);
    const timeout = setInterval(() => {
      if (
        sectionRef.current &&
        sectionRef.current.offsetWidth > 0 &&
        swiperRef.current &&
        !isSwiperUpdated
      ) {
        swiperRef.current.update();
        setIsSwiperUpdated(true);
        clearInterval(timeout);
      }
    }, 100);
  }, []);

  if (!items?.length) return null;

  return (
    <section id="slider-wrapper" ref={sectionRef} className={"slider-wrapper"}>
      {!isClientSide && (
        <Slide
          cld={cld}
          url={items[0].url}
          alt={items[0].alt}
          image={items[0].image}
          slideMaxHeight={slideMaxHeight}
        />
      )}
      {isClientSide && (
        <Swiper
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
          loop={true}
          pagination={{
            clickable: true,
          }}
          navigation={!isMobile}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
        >
          {items.map((item: Slide) => (
            <SwiperSlide key={item.id}>
              <Slide
                cld={cld}
                url={item.url}
                alt={item.alt}
                image={item.image}
                slideMaxHeight={slideMaxHeight}
              />
              {!getAuthToken() && (
                <button
                  className="get-bonus-button"
                  onClick={() => history.replace(item.url)}
                >
                  {t("Get bonus")}
                </button>
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </section>
  );
};
