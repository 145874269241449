import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";

import LoginPopup from "containers/popups/LoginPopup/LoginPopup";

import { getAccountUser } from "selectors/loginSelectors";

const PromoSignUpPopup = () => {
  const user = useSelector(getAccountUser);
  const history = useHistory();
  const { pathname } = useLocation();
  const onClose = () => {
    history.replace(pathname);
  };

  useEffect(() => {
    if (user) onClose();
  }, [user]);

  return <LoginPopup signUp={true} promo={true} onClose={onClose} />;
};

export default PromoSignUpPopup;
