import qs from "query-string";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";

import { getUnsubscribe } from "selectors/unsubscribeSelectors";

import {
  unsubscribe,
  unsubscribeClose,
  unsubscribeInit,
} from "actions/unsubscribeActions";

import Button from "components/common/Button";
import { FormErrorMessage } from "components/common/FormErrors";
import Popup from "components/common/Popup.tsx";

const Unsubscribe = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { loading, error, success } = useSelector(getUnsubscribe);
  const { unsubscribe: code, email } = location.search
    ? qs.parse(location.search) || {}
    : {};

  useEffect(() => {
    if (!code || !email) return;
    dispatch(unsubscribeInit(email, code));
  }, [code, email]);

  if (!code || !email) return null;

  return (
    <Popup
      title={t("Unsubscribe")}
      className={"unsubscribe-popup"}
      onClose={() => {
        dispatch(unsubscribeClose());
      }}
    >
      {success ? (
        <>
          <p>{t("You have successfully unsubscribed from newsletter")}</p>
          <Button
            secondary
            className={"btn-submit"}
            onClick={() => {
              dispatch(unsubscribeClose());
              history.push(location.pathname);
            }}
          >
            {t("Close")}
          </Button>
        </>
      ) : (
        <>
          <p>{t("To unsubscribe from newsletter please click button below")}</p>
          <div className={"d-flex"}>
            <Button
              secondary
              className={"btn-submit"}
              onClick={() => {
                dispatch(unsubscribe());
              }}
              loading={loading}
              style={{ minWidth: 140 }}
            >
              {t("Unsubscribe")}
            </Button>
            {error ? <FormErrorMessage text={t("Please, try later")} /> : null}
          </div>
        </>
      )}
    </Popup>
  );
};

export default Unsubscribe;
