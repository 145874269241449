import { Suspense, SuspenseProps, useEffect, useState } from "react";

const SuspensePostHydration = (props: SuspenseProps): JSX.Element => {
  const [postHydration, setPostHydration] = useState(false);
  useEffect(() => {
    setPostHydration(true);
  }, []);
  return postHydration ? <Suspense {...props} /> : <></>;
};

export default SuspensePostHydration;
