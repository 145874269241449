import { clearAuthBonusCookies, clearStoredBonus } from "components/bonus/bonus.utils";

import { localStorageUtils } from "utils/browserUtils";

import api, { addAuth, getAuthToken } from "../api/api";

export const register = async (code) => {
  try {
    const {
      data: { activeCode, bonusPacks },
    } = await api.post("/bonus/register", { code });
    if (!activeCode) {
      throw new Error("Cannot register bonus code");
    }
    return { activeCode, bonusPacks };
  } catch (error) {
    throw new Error("Cannot register bonus code");
  }
};

export const getBonusPacks = async (activeCode, paramPack) => {
  try {
    const {
      data: { bonusPacks },
    } = await api.post("/bonus/list", { params: { activeCode, paramPack } });
    return { bonusPacks };
  } catch (error) {
    throw new Error("Cannot load bonus packs");
  }
};

export const activateBonusPack = async () => {
  try {
    const token = getAuthToken();
    if (!token) throw new Error("Not authorized");
    const activeCode = localStorageUtils.getItem("activeCode");
    const bonusPackId = localStorageUtils.getItem("activeBonusId");
    if (!bonusPackId) {
      clearStoredBonus();
      clearAuthBonusCookies();
      throw new Error("Cannot activate Bonus Pack");
    }
    const {
      data: { status },
    } = await api.post("/bonus/activate", { activeCode, bonusPackId }, addAuth(token));
    clearStoredBonus();
    clearAuthBonusCookies();
    return { status };
  } catch (e) {
    throw new Error("Cannot activate bonus pack");
  }
};

export const getBonuses = async () => {
  try {
    const token = getAuthToken();
    if (!token) throw new Error("Not authorized");
    const {
      data: { bonuses },
    } = await api.get("/bonus", addAuth(token));
    return { bonuses };
  } catch (e) {
    throw new Error("Cannot get bonuses");
  }
};

export const cancelBonus = async (bonusId) => {
  try {
    const token = getAuthToken();
    if (!token) throw new Error("Not authorized");
    const {
      data: { status },
    } = await api.post("/bonus/cancel", { bonusId }, addAuth(token));
    return { status };
  } catch (e) {
    throw new Error("Cannot cancel bonus");
  }
};

export const acceptBonus = async (bonusId) => {
  try {
    const token = getAuthToken();
    if (!token) throw new Error("Not authorized");
    const {
      data: { status },
    } = await api.post("/bonus/accept", { bonusId }, addAuth(token));
    return { status };
  } catch (e) {
    throw new Error("Cannot accept bonus");
  }
};

export const refuseBonus = async (bonusId) => {
  try {
    const token = getAuthToken();
    if (!token) throw new Error("Not authorized");
    const {
      data: { status },
    } = await api.post("/bonus/refuse", { bonusId }, addAuth(token));
    return { status };
  } catch (e) {
    throw new Error("Cannot refuse bonus");
  }
};

export const getActiveRiskFreeBonus = async () => {
  try {
    const token = getAuthToken();
    if (!token) throw new Error("Not authorized");
    const {
      data: { bonus },
    } = await api.get("/risk-free", addAuth(token));
    return { bonus };
  } catch (e) {
    throw new Error("Cannot get active risk-free bet bonus");
  }
};

export const getPendingDepositBonus = async () => {
  try {
    const token = getAuthToken();
    if (!token) throw new Error("Not authorized");
    const res = await api.get("/bonus/pending-deposit", addAuth(token));

    if (res.status === "success") {
      const {
        data: { bonus },
      } = res;

      return { bonus };
    }

    return res.data;
  } catch (e) {
    throw new Error("Cannot get active pending deposit bonus");
  }
};

export const getWageringBonus = async () => {
  try {
    const token = getAuthToken();
    if (!token) throw new Error("Not authorized");
    const {
      data: { bonus },
    } = await api.get("/bonus/wagering-bonus", addAuth(token));
    return { bonus };
  } catch (e) {
    throw new Error("Cannot get active wagering bonus");
  }
};
export const fetchUserRiskFree = async () => {
  const token = getAuthToken();
  if (!token) return null;

  const response = await api.get("/bonus/user/risk-free", addAuth(token));

  return response.data;
};
export const clearUserRiskFreeModal = async () => {
  const token = getAuthToken();
  if (!token) return null;

  const response = await api.get("/bonus/user/clear-modal", addAuth(token));

  return response.data;
};
