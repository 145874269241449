const key = "e699d8026ccabf14bc2697a31fe1fdfd859568cd91f4e3cc339e1316c811a4588836";

export const xorEncrypt = (data: string): string => {
  const encrypted = [];
  for (let i = 0; i < data.length; i++) {
    const byte = data.charCodeAt(i) ^ key.charCodeAt(i % key.length);
    encrypted.push(byte);
  }
  return encrypted.join("-");
};

export const xorDecrypt = (encrypted: string): string => {
  if (encrypted?.length === 0) {
    return "{}";
  }
  const encryptedData = encrypted.split("-");
  const decrypted = [];
  for (let i = 0; i < encryptedData.length; i++) {
    const byte = +encryptedData[i] ^ key.charCodeAt(i % key.length);
    decrypted.push(String.fromCharCode(byte));
  }
  return decrypted.join("");
};
