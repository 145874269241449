import api from "./api";

export const fetchSlider = async (language, type) => {
  try {
    const url = `/slider/${language}/${type}`;
    const { data } = await api.get(url);
    return data || [];
  } catch (e) {
    return [];
  }
};
