import PropTypes from "prop-types";
import React from "react";
import { Alert } from "reactstrap";

const ErrorMsg = ({ children, className }) => {
  return (
    <Alert color="danger" className={className}>
      {children}
    </Alert>
  );
};

ErrorMsg.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  className: PropTypes.string,
};

export default ErrorMsg;
