import { loadEvents } from "actions/eventActions";

import { eventType } from "constants/events";

import { getBetBets } from "../../selectors/betSelectors";

export default (dispatch, getState) => {
  let i = 0;
  setInterval(() => {
    ++i;
    const liveEvents = i % 10 !== 0;

    const events = getState().events;
    const monitoring = events.monitoring;

    const ids = [];
    // eslint-disable-next-line
    for (const id of Object.getOwnPropertyNames(monitoring)) {
      if ((monitoring[id] || 0) <= 0) continue;
      if (events.loading[id] && events.loading[id].loading) continue;
      if (liveEvents && events.byId[id] && events.byId[id].type !== eventType.live)
        continue;

      ids.push(id);
    }

    const bets = getBetBets(getState());
    if (Array.isArray(bets)) {
      for (const bet of bets) {
        if (!ids.includes(bet.event)) {
          ids.push(bet.event);
        }
      }
    }

    if (!ids.length) return;
    dispatch(loadEvents(ids));
  }, 1000);
};
