import {
  CASINO_HISTORY_CLEAR,
  CASINO_HISTORY_ERROR,
  CASINO_HISTORY_LOADED,
  CASINO_HISTORY_LOADING,
} from "../../actions/casino";

const initialState = {
  loading: false,
  error: null,
  data: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CASINO_HISTORY_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CASINO_HISTORY_LOADED:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload,
      };
    case CASINO_HISTORY_ERROR:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    case CASINO_HISTORY_CLEAR:
      return initialState;
    default:
      return state;
  }
};
