import useClickOutside from "hooks/useClickOutside";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  betLimitsSelector,
  getBetError,
  getBetOptions,
  getBetPlaced,
  getBetSlipBets,
  hasPendingBetUpdates,
} from "selectors/betSelectors";
import { getOddsFormat } from "selectors/uiSelectors";

import {
  acceptChanges,
  betCancel,
  betClearPlaced,
  clearBetError,
  getBetLimits,
  modifyBetOptions,
  removeBet,
  restoreBetSlip,
} from "actions/betActions";

import BetSlip from "components/bet/BetSlip/index.ts";

const BetSlipHandler = () => {
  const dispatch = useDispatch();
  const bets = useSelector(getBetSlipBets);
  const betOptions = useSelector(getBetOptions);
  const hasPendingUpdates = useSelector(hasPendingBetUpdates);
  const error = useSelector(getBetError);
  const placedBet = useSelector(getBetPlaced);
  const betLimits = useSelector(betLimitsSelector);
  const prevBetsCount = useRef(0);
  const oddsFormat = useSelector(getOddsFormat);
  const [expanded, setExpanded] = useState(false);
  const betSlipRef = useRef(null);
  const { maxParlayBets } = betLimits;

  useClickOutside({
    ref: betSlipRef,
    onClick: () => {
      if (placedBet) {
        dispatch(betClearPlaced());
      }
      setExpanded(false);
    },
    exceptClasses: ["bet-button", "bet-text", "bet-odd-text"],
  });

  useEffect(() => {
    dispatch(restoreBetSlip());
  }, []);

  useEffect(() => {
    if (Object.keys(betLimits).length === 0) {
      dispatch(getBetLimits());
    }
  }, [betLimits]);

  useEffect(() => {
    if (prevBetsCount.current !== bets.length) {
      if (bets.length) {
        document.body.classList.add("betslip-shown");
      } else {
        document.body.classList.remove("betslip-shown");
      }
    }

    if (
      bets.length > prevBetsCount.current &&
      !expanded &&
      (prevBetsCount.current === 0 || prevBetsCount.current === maxParlayBets)
    ) {
      setExpanded(true);
    }
    prevBetsCount.current = bets.length;
  }, [bets.length]);

  useEffect(() => {
    if (
      (betOptions.acceptBetterOdds && !betOptions.acceptWorseOdds) ||
      (!betOptions.acceptBetterOdds && betOptions.acceptWorseOdds)
    ) {
      modifyBetOptions("acceptBetterOdds", true);
      modifyBetOptions("acceptWorseOdds", true);
    }
  }, [betOptions]);

  useEffect(() => {
    if (placedBet) {
      setExpanded(false);
    }
  }, [placedBet]);
  if (!bets.length && !placedBet) return null;

  return (
    <BetSlip
      betLimits={betLimits}
      placedBet={placedBet}
      bets={bets.filter((x) => !!x.event)}
      oddsFormat={oddsFormat}
      innerRef={betSlipRef}
      expanded={expanded}
      onToggle={() => setExpanded(!expanded)}
      removeBet={(bet) => dispatch(removeBet(bet))}
      clearAll={() => dispatch(betCancel())}
      clearError={() => dispatch(clearBetError())}
      error={error}
      betOptions={betOptions}
      modifyBetOptions={(option, value) => dispatch(modifyBetOptions(option, value))}
      hasPendingUpdates={hasPendingUpdates}
      acceptChanges={() => dispatch(acceptChanges())}
    />
  );
};

export default BetSlipHandler;
