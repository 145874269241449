import { addAuth, getAuthToken } from "api/api";
import { apiType, getApi } from "api/apiFactory";

const api = getApi(apiType.casino);

export const fetchGames = async (params) => {
  const resp = await api.get("/", { params });
  return resp.data;
};

export const fetchLobbyGames = async (params) => {
  const resp = await api.get("/categories/casino-games", { params });
  return resp.data;
};

export const addFavourite = async (gameId, eosAccount) => {
  await api.get(`/favourite/add?userId=${eosAccount}&gameId=${gameId}`);
};

export const deleteFavourite = async (gameId, eosAccount) => {
  await api.get(`/favourite/delete?userId=${eosAccount}&gameId=${gameId}`);
};

export const fetchGameLobby = async ({ game_uuid, currency }) => {
  const resp = await api.get(`/lobby/${game_uuid}/${currency}`);
  return resp.data;
};

export const fetchGame = async (gameId, eosAccount) => {
  const resp = await api.get(
    `/game/${gameId}${eosAccount ? `?userId=${eosAccount}` : ""}`
  );
  return resp.data;
};

export const fetchGameBySlug = async (slug) => {
  const token = getAuthToken();

  const resp = await api.post(
    `/game/slug`,
    {
      slug,
    },
    addAuth(token)
  );
  return resp.data;
};

export const fetchRelatedGames = async (provider, eosAccount, limit) => {
  const resp = await api.get(
    `/?page=1&perPage=${limit + 1}&related=true&providers=${provider}${
      eosAccount ? `&userId=${eosAccount}` : ""
    }`
  );
  return resp.data && resp.data.items;
};

export const prepareGame = async ({ game_uuid, account, language, lobby_data }) => {
  const resp = await api.post(`/prepare`, {
    game_uuid,
    account,
    language,
    lobby_data,
  });
  return resp.data;
};

export const startGame = async ({
  eos_id,
  currency,
  userCurrency,
  amount,
  rate,
  trx,
  gameName,
  providerName,
}) => {
  const token = getAuthToken();
  if (!token) return {};

  const resp = await api.post(
    `/start`,
    {
      eos_id,
      currency,
      userCurrency,
      amount,
      rate,
      trx,
      gameName,
      providerName,
    },
    addAuth(token)
  );
  return resp.data;
};

export const startGameDemo = async ({ gameId, language }) => {
  const resp = await api.post(`/start-demo`, {
    game_uuid: gameId,
    language,
  });
  return resp.data;
};

export const fetchCasinoHistory = async (account) => {
  const resp = await api.get(`/history/${account}`);
  return resp.data;
};

export const fetchCasinoHistoryDetails = async (gameId) => {
  const resp = await api.get(`/history/details/${gameId}`);
  return resp.data;
};

export const fetchCasinoHistory2 = async (account) => {
  const resp = await api.get(`/history/games/${account}`);
  return resp.data;
};

export const endGame = async (sessionId) => {
  const resp = await api.post("/end-game", { sessionId });
  return resp.data;
};

export const casinoGameStart = async ({
  token,
  currency,
  quantity,
  rate,
  game_uuid,
  language,
}) => {
  const authToken = getAuthToken();
  if (!authToken) throw new Error("Unauthorized");

  const response = await api.post(
    "/start-secured",
    {
      token,
      currency,
      quantity,
      rate,
      game_uuid,
      language,
    },
    addAuth(authToken)
  );
  return response.data;
};
