import {
  USER_ONSITE_ERROR,
  USER_ONSITE_SAVED,
  USER_ONSITE_SAVING,
  USER_RESET_NOTIFICATIONS_SETTINGS,
} from "actions/actionTypes";

const initialState = {
  error: null,
  loading: false,
  saved: false,
};

const onsiteReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_ONSITE_SAVING:
      return { ...state, loading: true, error: null, saved: false };
    case USER_ONSITE_SAVED:
      return { ...state, loading: false, saved: true };
    case USER_ONSITE_ERROR:
      return { ...state, loading: false, error: action.payload?.error };
    case USER_RESET_NOTIFICATIONS_SETTINGS:
      return initialState;
    default:
      return state;
  }
};

export default onsiteReducer;
