import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { dateOnly, toNiceDate } from "utils/dateUtils";

const DateHeader = ({ date }) => <div className="date-header">{date}</div>;

DateHeader.propTypes = {
  date: PropTypes.string,
};

const DateList = ({
  items,
  getDate,
  thenSort,
  renderGroup,
  render,
  asc,
  renderHeader,
  customSorting,
}) => {
  const { t } = useTranslation();
  const sorted = [...items];
  if (!thenSort) thenSort = () => 0;
  sorted.sort((a, b) => {
    const res = getDate(a).valueOf() - getDate(b).valueOf();
    if (res === 0) return thenSort(a, b);
    return asc ? res : -res;
  });
  if (typeof customSorting === "function") {
    sorted.sort(customSorting);
  }

  if (!renderGroup) {
    // eslint-disable-next-line react/display-name
    if (!renderHeader) renderHeader = (d) => <DateHeader key={d} date={d} />;
    // eslint-disable-next-line react/display-name
    renderGroup = (date, items) => (
      <Fragment key={date}>
        {renderHeader(toNiceDate(date, t), items)}
        {items.map((x) => render(x))}
      </Fragment>
    );
  }

  const elements = [];
  let current = [];
  let lastDate = new Date(0);

  for (const item of sorted) {
    const date = dateOnly(getDate(item));
    if (date.valueOf() !== lastDate.valueOf()) {
      if (current.length) {
        elements.push(renderGroup(lastDate, current));
        current = [];
      }
      lastDate = date;
    }
    current.push(item);
  }
  if (current.length) elements.push(renderGroup(lastDate, current));

  return <>{elements}</>;
};

DateList.propTypes = {
  items: PropTypes.array,
  getDate: PropTypes.func,
  thenSort: PropTypes.func,
  renderGroup: PropTypes.func,
  render: PropTypes.func,
  asc: PropTypes.bool,
  renderHeader: PropTypes.func,
  customSorting: PropTypes.func,
};

export default DateList;
