import Loadable from "react-loadable";

const LazyNotificationsPopup = Loadable({
  loader: () =>
    import(/* webpackChunkName: "notifications-popup"*/ "./NotificationsPopup"),
  loading: () => null,
  modules: ["notifications-popup"],
});

export default LazyNotificationsPopup;
