import Loadable from "react-loadable";

import Loading from "components/common/Loaders/Loading.tsx";

const LazyEventPage = Loadable({
  loader: () => import(/* webpackChunkName: "event-page"*/ "./EventPage.tsx"),
  loading: Loading,
  modules: ["event-page"],
});

export default LazyEventPage;
