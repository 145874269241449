import getEosApi from "api/eosApi";

import { getAccountUser } from "selectors/loginSelectors";

import {
  CURRENCY_CHANGED,
  CURRENCY_CHANGE_ERROR,
  CURRENCY_CHANGING,
} from "actions/actionTypes";

import { currencies } from "constants/currencies.ts";

export const changeCurrency = (currencyName) => async (dispatch, getState) => {
  try {
    if (!currencies[currencyName]) {
      return;
    }
    dispatch({
      type: CURRENCY_CHANGING,
    });

    const user = getAccountUser(getState());
    if (user.provider !== "scatter" && !user.eosAccount) {
      dispatch({
        type: CURRENCY_CHANGED,
        payload: { currencyName, balance: 0 },
      });
      return;
    }

    const balance = await getEosApi().getAccountBalance(currencyName, user);

    dispatch({
      type: CURRENCY_CHANGED,
      payload: { currencyName, balance },
    });
  } catch (e) {
    dispatch({
      type: CURRENCY_CHANGE_ERROR,
      payload: e,
    });
  }
};
