import { supportedProviders } from "@sbetdev2/dbmodel/enums";
import api, { addAuth, getAuthToken } from "api/api";
import { store } from "config/store";

import { getAccountUser } from "selectors/loginSelectors";

import * as anchorApi from "actions/eos/anchorapi";
import * as backendApi from "actions/eos/backendapi";
import * as scatterApi from "actions/eos/scatterapi";

import { localStorageUtils } from "utils/browserUtils";

export const broadcastTransaction = async (transaction, { timeout, tfaCode } = {}) => {
  const token = getAuthToken();
  if (!token) return null;

  const { data } = await api.post(
    "/eos-api/broadcast",
    { transaction },
    addAuth(token, {
      headers: { tfaCode },
      params: { timeout },
    })
  );
  return data;
};

export const getEosBalance = async (code, account, token) => {
  const response = await api.post(`/eos/balance/${account}`, { code, token });
  const { balance } = response.data;
  return balance;
};

export const placeBet = async (transaction, { timeout } = {}) => {
  const token = getAuthToken();
  if (!token) return null;

  const response = await api.post(
    "/bets/place2",
    { transaction },
    addAuth(token, {
      params: { timeout },
    })
  );
  return response.data;
};

export const eosApiGetRows = async (params) => {
  const token = getAuthToken();
  if (!token) return null;
  const response = await api.post("/eos-api/get-rows", { params }, addAuth(token));
  return response.data;
};

export const eosApiSignTrx = async (tx) => {
  const token = getAuthToken();
  if (!token) return null;
  const response = await api.post("/eos-api/sign-trx", { tx }, addAuth(token));
  return response.data;
};

export const getTokenHistory = async ({ page, pageSize, isCasino }) => {
  const AuthToken = getAuthToken();
  const apiUrl = isCasino ? "/wallet/transfer-history" : `/eos-api/history`;
  const { data } = await api.post(apiUrl, { page, pageSize }, addAuth(AuthToken));
  return data;
};

const getAction = (name, eosApi = scatterApi) => {
  const action = eosApi[name];
  if (action) return action;
  return async () => {
    throw new Error(`Not Supported EOS Action: ${name}`);
  };
};

const getWithdrawActionByProvider = (
  eosApi = scatterApi,
  provider = supportedProviders.bitgo
) => {
  const withdraw = eosApi.withdraw[provider];
  if (withdraw) return withdraw;
  return async () => {
    throw new Error(`Not Supported Provider: ${provider}`);
  };
};

export const init = getAction("init");
export const login = getAction("login");
export const logout = getAction("logout");
export const betTransfer = getAction("betTransfer");
export const betsTransfer = getAction("betsTransfer");
export const betComplete = getAction("betComplete");
export const getDividends = getAction("getDividends");
export const claimDividends = getAction("claimDividends");
export const stakeTokens = getAction("stakeTokens");
export const unstakeTokens = getAction("unstakeTokens");
export const getAccountBalance = scatterApi.getAccountBalance;
export const sendWithdrawal = getAction("sendWithdrawal");
export const approveWithdrawal = getAction("approveWithdrawal");
export const rejectWithdrawal = getAction("rejectWithdrawal");
export const withdraw = getWithdrawActionByProvider();
export const casinoDeposit = getAction("casinoDeposit");
export const convertBTC = getAction("convertBTC");
export const startCasinoGame = getAction("startCasinoGame");

const eosApi = {
  init,
  login,
  logout,
  betTransfer,
  betsTransfer,
  betComplete,
  getDividends,
  claimDividends,
  stakeTokens,
  unstakeTokens,
  getAccountBalance,
  sendWithdrawal,
  approveWithdrawal,
  rejectWithdrawal,
  withdraw: scatterApi.withdraw,
  casinoDeposit,
  convertBTC,
  startCasinoGame,
};

const getEosApi = (provider) => {
  const wallet = localStorageUtils.getItem("activeWallet");
  if (wallet === "anchor") {
    return { ...anchorApi, withdraw: getWithdrawActionByProvider(anchorApi, provider) };
  }
  const user = getAccountUser(store.getState());
  if (user && !["scatter"].includes(user.provider)) {
    return { ...backendApi, withdraw: getWithdrawActionByProvider(backendApi, provider) };
  }

  return { ...eosApi, withdraw: getWithdrawActionByProvider(eosApi, provider) };
};

export default getEosApi;
