import Cookies from "js-cookie";

import { isBrowser, sessionStorageUtils } from "utils/browserUtils";

import { HydrationState } from "./generalUtils";

const analytics = {};

const events = {
  /* login */
  login_open: "login_open",
  login_start: "login_start", //login_provider
  login_success: "login_success", //login_provider
  login_fail: "login_fail", //login_provider
  /* deposit */
  wallet_open: "wallet_open",
  deposit_currency_change: "deposit_currency_change", //deposit_currency
  deposit_change_method: "deposit_change_method", //deposit_currency deposit_method
  /* withdraw */
  withdraw: "withdraw", //deposit_currency
  /* featured events */
  featured_event_open: "featured_event_open",
  featured_event_bet: "featured_event_bet",
  /* bet */
  bet_placed: "bet_placed",
  /* signup */
  sign_up_failed: "sign_up_failed",
  sign_up_success: "sign_up_success",
  /* turnstile */
  turnstile_error: "turnstile_failed",
  /* slider clicked */
  slider_click: "slider_banner_click",
};

const sendEvent = (eventName, params = {}) => {
  const state = HydrationState.getInstance().getState();
  const isLoggedIn = state ? state.isLoggedIn : !!Cookies.get("auth_token");

  try {
    if (!isBrowser()) return;
    window.dataLayer.push({
      event: "ga4event",
      isLoggedIn,
      eventName,
      ...params,
    });
    // eslint-disable-next-line no-empty
  } catch (e) {}
};

analytics.init = () => {
  if (!isBrowser()) return;

  let utmParams = null;
  try {
    utmParams = sessionStorageUtils.getItem("utmParams");
    utmParams = JSON.parse(utmParams);
    sessionStorageUtils.removeItem("utmParams");
  } catch (e) {
    utmParams = null;
  }

  const query =
    utmParams &&
    (utmParams.campaign ||
      utmParams.content ||
      utmParams.medium ||
      utmParams.source ||
      utmParams.term)
      ? `?utm_campaign=${utmParams.campaign}&utm_content=${utmParams.content}&utm_medium=${utmParams.medium}&utm_source=${utmParams.source}&utm_term=${utmParams.term}`
      : "";
  window.dataLayer.push({
    event: "page_view",
    page_path: window.location.pathname + query,
  });
};
analytics.sendEvent = sendEvent;
analytics.setUserId = (userId) => {
  try {
    if (!isBrowser()) return;
    window.dataLayer.push({
      user_id: userId,
    });
  } catch (e) {
    // do nothing
  }
};

analytics.login = {
  open: () => sendEvent(events.login_open),
  start: (provider) => sendEvent(events.login_start, { login_provider: provider }),
  success: (provider) => sendEvent(events.login_success, { login_provider: provider }),
  fail: (provider) => sendEvent(events.login_fail, { login_provider: provider }),
};
analytics.deposit = {
  open: () => sendEvent(events.wallet_open),
  creditCard: (currency) =>
    sendEvent(events.deposit_change_method, {
      deposit_currency: currency,
      deposit_method: "creditCard",
    }),
  wireTransfer: (currency) =>
    sendEvent(events.deposit_change_method, {
      deposit_currency: currency,
      deposit_method: "wireTransfer",
    }),
  withdraw: (currency) => sendEvent(events.withdraw, { deposit_currency: currency }),
};
analytics.featuredEvents = {
  open: () => sendEvent(events.featured_event_open),
  bet: () => sendEvent(events.featured_event_bet),
};
analytics.bets = {
  placed: () => sendEvent(events.bet_placed),
};
analytics.welcome = {
  popup: () => sendEvent(events.welcome_popup),
};
analytics.signup = {
  error: (code) => sendEvent(events.sign_up_failed, code),
  success: (bonus) => sendEvent(events.sign_up_success, bonus),
};
analytics.turnstile = {
  error: (errorCode) =>
    sendEvent(events.turnstile_error, { turnstile_error_code: errorCode }),
};
analytics.slider = {
  click: (params) => sendEvent(events.slider_click, params),
};

export default analytics;
