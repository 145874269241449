import { getHostname } from "utils/domainUtils.ts";

const whitelabelConfig = {
  logo: "/images/logo.png",
  logoWidth: 160,
  name: "Sportbet.one",
  powered: false,
  disclaimer: true,
  socials: [
    {
      type: "twitter",
      url: "https://twitter.com/SportBetOne",
      title: "Twitter",
    },
    {
      type: "bitcointalk",
      url: "https://bitcointalk.org/index.php?topic=5177893.0",
      title: "Bitcointalk",
    },
  ],
  blogUrl: "https://sportbet.one/blog",
  affiliate: true,
  dividends: true,
  rules: true,
  withdrawalGuideUrl: "",
  privacyPolicy: true,
  bettingIntegrity: true,
  responsibleGaming: true,
  aboutUs: true,
  federbet: true,
  telegram: [
    {
      url: "https://t.me/SportBetOfficial",
      title: "Telegram",
      flag: "gb",
    },
    {
      url: "https://t.me/SportBetOfficialRU",
      title: "Telegram",
      flag: "ru",
    },
    {
      url: "https://t.me/SportBetOfficialCN",
      title: "Telegram",
      flag: "cn",
    },
  ],
  wechat: true,
  supportEmail: "support@sportbet.one",
  supportChat: true,
  languages: [
    { name: "English", code: "en", flag: "gb" },
    { name: "繁體中文", code: "zh-TW", flag: "mo" },
    { name: "简体中文", code: "zh-CN", flag: "cn" },
    { name: "한국어", code: "ko", flag: "kr" },
    { name: "Русский", code: "ru", flag: "ru" },
    { name: "Türkçe", code: "tr", flag: "tr" },
  ],
  defaultLanguage: "en",
  geoLocales: [
    { name: "Australia", code: "en-AU", route: "au" },
    { name: "Kenya", code: "en-KE", route: "ke" },
    { name: "Italia", code: "it-IT", route: "it" },
  ],
  canonical: [
    {
      from: "/casino/b-gaming/plinko",
      to: `https://${getHostname()}/casino/plinko`,
    },
  ],
  scatter: true,
  google: true,
  facebook: true,
  twitter: true,
  faq: true,
};

export type SocialType = { type?: string; url: string; title: string };
export type LanguageType = { name: string; code: string; flag?: string };
export type CanonicalType = { from: string; to: string };

type ConfigValueType =
  | boolean
  | string
  | number
  | Array<SocialType | LanguageType | CanonicalType>;

type ConfigType = Record<string, ConfigValueType>;

interface WhitelabelType {
  init: (data?: ConfigType) => void;
  logo: () => string;
  logoStyles: () => {
    width?: number;
    maxWidth?: number;
  };

  [key: string]: (() => ConfigValueType) | ((data?: ConfigType) => void);
}

let whitelabel: WhitelabelType | undefined;

export const initWhitelabel = (config: ConfigType = {}) => {
  whitelabel = {
    init: (data?: ConfigType) => {
      if (data) config = data;
    },
    logo: () => config.logo.toString(),
    logoStyles: () =>
      typeof Number(config.logoWidth) === "number"
        ? {}
        : {
            width: Number(config.logoWidth),
            maxWidth: Number(config.logoWidth),
          },
    name: () => config.name,
    powered: () => !!config.powered,
    disclaimer: () => !!config.disclaimer,
    socials: () => (Array.isArray(config.socials) ? config.socials : []),
    blog: () => !!config.blogUrl,
    blogUrl: () => config.blogUrl,
    affiliate: () => !!config.affiliate,
    dividends: () => !!config.dividends,
    rules: () => !!config.rules,
    faq: () => !!config.faq,
    withdrawalGuide: () => !!config.withdrawalGuideUrl,
    withdrawalGuideUrl: () => config.withdrawalGuideUrl,
    privacyPolicy: () => !!config.privacyPolicy,
    bettingIntegrity: () => !!config.bettingIntegrity,
    responsibleGaming: () => !!config.responsibleGaming,
    aboutUs: () => !!config.aboutUs,
    federbet: () => !!config.federbet,
    telegram: () => (Array.isArray(config.telegram) ? config.telegram : []),
    wechat: () => !!config.wechat,
    support: () => !!config.supportEmail,
    supportEmail: () => config.supportEmail,
    supportChat: () => !!config.supportChat,
    languages: () => config.languages,
    defaultLanguage: () => config.defaultLanguage,
    scatter: () => !!config.scatter,
    google: () => !!config.google,
    facebook: () => !!config.facebook,
    twitter: () => !!config.twitter,
    geoLocales: () => config.geoLocales,
    canonical: () => config.canonical,
  };
};

initWhitelabel(whitelabelConfig);

export default whitelabel;
