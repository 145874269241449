import { useSelector } from "react-redux";

import { getHomepageLiveView } from "selectors/homepageSelectors";

import GroupEvents from "components/sports/GroupEvents";

export const Live = ({ live }: { live: boolean }) => {
  const liveView = useSelector(getHomepageLiveView);

  return (
    <>
      {liveView.map((x) => (
        <GroupEvents
          key={x.name}
          live={true}
          startOpen={true}
          forceOpen={true}
          group={x}
          shortLeague={false}
          invertCountBadge={true}
          showAll={!live && "/live"}
          showLiveIcon
          showSportIcon
          topHighlight={false}
          hideBadge
        />
      ))}
    </>
  );
};
