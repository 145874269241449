import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Container } from "reactstrap";

import { getPage } from "selectors/pageSelectors";

import { CoinsWidget } from "components/nav/CoinsWidget.tsx";

import whitelabel from "utils/whitelabelUtils.ts";

import { Marks } from "./Marks.tsx";
import { Navigation } from "./Navigation.tsx";
import { Widget } from "./Widget.tsx";

const Footer = () => {
  const { i18n, t } = useTranslation();

  const { payload: page } = useSelector(getPage);

  const footer = page?.data?.template?.footer ?? {};
  const footerSeoData = footer.seoContent?.[i18n.language] ?? footer[i18n.language] ?? [];

  return (
    <footer className="px-0 pb-0">
      <Container className="footer-wrapper">
        <Widget footerData={footerSeoData} />
        <Navigation columns={footer.navigation} />
        <CoinsWidget />
        <div className="row mt-4">
          <div className="col-12 d-flex justify-content-center">
            {!!whitelabel?.disclaimer() && (
              <p
                className="disclaimer-text-xs mt-3 hidden-xs hidden-sm text-center col-12"
                style={{
                  fontSize: "14px",
                  color: "#838FA3",
                  lineHeight: "16px",
                }}
              >
                {t("PLATFORM_DESCRIPTION")}
              </p>
            )}
          </div>
        </div>
        <Marks />
        <div className="row mt-4">
          <div className="col-12 d-flex justify-content-center">
            <p style={{ fontSize: "14px", color: "#838FA3" }}>
              <>{whitelabel?.name()}</>© {new Date().getFullYear()}
            </p>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
