import { faCheckCircle } from "@fortawesome/free-solid-svg-icons/faCheckCircle";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons/faTimesCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import React from "react";

export const FormSavedMessage = ({ className, spanStyles, text = "Saved", ...props }) => (
  <div className={cx("settings__saved", className)} {...props}>
    <FontAwesomeIcon icon={faCheckCircle} />
    <span style={spanStyles}>{text}</span>
  </div>
);

export const FormErrorMessage = ({
  className = "",
  spanStyles = {},
  text = "Cannot be saved",
  ...props
}) => (
  <div className={cx("settings__error", className)} {...props}>
    <FontAwesomeIcon icon={faTimesCircle} />
    <span style={spanStyles}>{text}</span>
  </div>
);
