import React from "react";
import { Modal, ModalBody, Spinner } from "reactstrap";

const LoadingPopup = ({ children }) => {
  return (
    <Modal centered isOpen>
      <ModalBody className="d-flex">
        <div>{children}</div>
        <Spinner className="ml-auto" color="dark" size="md" />
      </ModalBody>
    </Modal>
  );
};

export default LoadingPopup;
