import { getError } from "api/api";
import { fetchBreadcrumbs, fetchEventById, fetchEvents } from "api/eventsApi";

import { getAccountIsVip } from "selectors/loginSelectors";

import {
  EVENTS_LOADED,
  EVENTS_LOADING,
  EVENTS_LOAD_ERROR,
  EVENT_BREADCRUMBS_LOADED,
  EVENT_BREADCRUMBS_RESET,
  EVENT_LOADED,
  EVENT_LOADING,
  EVENT_LOAD_ERROR,
  EVENT_MONITOR_START,
  EVENT_MONITOR_STOP,
} from "actions/actionTypes";

export const loadEvents = (eventIds) => async (dispatch, getState) => {
  const loading = getState().events.loading;
  const isVip = getAccountIsVip(getState());
  if (loading) {
    eventIds = eventIds.filter((x) => !(loading[x] && loading[x].loading));
  }
  if (!eventIds.length) return;

  dispatch({ type: EVENTS_LOADING, payload: eventIds });
  try {
    const fetchResult = await fetchEvents({ ids: eventIds, isVip });
    dispatch({
      type: EVENTS_LOADED,
      payload: fetchResult,
    });
    return fetchResult.events;
  } catch (e) {
    const error = getError(e);
    dispatch({ type: EVENTS_LOAD_ERROR, payload: { eventIds, error } });
  }
};

export const monitorEvent = (eventId) => ({
  type: EVENT_MONITOR_START,
  payload: eventId,
});

export const monitorEventEnd = (eventId) => ({
  type: EVENT_MONITOR_STOP,
  payload: eventId,
});

export const resetBreadcrumbs = (eventId) => async (dispatch) => {
  try{
    const breadcrumbs = await fetchBreadcrumbs(eventId);
    if (breadcrumbs?.events && breadcrumbs.events.length) {
      const eventIds = breadcrumbs.events.reduce(
        (a, x) => (x.type === breadcrumbs.event.type ? [...a, x._id] : a),
        []
      );
      for (const eventId of eventIds) {
        dispatch(monitorEventEnd(eventId));
      }
    }
  }catch (e) {
    console.error(e);
  } finally {
    dispatch({
      type: EVENT_BREADCRUMBS_RESET
    })
  }
}

export const loadBreadcrumbs = (eventId, cb) => async (dispatch) => {
  try {
    const breadcrumbs = await fetchBreadcrumbs(eventId);
    if (breadcrumbs?.events && breadcrumbs.events.length) {
      const eventIds = breadcrumbs.events.reduce(
        (a, x) => (x.type === breadcrumbs.event.type ? [...a, x._id] : a),
        []
      );
      dispatch(loadEvents([eventIds]));
      for (const eventId of eventIds) {
        dispatch(monitorEvent(eventId));
      }
      breadcrumbs.eventIds = eventIds;
    }
    dispatch({
      type: EVENT_BREADCRUMBS_LOADED,
      payload: { breadcrumbs },
    });
  } catch (e) {
    dispatch(resetBreadcrumbs(eventId));
  } finally {
    if (typeof cb === "function") cb();
  }
};

export const loadEventById = (eventId, project) => async (dispatch) => {
  dispatch({ type: EVENT_LOADING, payload: eventId });
  try {
    const event = await fetchEventById(eventId, project);

    dispatch({
      type: EVENT_LOADED,
      payload: event,
    });
  } catch (e) {
    const error = getError(e);
    dispatch({ type: EVENT_LOAD_ERROR, payload: { eventId, error } });
  }
};
