import { supportedProviders } from "@sbetdev2/dbmodel/enums";
import AnchorLink from "anchor-link";
import AnchorLinkBrowserTransport from "anchor-link-browser-transport";
import { broadcastTransaction, getEosBalance, placeBet } from "api/eosApi";
import { walletWithdraw } from "api/walletApi";

import { currencies } from "constants/currencies.ts";
import eosConfig from "constants/eos";

import { isBrowser, localStorageUtils } from "utils/browserUtils";
import { buildBetMemo } from "utils/contractUtils";
import { formatAssetQty, getTokenAmount } from "utils/eosUtils";

import { getDividends as getDividendsScatter } from "./scatterapi";

const contractName = "sportbet1bet";
const bankName = "sportbetdivs";
const walletContractName = "sportbetdepo";
const casinoContract = "decentcasino";

const identifier = "sportbetone";
const initLink = () => {
  if (!isBrowser()) return null;
  const transport = new AnchorLinkBrowserTransport();
  return new AnchorLink({
    transport,
    chains: [
      {
        chainId: "aca376f206b8fc25a6ed44dbdc66547c36c6c33e3a119ffbeaef943642f0e906",
        nodeUrl: "https://eos.greymass.com", // "https://eos.dfuse.eosnation.io:443", // "https://eos.greymass.com",
      },
    ],
  });
};

const link = initLink();
let session = null;

export const makeTransaction = async (action, broadcast = true, headers = {}) => {
  const account = await init();
  if (!account) return {};

  const actions = [
    {
      account: eosConfig.signTransactionBy.name,
      name: "payforcpu",
      authorization: [
        {
          actor: eosConfig.signTransactionBy.name,
          permission: eosConfig.signTransactionBy.authority,
        },
      ],
      data: {},
    },
    ...(Array.isArray(action) ? action : [action]).map((x) => {
      x.authorization = [session.auth];
      return x;
    }),
  ];

  const transaction = await session.transact({ actions }, { broadcast: false });

  if (!broadcast) return transaction;

  const data = {
    compression: "none",
    signatures: transaction.signatures,
    transaction: transaction.transaction,
  };
  const response = await broadcastTransaction(data, headers);

  return response.error ? response : response.result;
};

export const getBalance = async (code, account, token) => {
  const balance = await getEosBalance(code, account, token);
  return balance ? getTokenAmount(balance) : 0;
};

const init = async (renew = false) => {
  if (!isBrowser()) return null;
  if (session && session.auth) {
    return {
      name: session.auth.actor,
    };
  }

  if (renew) {
    session = await link.restoreSession(identifier);
  } else {
    const result = await link.login(identifier);
    session = result.session;
  }

  if (session && session.auth && session.auth.actor) {
    localStorageUtils.setItem("activeWallet", "anchor");
    return {
      name: session.auth.actor,
    };
  }
  localStorageUtils.removeItem("activeWallet");

  return null;
};

const getAccountBalance = async (currencyName = currencies.EOS.name) => {
  try {
    const account = await init();
    if (!account) return undefined;

    const currencyMetadata = currencies[currencyName];
    return await getBalance(currencyMetadata.code, account.name, currencyMetadata.name);
  } catch (e) {
    return undefined;
  }
};

const login = async () => ({});

const logout = async () => {
  try {
    if (session) session.remove();
    session = null;
    link.clearSessions(identifier);
    // eslint-disable-next-line no-empty
  } catch (e) {}
};

const betTransfer = async (
  accountName,
  bets,
  amount,
  referral,
  currency = "EOS",
  options
) => {
  const { code, precision } = currencies[currency];
  if (referral === accountName) referral = "";
  const memo = buildBetMemo(bets, referral === accountName ? "" : referral, options);

  const result = await makeTransaction(
    {
      account: code,
      name: "transfer",
      data: {
        from: accountName,
        to: contractName,
        quantity: formatAssetQty(amount, currency, precision),
        memo,
      },
    },
    false
  );

  const { signatures, transaction } = result;

  return await placeBet({ signatures, transaction, compression: "none" });
};

const betsTransfer = async (accountName, bets, referral, currency = "EOS") => {
  const { code, precision } = currencies[currency];
  const actions = [];
  for (const item of bets) {
    const memo = buildBetMemo(
      item.bets,
      referral === accountName ? "" : referral,
      item.options
    );

    actions.push({
      account: code,
      name: "transfer",
      data: {
        from: accountName,
        to: contractName,
        quantity: formatAssetQty(item.amount, currency, precision),
        memo,
      },
    });
  }

  const result = await makeTransaction(actions, false);

  const { signatures, transaction } = result;

  return await placeBet({ signatures, transaction, compression: "none" });
};

const authTransaction = async (nonce) => {
  return await makeTransaction(
    {
      account: contractName,
      name: "auth",
      data: { nonce },
    },
    false
  );
};

const betComplete = async () => ({});

const sendWithdrawal = async (amount, externalAddress) => {
  const currency = currencies.BTC;
  const account = await init();
  if (!account) return undefined;

  return await makeTransaction({
    account: currency.code,
    name: "transfer",
    data: {
      from: account.name,
      to: walletContractName,
      quantity: formatAssetQty(amount, currency.name, currency.precision),
      memo: externalAddress,
    },
  });
};

const sendTokenWithdrawal = async ({
  to,
  contract,
  quantity,
  code,
  tfaCode,
  providerService,
}) => {
  const account = await init();
  if (!account) return undefined;

  const contractName = currencies[code]?.code ?? contract;

  const signedTrx = await makeTransaction(
    {
      account: contractName,
      name: "transfer",
      data: {
        from: account.name,
        to: "sportbetcurr",
        quantity,
        memo: to,
      },
    },
    false,
    { tfaCode }
  );

  const data = {
    compression: "none",
    signatures: signedTrx.signatures,
    transaction: signedTrx.transaction,
  };

  return await walletWithdraw(
    {
      to,
      code,
      transaction: data,
      quantity,
      anchor: true,
    },
    { tfaCode }
  );
};

const sendEosWithdrawal = async ({
  to,
  memo,
  code,
  quantity,
  tfaCode,
  providerService,
}) => {
  const account = await init();
  if (!account) return;

  return await makeTransaction(
    {
      account: code,
      name: "transfer",
      data: {
        from: account.name,
        to: to,
        quantity,
        memo,
      },
    },
    true,
    { tfaCode }
  );
};

const withdraw = {
  [supportedProviders.ptokens]: sendEosWithdrawal,
  [supportedProviders.bitgo]: sendTokenWithdrawal,
};

const approveWithdrawal = async () => ({});
const rejectWithdrawal = async () => ({});
const getDividends = getDividendsScatter;

const claimDividends = async () => {
  const account = await init();
  if (!account) return;

  await makeTransaction({
    account: bankName,
    name: "claim",
    data: {
      from: account.name,
    },
  });
};

const stakeTokens = async (amount) => {
  const account = await init();
  if (!account) return;

  await makeTransaction({
    account: bankName,
    name: "stake",
    data: {
      from: account.name,
      quantity: formatAssetQty(amount, "SBET"),
    },
  });
};

const unstakeTokens = async (amount) => {
  const account = await init();
  if (!account) return;

  await makeTransaction({
    account: bankName,
    name: "unstake",
    data: {
      from: account.name,
      quantity: formatAssetQty(amount, "SBET"),
    },
  });
};

const casinoDeposit = async (amount, currency, memo) => {
  const account = await init();
  if (!account) return;

  const { code, precision } = currencies[currency];

  const { transaction, signatures } = await makeTransaction(
    {
      account: code,
      name: "transfer",
      data: {
        from: account.name,
        to: casinoContract,
        quantity: formatAssetQty(amount, currency, precision),
        memo: memo,
      },
    },
    false
  );

  return { signatures, transaction, compression: "none" };
};

const convertBTC = async (amount) => {
  const account = await init();
  if (!account) return;

  const { code, precision, name: currency } = currencies.BTC;

  const result = await makeTransaction({
    account: code,
    name: "transfer",
    data: {
      from: account.name,
      to: "sportbetcurr",
      quantity: formatAssetQty(amount, currency, precision),
      memo: "",
    },
  });

  const { signatures, transaction } = result;

  return await { signatures, transaction, compression: "none" };
};

export {
  init,
  getAccountBalance,
  login,
  logout,
  betTransfer,
  betsTransfer,
  authTransaction,
  betComplete,
  sendWithdrawal,
  approveWithdrawal,
  rejectWithdrawal,
  getDividends,
  claimDividends,
  stakeTokens,
  unstakeTokens,
  withdraw,
  casinoDeposit,
  convertBTC,
};
