import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Button } from "reactstrap";

import { PromoData } from "containers/popups/PromoInfo/PromoInfo.tsx";

import { getLoginProgress } from "selectors/loginSelectors";

import { baseUrls } from "constants/urls";

import { localStorageUtils } from "utils/browserUtils";

type Props = PromoData & { showMoreInfo: () => void };

const PromoCard: React.FC<Props> = ({
  id,
  title,
  minDep,
  maxBet,
  coverImage,
  sportRollover,
  casinoRollover,
  showMoreInfo,
}) => {
  const logininProgress = useSelector(getLoginProgress);

  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className={"promo-grid__card"}>
      <div className={"promo-grid__card-zoom"}>
        <picture>
          <source srcSet={coverImage} type="image/png" />
          <img src={coverImage} alt={title} />
        </picture>
      </div>
      <div className="promo-grid__card-body">
        <h6 className="mb-3 promo-grid__card-title">{title}</h6>
        <ul className="promo-grid__card-benefits">
          {!!minDep && (
            <li>
              Minimum deposit: <b>${minDep} in crypto</b>
            </li>
          )}
          {!!maxBet && (
            <li>
              Maximum bet: <b>${maxBet} in crypto</b>
            </li>
          )}
          <li>
            Rollover on sports: <b>{sportRollover}x</b>
          </li>
          <li>
            Rollover on casino: <b>{casinoRollover}x</b>
          </li>
        </ul>
        <Button
          className="mt-2 rounded btn-success btn-black-text w-100"
          onClick={() => {
            localStorageUtils.setItem("activeBonusId", id);
            history.push(`#signup`, { from: baseUrls.promotion });
          }}
          disabled={!!logininProgress}
        >
          {t("Join Now")}
        </Button>
        <a
          className="mt-2 w-100 d-block text-center p-2"
          onClick={(e) => {
            e.preventDefault();
            showMoreInfo();
          }}
        >
          {t("More info")}
        </a>
      </div>
    </div>
  );
};

export default PromoCard;
