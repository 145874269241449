import PBTC from "images/tokens/BTC.svg";
import PETH from "images/tokens/ETH.svg";
import PLTC from "images/tokens/LTC.svg";
import USDT from "images/tokens/USDT.svg";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

import { getAccountUser } from "selectors/loginSelectors";

import { localStorageUtils } from "utils/browserUtils";

import styles from "./styles.module.scss";

const DetaislUrl =
  "https://tether.io/news/tether-announces-strategic-transition-to-prioritize-community-driven-blockchain-support/#:~:text=Tether%20will%20stop%20minting%20USD,and%20announced%20around%20that%20time.";
const TelegramUrl = "https://t.me/pNetworkDefi/145623/147391";

const cryptoBlocks = [
  {
    titles: [{ icon: USDT, name: "USDT (EOS)" }],
    descriptions: [
      <>
        - Tether has announced they&apos;re stopping the minting of USD₮ on EOS and
        Algorand from June 24, 2024.{" "}
        <a href={DetaislUrl} className={styles.link} target="_blank" rel="noreferrer">
          More details here
        </a>
        .
      </>,
      "- But don't worry — you can still redeem your USD₮ on these blockchains as usual for the next 12 months. Keep an eye out for any updates closer to that time. ",
    ],
  },
  {
    titles: [
      { icon: PBTC, name: "pBTC" },
      { icon: PETH, name: "pETH," },
      { icon: PLTC, name: "pLTC" },
    ],
    descriptions: [
      <>
        - Those tokens will be discounted by pNetwork on November 30. Please, withdraw or
        unpeg those tokens before the deadline. More details in their{" "}
        <a href={TelegramUrl} className={styles.link} target="_blank" rel="noreferrer">
          Telegram post
        </a>
        .
      </>,
    ],
  },
];

const WalletNotification = () => {
  const alreadyShown = localStorageUtils.getItem("walletNotificationShown");

  const user = useSelector(getAccountUser);
  const history = useHistory();

  const isNeedShow = useCallback(
    () => !alreadyShown && user && user.provider === "scatter",
    [alreadyShown, user]
  );

  const [isShown, setIsShown] = useState<boolean>(isNeedShow());

  useEffect(() => {
    if (isNeedShow()) {
      setIsShown(true);
    }
  }, [user?.provider]);

  const onClose = () => {
    localStorageUtils.setItem("walletNotificationShown", 1);
    setIsShown(false);
  };

  if (!isShown) return null;

  return (
    <Modal centered isOpen autoFocus={false} className={styles.wrapper} toggle={onClose}>
      <ModalHeader toggle={onClose}>Important notice</ModalHeader>
      <ModalBody style={{ paddingBottom: 16, borderRadius: "8px" }}>
        <div className={styles.body}>
          <p className={styles.subtitle}>
            Starting November 25th, we&apos;ll be discontinuing support for these EOS
            tokens:
          </p>
          {cryptoBlocks.map(({ titles, descriptions }, index) => (
            <div className={styles.block} key={index}>
              <div className={styles.block_headers}>
                {titles.map(({ icon, name }, index) => (
                  <div className={styles.block_headers_header} key={index}>
                    <img className={styles.cryptoImage} src={icon} alt={name + "Icon"} />
                    <p className={styles.cryptoName}>{name}</p>
                  </div>
                ))}
              </div>
              {descriptions.map((text, index) => (
                <p key={index} className={styles.block_description}>
                  {text}
                </p>
              ))}
            </div>
          ))}
          <button
            className={styles.button}
            onClick={() => history.replace({ hash: "#wallet" })}
          >
            Wallet
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default WalletNotification;
