import {
  BONUS_ERROR_CLEAR,
  BONUS_REGISTER_ERROR,
  BONUS_REGISTER_LOADED,
  BONUS_REGISTER_LOADING,
  BONUS_REGISTER_REFRESH,
} from "actions/actionTypes";

const initialState = {
  code: null,
  loading: false,
  loaded: false,
  error: null,
};

const bonusCodeReducer = (state = initialState, action) => {
  switch (action.type) {
    case BONUS_REGISTER_LOADING:
      return { ...state, loading: true };
    case BONUS_REGISTER_LOADED:
      return { ...state, loaded: true, loading: false, code: action.payload?.code };
    case BONUS_REGISTER_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload?.error,
      };
    case BONUS_ERROR_CLEAR:
      return {
        ...state,
        error: null,
      };
    case BONUS_REGISTER_REFRESH:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default bonusCodeReducer;
