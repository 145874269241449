export const getApiHostname = (hostname: string) => {
  if (hostname.includes("localhost")) return `localhost:5000`;
  const dots = hostname.split(".");
  let api = `api.${hostname}`;
  if (dots.length > 2) {
    api = `api-${hostname}`;
  }
  return api;
};

export const getHostname = () => {
  if (typeof window !== "undefined") {
    const { hostname, host } = window.location;
    return hostname === "localhost" ? host : hostname;
  } else return "";
};

export const getRootDomain = (hostname: string) => {
  const parts = hostname.split(".");
  const partsLength = parts.length;
  if (partsLength <= 2) return hostname;

  return parts.slice(partsLength - 2).join(".");
};
