import { getError } from "api/api";
import { fetchHomepageEvents } from "api/eventsApi";
import { AppDispatch, RootState } from "redux";

import { getHomepageMonitoring, getHomepageSportId } from "selectors/homepageSelectors";
import { getAccountIsVip } from "selectors/loginSelectors";

import {
  HOMEPAGE_CHANGE_SPORT,
  HOMEPAGE_RESET_SPORT,
  HOMEPAGE_SPORT_ERROR,
  HOMEPAGE_SPORT_LOAD,
  HOMEPAGE_SPORT_LOADED,
} from "actions/actionTypes";

export const changeHomepageSport = (sport: number) => ({
  type: HOMEPAGE_CHANGE_SPORT,
  payload: { sport },
});

export const resetHomepageSport = () => ({
  type: HOMEPAGE_RESET_SPORT,
});

let timerIds: NodeJS.Timeout[] = [];

export const loadHomepageSport =
  () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const sport = getHomepageSportId(state);
    try {
      dispatch({
        type: HOMEPAGE_SPORT_LOAD,
        payload: { sport },
      });
      const isVip = getAccountIsVip(state);
      const data = await fetchHomepageEvents(sport, isVip);
      data.prematchEvents = data.prematchEvents2;
      data.prematchLeagues = data.prematchLeagues2;
      data.liveEvents = data.liveEvents2;
      data.liveLeagues = data.liveLeagues2;
      dispatch({
        type: HOMEPAGE_SPORT_LOADED,
        payload: data,
      });

      const timerId = setTimeout(() => {
        const state = getState();
        const monitoring = getHomepageMonitoring(state);
        if (!monitoring) return;
        dispatch(loadHomepageSport());
      }, 1000);

      timerIds.push(timerId);
    } catch (e) {
      const error = getError(e);
      dispatch({
        type: HOMEPAGE_SPORT_ERROR,
        payload: { sport, error },
      });
    }
  };

export const clearAllHomepageTimers = () => {
  timerIds.forEach((timerId) => clearTimeout(timerId));
  timerIds = [];
};
