import Loadable from "react-loadable";

import Loading from "components/common/Loaders/Loading.tsx";

const LazyLandingPage = Loadable({
  loader: () => import(/* webpackChunkName: "landing-page"*/ "./LandingPage.jsx"),
  loading: Loading,
  modules: ["landing-page"],
});

export default LazyLandingPage;
