export const homepageTitles = {
  en: "Bitcoin and Crypto sports betting ✓ Best cryptocurrency betting site sportbet.one",
  ru: "Ставки на спорт в биткойнах и криптовалютах ✓ Лучший сайт ставок в криптовалюте в США sportbet.one",
  "zh-CN": "比特币和加密货币体彩 ✓ USA 最好的加密投注网页 sportbet.one",
  "zh-TW": "比特幣和加密貨幣體彩 ✓ USA 最好的加密投注網頁 sportbet.one",
  ko: "비트코인과 암호화폐 스포츠 베팅 ✓ 최고의 미국 가상화폐 베팅 사이트 sportbet.one",
  tr: "Bitcoin ve Kripto para spor bahisi ✓ ABD'nin en iyi kripto bahis sitesi sportbet.one",
};

export const homepageDescriptions = {
  en: "LIVE and Pre-Match Sports Betting with BTC (Bitcoin), Ethereum and other cryptocurrency ✓ Highest odds ✓ Instant payouts ✓ Play with cryptocurrency at Sportbet.one",
  ru: "Ставки на спорт в прямом эфире и пематч с использованием BTC (биткойнов), Ethereum и другой криптовалюты ✓ Самые высокие шансы ✓ Мгновенные выплаты ✓ Играйте в криптовалюте на Sportbet.one",
  "zh-CN":
    "使用 BTC, ETH 及其它加密货币实时和赛前体彩投注 ✓ 最高赔率 ✓ 即时付款 ✓ 加密货币在 Sportbet.one 上畅玩",
  "zh-TW":
    "使用 BTC, ETH 及其它加密貨幣即時和賽前體彩投注 ✓ 最高賠率 ✓ 即時付款 ✓ 加密貨幣在 Sportbet.one 上暢玩",
  ko: "라이브와 프리매치 BTC (비트코인), 이더리움, 그리고 기타 가상화폐 스포츠 베팅 ✓ 최고승률 ✓ 즉시지급 ✓ 가상화폐로 Sportbet.one에서 베팅하세요",
  tr: "BTC (Bitcoin), Ethereum ve diğer kripto paralarla CANLI ve Maç Önü Spor Bahisleri ✓ En yüksek oranlar ✓ Anında ödeme ✓ Sportbet.one'da kripto para ile oyna",
};

export const liveTitles = {};

export const slugs = {
  about_us: "about-us",
  privacy_policy: "privacy-policy",
  responsible_gambling: "responsible-gambling",
};
