import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "reactstrap";

import { getLoginProgress } from "selectors/loginSelectors";

import { showSignUpPopup } from "actions/loginActions.ts";

const SignUpButton = ({ className }) => {
  const [isHighlighted, setIsHighlighted] = useState(false);

  const logininProgress = useSelector(getLoginProgress);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleScroll = () => {
    setIsHighlighted(window.scrollY > 300);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Button
      color="success"
      size="sm"
      className={`signup-btn ${className} ${isHighlighted && "signup-btn__highlighted"}`}
      onClick={() => dispatch(showSignUpPopup())}
      disabled={!!logininProgress}
    >
      <span>{t("Join Now")}</span>
    </Button>
  );
};

export default SignUpButton;
