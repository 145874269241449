import { useEffect, useState } from "react";

const ClientSideRenderer = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const [postHydration, setPostHydration] = useState(false);
  useEffect(() => {
    setPostHydration(true);
  }, []);
  return <>{postHydration && children}</>;
};

export default ClientSideRenderer;
