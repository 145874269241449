import Loadable from "react-loadable";

import Loading from "components/common/Loaders/Loading.tsx";

const LazyLandingCoinsPage = Loadable({
  loader: () =>
    import(/* webpackChunkName: "landing-coins-page"*/ "./LandingCoinsPage.tsx"),
  loading: Loading,
  modules: ["landing-coins-page"],
});

export default LazyLandingCoinsPage;
