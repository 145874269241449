import { connectRouter } from "connected-react-router";
import { createBrowserHistory } from "history";
import reducers from "reducers";
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import ReduxThunk from "redux-thunk";

import { isBrowser, windowUtils } from "utils/browserUtils";

export let store = null;
export const history = isBrowser() ? createBrowserHistory() : null;
const middleware = applyMiddleware(ReduxThunk);
const devToolsExtension = /* isDev && */ windowUtils.get("__REDUX_DEVTOOLS_EXTENSION__");
const devTools = devToolsExtension && devToolsExtension();
const enhancer = devTools ? compose(middleware, devTools) : middleware;
const setUpStore = compose(middleware, enhancer)(createStore);

export default function configureStore(initialState = {}) {
  const allReducers = history
    ? { router: connectRouter(history), ...reducers }
    : reducers;
  store = setUpStore(combineReducers(allReducers), initialState);
  return store;
}
