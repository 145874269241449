import { FC } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as NoEventsIcon } from "img/noEvents.svg";

const NoEventsPlaceholder: FC = () => {
  const { t } = useTranslation();
  return (
    <div className="d-flex px-3 pt-3 pb-2">
      <NoEventsIcon />
      <div className="d-flex flex-column ml-1">
        <span className="text-white small">{t("currentlyNoEventsScheduled")}</span>
        <span className="small">{t("exploreOtherSportsOrWatchForUpdates")}</span>
      </div>
    </div>
  );
};

export default NoEventsPlaceholder;
