import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import { FC } from "react";
import { DropdownToggleProps, DropdownToggle as ReactDropdownToggle } from "reactstrap";

const DropdownToggle: FC<DropdownToggleProps> = ({
  open,
  caret,
  children,
  className,
  caretStacked,
  ...rest
}) => {
  const cls = cx(`bt-dropdown-toggle`, className, {
    open,
    "caret-stacked": caret && caretStacked,
  });

  return (
    <ReactDropdownToggle {...rest} caret={caret} className={cls}>
      {children}
      {caret && (
        <span className="caret-icon">
          <FontAwesomeIcon icon={faChevronDown} />
        </span>
      )}
    </ReactDropdownToggle>
  );
};

export default DropdownToggle;
