import { createContext, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { setLiveSportsCount } from "actions/sportActions";
import { loadExchangeRates } from "actions/walletActions";

import { getApiHostname, getHostname } from "utils/domainUtils.ts";

const AppContext = createContext({
  requestLanguage: null,
  loadScript: (url, onLoad) => {},
});

export const AppContextProvider = ({ children }) => {
  const socket = useRef(null);
  const [socketConnected, setSocketConnected] = useState(false);
  const loadedScripts = useRef([]);
  const eventListeners = useRef({});
  const socketListeners = useRef([]);
  const notificationsCache = useRef({});
  const dispatch = useDispatch();
  const apiHostname = getApiHostname(getHostname());
  const wssUrl = `//${apiHostname}/ws/connect`;
  useEffect(() => {
    function connect() {
      try {
        if (!socketConnected) {
          socket.current = new WebSocket(wssUrl);
          socket.current.onopen = () => {
            setSocketConnected(true);
          };
          socket.current.onclose = function () {
            setSocketConnected(false);
            setTimeout(function () {
              connect();
            }, 30000);
          };
          socket.current.onerror = function () {
            socket.current.close();
          };
          socket.current.addEventListener("message", (event) => {
            try {
              const { type, data } = JSON.parse(event.data);
              notificationsCache.current[type] = data;
              if (eventListeners.current[type]) {
                eventListeners.current[type].forEach((callback) => callback(data));
              }
            } catch (e) {
              console.error(e);
            }
          });
        }
      } catch (e) {
        console.error(e);
      }
    }

    connect();

    dispatch(loadExchangeRates());

    try {
      if (window.self !== window.top) {
        window.top.postMessage("IN_IFRAME", "*");
      }
    } catch (e) {}
  }, []);

  const addSocketListener = (event, callback) => {
    if (!socket.current) return false;
    if (!eventListeners.current[event]) {
      eventListeners.current[event] = [];
    }
    eventListeners.current[event].push(callback);
    if (notificationsCache.current[event]) {
      callback(notificationsCache.current[event]);
    }
    if (!socketListeners.current.includes(event)) {
      socketListeners.current.push(event);
    }

    return true;
  };

  const removeSocketListener = (event, callback) => {
    if (eventListeners.current[event]) {
      eventListeners.current[event].filter((x) => x !== callback);
    }
  };

  useEffect(() => {
    addSocketListener("live-sports", (data) => {
      dispatch(setLiveSportsCount(data));
    });
  }, []);

  const loadScript = (url, onLoad = () => {}) => {
    if (loadedScripts.current.includes(url)) {
      onLoad();
      return;
    }
    loadedScripts.current.push(url);
    const script = document.createElement("script");
    script.src = url;
    script.async = true;
    script.onload = onLoad;
    document.body.appendChild(script);
  };

  return (
    <AppContext.Provider
      value={{
        loadScript,
        addSocketListener,
        removeSocketListener,
        socketConnected,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;
