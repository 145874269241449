import Loadable from "react-loadable";

import Loading from "components/common/Loaders/Loading.tsx";

const LazyRulesPage = Loadable({
  loader: () => import(/* webpackChunkName: "rules-page"*/ "./RulesPage.tsx"),
  loading: Loading,
  modules: ["rules-page"],
});

export default LazyRulesPage;
