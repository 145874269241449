import api, { addAuth } from "api/api";

export const getTfaSettings = async (token) => {
  const response = await api.get("/users/tfa", addAuth(token));
  return response?.data;
};

export const userTfaInit = async (token) => {
  const response = await api.post("/users/tfa/init", {}, addAuth(token));
  return response?.data?.tfaSecret;
};

export const userTfaReset = async (token) => {
  return await api.post("/users/tfa/reset", {}, addAuth(token));
};

export const userTfaActivate = async (token, tfaCode) => {
  const response = await api.post("/users/tfa/activate", { tfaCode }, addAuth(token));
  return response?.data?.tfaRecovery;
};

export const userTfaGenerateRecovery = async (token, tfaCode) => {
  const response = await api.post(
    "/users/tfa/generate-recovery",
    { tfaCode },
    addAuth(token)
  );
  return response?.data?.tfaRecovery;
};

export const userTfaDeactivate = async (token, tfaCode) => {
  return await api.post("/users/tfa/deactivate", { tfaCode }, addAuth(token));
};

export const userTfaRecovery = async (token, tfaRecovery) => {
  return await api.post("/users/tfa/recovery", { tfaRecovery }, addAuth(token));
};

export const userTfaUnlock = async (token, tfaCode) => {
  return await api.post("/users/tfa/unlock", { tfaCode }, addAuth(token));
};

export const userExclude = async (token) => {
  return await api.post("/users/exclude", {}, addAuth(token));
};

export const userReactivate = async (token) => {
  return await api.post("/users/reactivate", {}, addAuth(token));
};

export const getSettings = async (token) => {
  return await api.get("/users/settings", addAuth(token));
};

export const saveSettings = async (token, settings) => {
  return await api.post("/users/settings", settings, addAuth(token));
};

export const unsubscribeUser = async (email, code) => {
  return await api.post("/users/unsubscribe", { email, code });
};

export const saveUserEmail = async (token, email) => {
  return await api.post("/users/email", { email }, addAuth(token));
};

export const resendEmailActivation = async (token) => {
  return await api.post("/users/email/resend-activation", {}, addAuth(token));
};

export const saveUserSubscribe = async (token, subscribe) => {
  return await api.post("/users/subscribe", { subscribe }, addAuth(token));
};

export const saveUserOnsiteEnabled = async (token, enabled) => {
  return await api.post("/users/onsite/enabled", { enabled }, addAuth(token));
};

export const saveUserExclude = async (excludeToken) => {
  return await api.post("/users/exclude", { excludeToken });
};

export const saveUserExcludeExtend = async (period, excludeToken) => {
  return await api.post("/users/exclude-extend", { period, excludeToken });
};

export const sendExcludeEmail = async (token) => {
  return await api.post("/users/exclude-email", {}, addAuth(token));
};

export const saveFirstLoginAfterReactivation = async (
  token,
  isFirstLoginAfterReactivation
) => {
  return await api.post(
    "/users/reactivate/first-login",
    { isFirstLoginAfterReactivation },
    addAuth(token)
  );
};
