import cx from "classnames";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Nav } from "reactstrap";

import { getEventsCountByHours, getSportDateFilter } from "selectors/sportsSelectors";

import { setSportDateFilter } from "actions/sportActions";

const SportDateFilter = ({ className }) => {
  const [open, setOpen] = useState(false);
  const dateFilter = useSelector(getSportDateFilter);
  const eventsCount = useSelector(getEventsCountByHours);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const filterMobile = (filter) => (
    <DropdownItem
      onClick={() => dispatch(setSportDateFilter(filter))}
      className={cx({
        active: dateFilter === filter,
      })}
    >
      <span>{t(filter || "All")}</span>
      <span className={"sport-date-filter__count"}>{eventsCount[filter || "ALL"]}</span>
    </DropdownItem>
  );

  return (
    <div className={cx("sport-date-filter", className)}>
      <Nav pills>
        <Dropdown nav isOpen={open} toggle={() => setOpen(!open)}>
          <DropdownToggle nav caret className={cx({ active: !!dateFilter })}>
            {t("Starts in")}: {t(dateFilter || "All")}
          </DropdownToggle>
          <DropdownMenu className={"dropdown-menu-filter"}>
            {filterMobile("")}
            {filterMobile("4H")}
            {filterMobile("12H")}
            {filterMobile("24H")}
          </DropdownMenu>
        </Dropdown>
      </Nav>
    </div>
  );
};

SportDateFilter.propTypes = {
  className: PropTypes.string,
};

export default SportDateFilter;
