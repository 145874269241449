import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";

import Button from "components/common/Button";
import Popup from "components/common/Popup.tsx";

import letterImg from "../../../images/letter.svg";

const AccountCreated = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  return (
    <Popup
      className={"activate-email-popup"}
      onClose={() => {
        history.replace(location.pathname);
      }}
    >
      <div className={"login-form"} style={{ marginTop: 0, textAlign: "center" }}>
        <div className={"login-message"}>
          <img src={letterImg} style={{ margin: "0 auto 18px", display: "block" }} />
          <p>
            {t(
              "Your account has been created. Please check your email for the validation link"
            )}
          </p>
        </div>

        {/* <Button
          secondary
          className={"btn-submit"}
          style={{ marginBottom: 16 }}
          onClick={() => {
            history.replace(location.pathname);
          }}
        >
          {t("Continue to the website")}
        </Button> */}
        <a
          href={"#"}
          style={{ display: "block", margin: "16px 0 0" }}
          onClick={(e) => {
            e.preventDefault();
            history.replace(location.pathname);
          }}
        >
          {t("Confirm email address later")}
        </a>
      </div>
    </Popup>
  );
};

export default AccountCreated;
