export const betOption = {
  acceptWorseOdds: 1,
  acceptBetterOdds: 2,
};

export const betStatus = {
  placed: 0,
  pending: 1,
  lose: 2,
  loseHalf: 3,
  stakeRefund: 4,
  winHalf: 5,
  win: 6,
  deleted: 7,
  cancelled: 8,
  rejected: 9,
  skipped: 10,
};

export const betStatusToString = {
  [betStatus.placed]: "Placed",
  [betStatus.pending]: "Pending",
  [betStatus.lose]: "Lose",
  [betStatus.loseHalf]: "Lose Half",
  [betStatus.stakeRefund]: "Refund",
  [betStatus.winHalf]: "Win Half",
  [betStatus.win]: "Win",
  [betStatus.deleted]: "Deleted",
  [betStatus.cancelled]: "Cancelled",
  [betStatus.rejected]: "Rejected",
  [betStatus.skipped]: "Skipped",
};
