import React from "react";

import Page from "pages/Page";

const withPage = (Component) => {
  return (props) => (
    <Page>
      <Component {...props} />
    </Page>
  );
};

export default withPage;
