import { supportedProviders } from "@sbetdev2/dbmodel/enums";
import { Fiats, Tokens } from "@sbetdev2/sbet-utils/constants";

export const depositDescription = {
  [Tokens.BTC]: [
    { text: "Coins will be deposited after 1 network confirmation", type: "primary" },
    {
      text: "Deposit delay can be up to 5 minutes after network confirmation",
      type: "primary",
    },
    {
      text: "Min deposit {{amount}}",
      tParams: { amount: "0.00005 BTC" },
      type: "primary",
    },
  ],
  [Tokens.LTC]: [
    { text: "Coins will be deposited after 2 network confirmations", type: "primary" },
    {
      text: "Deposit delay can be up to 5 minutes after network confirmation",
      type: "primary",
    },
    {
      text: "Min deposit {{amount}}",
      tParams: { amount: "0.0001 LTC" },
      type: "primary",
    },
  ],
  [Tokens.USDT]: [
    { text: "Coins will be deposited after 6 network confirmations", type: "primary" },
  ],
  [Tokens.ETH]: [
    { text: "Coins will be deposited after 6 network confirmations", type: "primary" },
  ],
  [Tokens.TRX_USDT]: [
    { text: "Coins will be deposited after 20 network confirmations", type: "primary" },
  ],
  [Tokens.EOS]: [
    { text: "Coins will be deposited after 1 network confirmation.", type: "primary" },
  ],
  [Tokens.SOL]: [
    { text: "Coins will be deposited after 50 network confirmations", type: "primary" },
  ],
};

export const tokenNetworks = {
  [Tokens.EOS]: [
    {
      name: "EOS",
      value: "EOS",
      token: Tokens.EOS,
      ptoken: Tokens.EOS,
      isPNetwork: true,
    },
  ],
  [Tokens.SBET]: [
    {
      name: "EOS",
      value: "EOS",
      token: Tokens.EOS,
      ptoken: Tokens.SBET,
      isPNetwork: true,
    },
  ],
  [Tokens.PETH]: [
    { name: "Ethereum", value: "ETH", token: Tokens.ETH, ptoken: Tokens.PETH },
    {
      name: "EOS (pETH)",
      value: "EOS",
      token: Tokens.EOS,
      ptoken: Tokens.PETH,
      isPNetwork: true,
    },
  ],
  [Tokens.PLTC]: [
    { name: "Litecoin", value: "LTC", token: Tokens.LTC, ptoken: Tokens.PLTC },
    {
      name: "EOS (pLTC)",
      value: "EOS",
      token: Tokens.EOS,
      ptoken: Tokens.PLTC,
      isPNetwork: true,
    },
  ],
  [Tokens.PBTC]: [
    { name: "Bitcoin", value: "BTC", token: Tokens.BTC, ptoken: Tokens.PBTC },
    {
      name: "EOS (pBTC)",
      value: "EOS",
      token: Tokens.EOS,
      ptoken: Tokens.PBTC,
      isPNetwork: true,
    },
  ],
  [Tokens.USDT]: [
    { name: "Ethereum (ERC20)", value: "ETH", token: Tokens.USDT, ptoken: Tokens.USDT },
    { name: "Tron (TRC20)", value: "TRX", token: Tokens.TRX_USDT, ptoken: Tokens.USDT },
    {
      name: "EOS",
      value: "EOS",
      token: Tokens.EOS,
      ptoken: Tokens.USDT,
      isPNetwork: true,
    },
  ],
  [Tokens.SOL]: [{ name: "Solana", value: "SOL", token: Tokens.SOL, ptoken: Tokens.SOL }],
};

export const depositTokenNetworks = {
  ...tokenNetworks,
  [Tokens.PBTC]: [
    { name: "Bitcoin", value: "BTC", token: Tokens.BTC, ptoken: Tokens.PBTC },
  ],
  [Tokens.PLTC]: [
    { name: "Litecoin", value: "LTC", token: Tokens.LTC, ptoken: Tokens.PLTC },
  ],
  [Tokens.PETH]: [
    { name: "Ethereum", value: "ETH", token: Tokens.ETH, ptoken: Tokens.PETH },
  ],
  [Tokens.USDT]: [
    { name: "Ethereum (ERC20)", value: "ETH", token: Tokens.USDT, ptoken: Tokens.USDT },
    { name: "Tron (TRC20)", value: "TRX", token: Tokens.TRX_USDT, ptoken: Tokens.USDT },
  ],
};

export const bonusCurrencies = {
  [Tokens.SEOS]: {
    name: Tokens.SEOS,
    realName: "EOS",
    code: "sportbetseos",
    precision: 4,
    shortDisplayPrecision: 2,
    fullDisplayPrecision: 4,
    walletDisplayPrecision: 4,
    displayName: "EOS",
    bonusName: "EOS (Bonus)",
    fromDisplayValue: (x: number) => x,
    toDisplayValue: (x: number) => x,
  },
  [Tokens.SBTC]: {
    name: Tokens.SBTC,
    realName: "PBTC",
    code: "sportbetsbtc",
    precision: 8,
    shortDisplayPrecision: 5,
    fullDisplayPrecision: 5,
    walletDisplayPrecision: 5,
    displayName: "mBTC",
    bonusName: "BTC (Bonus)",
    fromDisplayValue: (x: number) => x && x / 1000,
    toDisplayValue: (x: number) => x && x * 1000,
  },
  [Tokens.SLTC]: {
    name: Tokens.SLTC,
    realName: "PLTC",
    code: "sportbetsltc",
    precision: 8,
    shortDisplayPrecision: 5,
    fullDisplayPrecision: 5,
    walletDisplayPrecision: 8,
    displayName: "LTC",
    bonusName: "LTC (Bonus)",
    fromDisplayValue: (x: number) => x,
    toDisplayValue: (x: number) => x,
  },
  [Tokens.SETH]: {
    name: Tokens.SETH,
    realName: "PETH",
    code: "sportbetseth",
    precision: 9,
    shortDisplayPrecision: 5,
    fullDisplayPrecision: 5,
    walletDisplayPrecision: 9,
    displayName: "ETH",
    bonusName: "ETH (Bonus)",
    fromDisplayValue: (x: number) => x,
    toDisplayValue: (x: number) => x,
  },
  [Tokens.SUSD]: {
    name: Tokens.SUSD,
    realName: "USDT",
    code: "sportbetsusd",
    precision: 4,
    shortDisplayPrecision: 0,
    fullDisplayPrecision: 4,
    walletDisplayPrecision: 4,
    displayName: "USDT",
    bonusName: "USDT (Bonus)",
    fromDisplayValue: (x: number) => x,
    toDisplayValue: (x: number) => x,
  },
  [Tokens.SSOL]: {
    name: Tokens.SSOL,
    realName: "SOL",
    code: "sbetbonuscur",
    precision: 9,
    shortDisplayPrecision: 5,
    fullDisplayPrecision: 5,
    walletDisplayPrecision: 9,
    displayName: "SOL",
    bonusName: "SOL (Bonus)",
    fromDisplayValue: (x: number) => x,
    toDisplayValue: (x: number) => x,
  },
};

const basicCurrencies = {
  [Tokens.EOS]: {
    name: Tokens.EOS,
    code: "eosio.token",
    precision: 4,
    shortDisplayPrecision: 2,
    fullDisplayPrecision: 4,
    walletDisplayPrecision: 4,
    displayName: "EOS",
    fromDisplayValue: (x: number) => x,
    toDisplayValue: (x: number) => x,
    walletName: "EOS",
    mainNetwork: "EOS",
  },
  [Tokens.SBET]: {
    name: Tokens.SBET,
    code: "sportbetsbet",
    precision: 4,
    shortDisplayPrecision: 0,
    fullDisplayPrecision: 4,
    walletDisplayPrecision: 4,
    displayName: "SBET",
    fromDisplayValue: (x: number) => x,
    toDisplayValue: (x: number) => x,
    walletName: "SBET",
    mainNetwork: "EOS",
  },
  [Tokens.BTC]: {
    name: Tokens.BTC,
    code: "sportbetcurr",
    precision: 8,
    shortDisplayPrecision: 5,
    fullDisplayPrecision: 5,
    walletDisplayPrecision: 5,
    displayName: "mBTC",
    fromDisplayValue: (x: number) => x && x / 1000,
    toDisplayValue: (x: number) => x && x * 1000,
  },
  [Tokens.LTC]: {
    name: Tokens.LTC,
    code: "sportbetcurr",
    precision: 8,
    shortDisplayPrecision: 6,
    fullDisplayPrecision: 6,
    walletDisplayPrecision: 8,
    displayName: "LTC",
    fromDisplayValue: (x: number) => x,
    toDisplayValue: (x: number) => x,
    mainNetwork: "Litecoin",
    pNetwork: "EOS (pLTC)",
    walletName: "LTC",
  },
  [Tokens.ETH]: {
    name: Tokens.ETH,
    code: "sportbetcurr",
    precision: 9,
    shortDisplayPrecision: 5,
    fullDisplayPrecision: 5,
    walletDisplayPrecision: 9,
    displayName: "ETH",
    fromDisplayValue: (x: number) => x,
    toDisplayValue: (x: number) => x,
    mainNetwork: "Ethereum",
    pNetwork: "EOS (pETH)",
    walletName: "ETH",
  },
  [Tokens.USDT]: {
    name: Tokens.USDT,
    code: "tethertether",
    precision: 4,
    shortDisplayPrecision: 2,
    fullDisplayPrecision: 4,
    walletDisplayPrecision: 4,
    displayName: "USDT",
    fromDisplayValue: (x: number) => x,
    toDisplayValue: (x: number) => x,
    provider: supportedProviders.bitgo,
    walletName: "USDT",
    mainNetwork: "Tether",
    pNetwork: "EOS",
    feeName: "USDT",
  },
  [Tokens.SOL]: {
    name: Tokens.SOL,
    code: "sportbetcurr",
    precision: 9,
    shortDisplayPrecision: 5,
    fullDisplayPrecision: 5,
    walletDisplayPrecision: 9,
    displayName: "SOL",
    fromDisplayValue: (x: number) => x,
    toDisplayValue: (x: number) => x,
    provider: supportedProviders.bitgo,
    walletName: "SOL",
    mainNetwork: "Solana",
  },
  [Tokens.TRX_USDT]: {
    name: Tokens.TRX_USDT,
    code: "tethertether",
    precision: 4,
    shortDisplayPrecision: 2,
    fullDisplayPrecision: 4,
    walletDisplayPrecision: 4,
    displayName: "TRX:USDT",
    fromDisplayValue: (x: number) => x,
    toDisplayValue: (x: number) => x,
    provider: supportedProviders.bitgo,
    walletName: "TRX USDT",
    mainNetwork: "USDT (TRC20)",
    pNetwork: "EOS network (USDT-EOS)",
    feeName: "USDT",
  },
  [Tokens.PETH]: {
    name: Tokens.PETH,
    code: "eth.ptokens",
    precision: 9,
    shortDisplayPrecision: 5,
    fullDisplayPrecision: 5,
    walletDisplayPrecision: 9,
    displayName: "ETH",
    fromDisplayValue: (x: number) => x,
    toDisplayValue: (x: number) => x,
    mainNetwork: "Ethereum",
    pNetwork: "EOS (pETH)",
    walletName: "ETH",
    provider: supportedProviders.ptokens,
    networks: [{}],
  },
  [Tokens.PLTC]: {
    name: Tokens.PLTC,
    code: "ltc.ptokens",
    precision: 8,
    shortDisplayPrecision: 5,
    fullDisplayPrecision: 5,
    walletDisplayPrecision: 8,
    displayName: "LTC",
    fromDisplayValue: (x: number) => x,
    toDisplayValue: (x: number) => x,
    provider: supportedProviders.ptokens,
    pNetwork: "EOS (pLTC)",
    mainNetwork: "Litecoin",
    walletName: "LTC",
  },
  [Tokens.PBTC]: {
    name: Tokens.PBTC,
    code: "btc.ptokens",
    precision: 8,
    feePrecision: 3,
    shortDisplayPrecision: 5,
    fullDisplayPrecision: 5,
    walletDisplayPrecision: 5,
    displayName: "mBTC",
    fromDisplayValue: (x: number) => x && x / 1000,
    toDisplayValue: (x: number) => x && x * 1000,
    provider: supportedProviders.ptokens,
    walletName: "BTC",
    pNetwork: "EOS (pBTC)",
    mainNetwork: "Bitcoin",
  },
};

export const currencies = {
  ...basicCurrencies,
  ...bonusCurrencies,
};

export const bonusCurrenciesOrder = [
  Tokens.SBTC,
  Tokens.SUSD,
  Tokens.SETH,
  Tokens.SLTC,
  Tokens.SSOL,
  Tokens.SEOS,
];

export const currenciesOrderScatter = [
  Tokens.EOS,
  Tokens.PBTC,
  Tokens.USDT,
  Tokens.PETH,
  Tokens.PLTC,
  Tokens.SOL,
  Tokens.SBET,
];
export const currenciesOrderSocial = [
  Tokens.PBTC,
  Tokens.USDT,
  Tokens.PETH,
  Tokens.PLTC,
  Tokens.SOL,
  Tokens.EOS,
  Tokens.SBET,
];

export const fiatCurrencies = {
  [Fiats.USD]: { title: Fiats.USD, currency: Fiats.USD },
  [Fiats.EUR]: { title: Fiats.EUR, currency: Fiats.EUR },
  [Fiats.TRY]: { title: Fiats.TRY, currency: Fiats.TRY },
};

export const getBonusToken = (token: Tokens) => {
  switch (token) {
    case Tokens.EOS:
      return Tokens.SEOS;
    case Tokens.BTC:
      return Tokens.SBTC;
    case Tokens.LTC:
      return Tokens.SLTC;
    case Tokens.ETH:
      return Tokens.SETH;
    case Tokens.USDT:
      return Tokens.SUSD;
    case Tokens.SOL:
      return Tokens.SSOL;
    default:
      return null;
  }
};
