import { FC, ReactNode } from "react";
import { isDesktop, isMobile } from "react-device-detect";

import { isBrowser } from "utils/browserUtils";

type Props = {
  mobile?: boolean;
  desktop?: boolean;
  browser?: boolean;
  children: ReactNode;
};

const Only: FC<Props> = ({ mobile, desktop, browser, children }) => {
  if (mobile && !isMobile) return null;
  if (desktop && !isDesktop) return null;
  if (browser && !isBrowser()) return null;
  return children;
};

export default Only;
