import { HydrationState } from "utils/generalUtils.ts";

type Props = {
  serverCookie: string;
  isMobile: boolean;
};

export const useSetupHydrationState = ({ serverCookie, isMobile }: Props) => {
  if (typeof serverCookie !== "undefined" || typeof isMobile !== "undefined") {
    const ssrState: { isLoggedIn?: boolean; isMobile: boolean } = {
      isMobile,
    };
    if (serverCookie) ssrState.isLoggedIn = !!serverCookie;
    HydrationState.getInstance().setState(ssrState);
  }
};
