import produce from "immer";

export const compoundReducer =
  (initialState, ...reducers) =>
  (state = initialState, action) => {
    for (const reducer of reducers) {
      const newState = reducer(state, action);
      if (newState) return newState;
    }

    return state;
  };

export const getInitialFetchState = (defaultData = null) => ({
  loading: false,
  loaded: false,
  error: null,
  data: defaultData,
});

const defaultOptions = {
  defaultData: null,
  clearOnLoad: false,
  clearOnReload: false,
};

export const getActionType = {
  loading: (prefix) => `${prefix}_LOADING`,
  loaded: (prefix) => `${prefix}_LOADED`,
  error: (prefix) => `${prefix}_ERROR`,
  clear: (prefix) => `${prefix}_CLEAR`,
};

export const fetchReducer =
  (actionPrefix, key, options = defaultOptions) =>
  (state, action) => {
    const { defaultData } = {
      ...defaultOptions,
      ...options,
    };

    switch (action.type) {
      case getActionType.loading(actionPrefix):
        return produce(state, (draft) => {
          draft[key].loading = true;
          draft[key].loaded = false;
          draft[key].error = null;
        });
      case getActionType.loaded(actionPrefix):
        return produce(state, (draft) => {
          draft[key].loading = false;
          draft[key].loaded = true;
          draft[key].error = null;
          draft[key].data = action.payload.data;
        });
      case getActionType.error(actionPrefix):
        return produce(state, (draft) => {
          draft[key].loading = false;
          draft[key].loaded = true;
          draft[key].error = action.payload.error;
          draft[key].data = defaultData;
        });
      case getActionType.clear(actionPrefix):
        return produce(state, (draft) => {
          draft[key].loading = false;
          draft[key].loaded = false;
          draft[key].error = null;
          draft[key].data = defaultData;
        });
    }
  };

export const monitorReducer = (start, stop) => {
  return (state = {}, action) => {
    let { payload } = action;
    if (!Array.isArray(payload)) payload = [payload];
    switch (action.type) {
      case start: {
        const newState = { ...state };
        payload.forEach((x) => (newState[x] = (newState[x] || 0) + 1));
        return newState;
      }
      case stop: {
        const newState = { ...state };
        payload.forEach((x) => {
          if (!newState[x]) return;
          if (newState[x] > 1) {
            newState[x]--;
          } else {
            delete newState[x];
          }
        });
        return newState;
      }
      default:
        return state;
    }
  };
};
