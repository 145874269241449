import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

import { getAccountUser } from "selectors/loginSelectors";

import { closePromoModal } from "actions/riskFreeActions";

import { getRiskFree, getRiskFreeModal } from "../../selectors/promoSelectors";

const iconTypes = {
  alert: "alert",
  deposit: "deposit",
  shield: "shield",
  switcher: "switcher",
};
const icons = {
  [iconTypes.alert]: "/img/promo/alert.svg",
  [iconTypes.deposit]: "/img/promo/deposit.svg",
  [iconTypes.shield]: "/img/promo/shield.svg",
  [iconTypes.switcher]: "/img/promo/switcher.svg",
};
const classNames = {
  modal: "promo-modal",
  modalNoRiskBetClaimed: "promo-modal--no-risk-bet-claimed",
  icon: "promo-modal__icon",
  message: "promo-modal__message",
  tilesContainer: "promo-modal__tiles-container",
  tile: "promo-modal__tile",
  tileCounter: "promo-modal__tile-counter",
  tileIcon: "promo-modal__tile-icon",
  tileMessage: "promo-modal__tile-message",
  depositButton: "promo-modal__deposit-button",
};

const PromoModalMessage = ({ title, modalTitle, iconType, message, closeModal }) => {
  const { t } = useTranslation();
  const icon = icons[iconType] ? icons[iconType] : null;

  return (
    <Modal
      centered
      isOpen={true}
      toggle={closeModal}
      autoFocus={false}
      className={classNames.modal}
    >
      <ModalHeader toggle={closeModal}>{t(title)}</ModalHeader>
      <ModalBody>
        <div className={classNames.icon}>
          <img src={icon} alt={t(modalTitle)} />
        </div>
        <div className={classNames.message}>
          {modalTitle ? <h2>{t(modalTitle)}</h2> : null}
          {message ? <p>{t(message)}</p> : null}
        </div>
      </ModalBody>
    </Modal>
  );
};

const PromoModal = () => {
  const promoModal = useSelector(getRiskFreeModal);
  const user = useSelector(getAccountUser);
  const dispatch = useDispatch();
  const closeModal = useCallback(() => {
    dispatch(closePromoModal());
  }, [dispatch]);

  useEffect(() => {
    try {
      if (
        !!promoModal &&
        !!user &&
        (user.provider === "scatter" || !!user.licenseAccepted) &&
        !!localStorage.getItem("no-promo-modal")
      ) {
        localStorage.removeItem("no-promo-modal");
        closeModal();
      }
    } catch (e) {}
  }, [promoModal, user]);

  if (!promoModal || !user || (user.provider !== "scatter" && !user.licenseAccepted))
    return null;

  return <PromoModalMessage {...promoModal} closeModal={closeModal} />;
};

export default PromoModal;
