import { Tokens } from "@sbetdev2/sbet-utils/constants";
import BTC from "images/tokens/BTC.svg";
import EOS from "images/tokens/EOS.svg";
import ETH from "images/tokens/ETH.svg";
import LTC from "images/tokens/LTC.svg";
import SBET from "images/tokens/SBET.svg";
import SOL from "images/tokens/SOL.svg";
import TRX from "images/tokens/TRX.svg";
import USDT from "images/tokens/USDT.svg";

export const tokenIcons = {
  EOS,
  SBET,
  BTC,
  LTC,
  ETH,
  USDT,
  SOL,
  TRX,
  [Tokens.SSOL]: SOL,
  mBTC: BTC,
  [Tokens.PBTC]: BTC,
  [Tokens.SBTC]: BTC,
  [Tokens.PLTC]: LTC,
  [Tokens.SLTC]: LTC,
  [Tokens.PETH]: ETH,
  [Tokens.SETH]: ETH,
  [Tokens.SEOS]: EOS,
  [Tokens.SUSD]: USDT,
  [Tokens.TRX_USDT]: USDT,
  tethertether: USDT,
  ["eosio.token"]: USDT,
};
