import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useSelector } from "react-redux";

import { bonusStatus } from "containers/popups/BonusModal/bonus.utils";

import { getRiskFree } from "selectors/promoSelectors";

import HashLink from "components/common/HashLink";

import { localStorageUtils } from "utils/browserUtils";

import bonusImg from "../../images/bonus.svg";

const RiskFreeBetBonusStrip = () => {
  const [hidden, setHidden] = useState(false);
  const promo = useSelector(getRiskFree);
  const isPromoActive = promo && promo.status === bonusStatus.active;
  const isBonusInfoHidden = localStorageUtils.getItem("hideBonusInfo");
  const hideBonusInfo = () => {
    localStorageUtils.setItem("hideBonusInfo", true);
    setHidden(true);
  };

  if (!isPromoActive || isBonusInfoHidden || hidden) return null;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "#323F5B",
        height: 32,
        textAlign: "center",
        overflow: "hidden",
        whiteSpace: "nowrap",
        lineHeight: "32px",
        width: "100%",
        fontSize: "14px",
        color: "#fff",
      }}
    >
      <img style={{ width: 14, height: 14, marginRight: 6 }} src={bonusImg} />
      <div
        style={{
          alignItems: "center",
        }}
        className={"d-flex"}
      >
        <span className={"d-none d-sm-none d-md-inline-block d-lg-inline-block"}>
          Risk-Free Bet has been credited!
        </span>
        <HashLink
          style={{ color: "#fff", textDecoration: "underline" }}
          to={"bonus-howto"}
        >
          <span
            className={"d-none d-sm-none d-md-inline-block d-lg-inline-block"}
            style={{ color: "#fff", textDecoration: "underline" }}
          >
            How to use?
          </span>
          <span
            className={"d-inline-block d-sm-inline-block d-md-none d-lg-none"}
            style={{ color: "#fff", textDecoration: "underline" }}
          >
            How to use bonus?
          </span>
        </HashLink>
      </div>

      <FontAwesomeIcon
        icon={faTimes}
        style={{
          position: "absolute",
          right: "10px",
          cursor: "pointer",
        }}
        onClick={(e) => {
          e.stopPropagation();
          hideBonusInfo();
        }}
      />
    </div>
  );
};

export default RiskFreeBetBonusStrip;
