export const getPage = (state) => state.page;
export const getPageData = (state) => getPage(state).payload.data;
export const getPageType = (state) => getPage(state).type;
export const getPageSlug = (state) => getPageData(state)?.slug;
export const getPageSeoContent = (state) => getPageData(state)?.seoContent;
export const getPageParams = (state) => getPage(state).params;
export const getPageIds = (state) => {
  const page = getPageData(state);
  return page ? page.relatedIds || {} : {};
};
export const getPageId = (state) => {
  const page = getPageData(state);
  return page ? page.primaryId : null;
};
