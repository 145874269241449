const actionResolvers = {};

export const cancellableAction = async (id, fn, onCancel) => {
  const promiseState = { aborted: false };
  const promise = fn(promiseState);

  return Promise.race([
    promise,
    new Promise((resolve) => {
      if (!actionResolvers[id]) actionResolvers[id] = [];
      actionResolvers[id].push(() => {
        promiseState.aborted = true;
        resolve();
        if (typeof onCancel === "function") {
          promise.then(onCancel);
        }
      });
    }),
  ]);
};

export const cancelActions = (...ids) => {
  ids.forEach((id) => {
    if (actionResolvers[id]) {
      actionResolvers[id].forEach((resolve) => {
        resolve();
      });
      delete actionResolvers[id];
    }
  });
};
