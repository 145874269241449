import {
  USER_STATS_ERROR,
  USER_STATS_LOADED,
  USER_STATS_LOADING,
} from "actions/actionTypes";

const initialState = {
  loading: false,
  error: null,
  data: null,
};

const userStatsReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_STATS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case USER_STATS_LOADED:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload,
      };
    case USER_STATS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        data: null,
      };
    default:
      return state;
  }
};

export default userStatsReducer;
