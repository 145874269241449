import { getInitialCurrency } from "selectors/uiSelectors";

import {
  CURRENCY_CHANGED,
  CURRENCY_CHANGE_ERROR,
  CURRENCY_CHANGING,
  LOGIN_BALANCE,
  LOGIN_COMPLETE,
  LOGIN_ERROR,
  LOGIN_HIDE_POPUP,
  LOGIN_INIT,
  LOGIN_PROGRESS,
  LOGIN_RESET,
  LOGIN_SET_EOS_ACCOUNT,
  LOGIN_SET_SIGNING_IN,
  LOGIN_SHOW_POPUP,
  LOGIN_UPDATE_USER,
  LOGOUT_COMPLETE,
  SIGNUP_HIDE_POPUP,
  SIGNUP_SHOW_POPUP,
} from "actions/actionTypes";

import analytics from "utils/analyticsUtils";
import { localStorageUtils } from "utils/browserUtils";

const initialState = {
  account: null,
  balance: null,
  init: false,
  error: null,
  progress: null,
  isSignInEverFinished: false,
  signingIn: false,
  token: null,
  popupShown: false,
  signupPopupShown: false,
  currency: {
    name: getInitialCurrency(),
    isLoading: false,
    hasError: false,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_PROGRESS:
      return { ...state, progress: action.payload };
    case LOGIN_INIT:
      return {
        ...state,
        ...initialState,
        init: true,
      };
    case LOGIN_COMPLETE:
      if (action.payload.user) {
        if (action.payload.user.userId) {
          analytics.setUserId(action.payload.user.userId);
        }
        if (action.payload.user.provider) {
          analytics.login.success(action.payload.user.provider);
        }
      }
      return {
        ...state,
        init: true,
        account: action.payload.account,
        balance: action.payload.balance,
        user: action.payload.user,
        token: action.payload.token,
        isSignInEverFinished: !!action.payload.user.userId,
        progress: null,
        signingIn: false,
      };
    case LOGIN_BALANCE:
      return { ...state, balance: action.payload };
    case LOGIN_RESET:
      return {
        ...state,
        account: null,
        progress: null,
        balance: null,
        isSignInEverFinished: false,
        user: null,
        signingIn: false,
      };
    case LOGOUT_COMPLETE:
      analytics.setUserId(null);
      return {
        ...state,
        account: null,
        progress: null,
        balance: null,
        user: null,
        signingIn: false,
      };
    case LOGIN_ERROR:
      return {
        ...state,
        error: (action.payload && action.payload.error) || action.payload,
        progress: null,
        signingIn: false,
      };
    case CURRENCY_CHANGING: {
      return {
        ...state,
        currency: {
          ...state.currency,
          isLoading: true,
        },
      };
    }
    case CURRENCY_CHANGE_ERROR: {
      return {
        ...state,
        currency: {
          ...state.currency,
          isLoading: false,
          hasError: true,
        },
      };
    }
    case CURRENCY_CHANGED: {
      const { balance, currencyName } = action.payload;

      localStorageUtils.setItem("preferred_currency", currencyName);

      return {
        ...state,
        balance,
        currency: {
          ...state.currency,
          isLoading: false,
          hasError: false,
          name: currencyName,
        },
      };
    }
    case LOGIN_SHOW_POPUP: {
      return {
        ...state,
        popupShown: true,
        error: null, // temp fix
      };
    }
    case LOGIN_HIDE_POPUP: {
      return {
        ...state,
        popupShown: false,
        // error: null,
      };
    }
    case SIGNUP_SHOW_POPUP: {
      return {
        ...state,
        signupPopupShown: true,
        // error: null,
      };
    }
    case SIGNUP_HIDE_POPUP: {
      return {
        ...state,
        signupPopupShown: false,
        // error: null,
      };
    }
    case LOGIN_SET_SIGNING_IN: {
      return {
        ...state,
        signingIn: !!action.payload,
        progress: null,
      };
    }
    case LOGIN_UPDATE_USER: {
      return {
        ...state,
        user: action.payload.user,
      };
    }
    case LOGIN_SET_EOS_ACCOUNT: {
      return {
        ...state,
        user: {
          ...state.user,
          eosAccount: action.payload.eosAccount,
        },
      };
    }

    default:
      return state;
  }
};
