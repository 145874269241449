import produce from "immer";
import { combineReducers } from "redux";

import {
  LOGOUT_COMPLETE,
  NOTIFICATIONS_ALL_ERROR,
  NOTIFICATIONS_ALL_LOADED,
  NOTIFICATIONS_ALL_LOADING,
  NOTIFICATIONS_READ,
  NOTIFICATIONS_READ_ALL_LOADED,
  NOTIFICATIONS_RECENT_CLEAR,
  NOTIFICATIONS_RECENT_ERROR,
  NOTIFICATIONS_RECENT_HIDE,
  NOTIFICATIONS_RECENT_LOADED,
  NOTIFICATIONS_RECENT_LOADING,
  NOTIFICATIONS_UNREAD_ERROR,
  NOTIFICATIONS_UNREAD_LOADED,
  NOTIFICATIONS_UNREAD_LOADING,
} from "actions/actionTypes";

const counterInitialState = {
  notifications: 0,
  pendingBets: 0,
};

const counter = (state = counterInitialState, action) => {
  switch (action.type) {
    case NOTIFICATIONS_RECENT_LOADED:
      return {
        notifications: action.payload?.unreadNotifications || 0,
        pendingBets: action.payload?.pendingBets || 0,
      };
    case NOTIFICATIONS_RECENT_ERROR:
      return counterInitialState;
    default:
      return state;
  }
};

const recentInitialState = {
  loading: false,
  items: [],
  lastId: 0,
};

const recent = (state = recentInitialState, action) => {
  switch (action.type) {
    case NOTIFICATIONS_RECENT_LOADING:
      return { ...state, loading: true };
    case NOTIFICATIONS_RECENT_LOADED:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.items = [...state.items, ...(action.payload.items || [])].slice(0, 4);
        if (action.payload.lastId) {
          draft.lastId = action.payload.lastId;
        }
      });
    case NOTIFICATIONS_RECENT_CLEAR:
      return { ...state, loading: false, items: [], lastId: 0 };
    case NOTIFICATIONS_RECENT_ERROR:
      return { ...state, loading: false };
    case LOGOUT_COMPLETE:
      return recentInitialState;
    case NOTIFICATIONS_RECENT_HIDE:
      return produce(state, (draft) => {
        draft.items = state.items.filter((x) => x._id !== action.payload.id);
      });
    default:
      return state;
  }
};

const unreadInitialState = {
  loading: false,
  items: null,
  error: null,
  loaded: false,
};

const unread = (state = unreadInitialState, action) => {
  switch (action.type) {
    case NOTIFICATIONS_UNREAD_LOADING:
      return { ...state, loading: true, error: null };
    case NOTIFICATIONS_UNREAD_LOADED:
      return {
        ...state,
        items: action.payload.items,
        loading: false,
        error: null,
        loaded: true,
      };
    case NOTIFICATIONS_UNREAD_ERROR:
      return { ...state, items: null, error: action.payload.error };
    case NOTIFICATIONS_READ:
      return produce(state, (draft) => {
        draft.items = state.items.filter((x) => x._id !== action.payload.id);
      });
    case NOTIFICATIONS_READ_ALL_LOADED:
      return { ...state, items: null, loading: false, error: null };
    default:
      return state;
  }
};

const allInitialState = {
  loading: false,
  items: null,
  error: null,
  loaded: false,
};

const all = (state = allInitialState, action) => {
  switch (action.type) {
    case NOTIFICATIONS_ALL_LOADING:
      return { ...state, loading: true, error: null };
    case NOTIFICATIONS_ALL_LOADED:
      return {
        ...state,
        items: action.payload.items,
        loading: false,
        error: null,
        loaded: true,
      };
    case NOTIFICATIONS_ALL_ERROR:
      return { ...state, items: null, error: action.payload.error };
    default:
      return state;
  }
};

export default combineReducers({
  counter,
  recent,
  unread,
  all,
});
