import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useClickOutside from "hooks/useClickOutside";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";

import { getAccountUser, getLoginToken } from "selectors/loginSelectors";
import { getNotificationsRecentItems } from "selectors/notificationsSelectors";

import { loadRecentNotifications } from "actions/notificationsActions";

import { notificationIcons, notificationTypes } from "constants/notifications";

import Button from "components/common/Button";
import FlagIcon from "components/common/FlagIcon";
import NotificationOnsite from "components/common/NotificationOnsite";

import { localStorageUtils } from "utils/browserUtils";
import whitelabel from "utils/whitelabelUtils.ts";

const LanguageNotification = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const browserLanguage =
    (navigator.languages && navigator.languages[0]) ||
    navigator.language ||
    navigator.userLanguage;
  const languages = whitelabel.languages();
  const matchingLanguage = languages.find((x) => x.code === browserLanguage);
  const currentLanguage = i18n.language;
  const [shown, setShown] = useState(
    !localStorageUtils.getItem("languageChoosen") &&
      !!matchingLanguage &&
      currentLanguage !== matchingLanguage.code
  );
  const hideLanguageNotification = () => {
    localStorageUtils.setItem("languageChoosen", "1");
    setShown(false);
  };
  const location = useLocation();
  const history = useHistory();
  const itemRef = useRef(null);
  useClickOutside({
    ref: itemRef,
    onClick: hideLanguageNotification,
  });
  const setLang = async () => {
    const newLang = matchingLanguage.code;
    let newLocation =
      newLang === "en"
        ? location.pathname.replace(`/${i18n.language}`, "")
        : i18n.language === "en"
        ? `/${newLang}${location.pathname}`
        : location.pathname.replace(i18n.language, newLang);
    if (location.hash) newLocation += `${location.hash}`;
    history.push(newLocation);
    await i18n.changeLanguage(newLang);
    hideLanguageNotification();
  };

  if (!shown || !matchingLanguage || currentLanguage === matchingLanguage.code)
    return null;

  return (
    <div className="notification-onsite open" ref={itemRef}>
      <div className="notification-onsite__close">
        <FontAwesomeIcon
          icon={faTimes}
          onClick={(e) => {
            e.stopPropagation();
            hideLanguageNotification();
          }}
        />
      </div>
      <div className="notification-onsite__content">
        <div className="notification-onsite__icon">
          <FontAwesomeIcon
            icon={notificationIcons[notificationTypes.info].icon}
            style={{
              color: notificationIcons[notificationTypes.info].color,
              marginTop: 6,
            }}
          />
        </div>
        <div className="notification-onsite__message">
          <div
            className="notification-onsite__message-title"
            style={{ display: "flex", alignItems: "center", fontSize: 15 }}
          >
            <span
              style={{
                whiteSpace: "normal",
                lineHeight: "18px",
                marginBottom: 4,
                marginRight: 8,
                alignItems: "center",
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              {t("Change the site language into")}
            </span>
            <div className={"notification-language"}>
              <FlagIcon code={matchingLanguage.flag} />
              <span>{matchingLanguage.name}</span>
            </div>
          </div>
          <div
            className="notification-onsite__message-text"
            style={{ display: "flex", alignItems: "center", marginTop: 8 }}
          >
            <Button
              primary
              className={"btn-submit"}
              style={{ height: 36, fontSize: 15 }}
              onClick={setLang}
            >
              Apply
            </Button>
            <a
              href={"#"}
              style={{ fontSize: 15, marginLeft: 16 }}
              onClick={(e) => {
                e.preventDefault();
                hideLanguageNotification();
              }}
            >
              Skip
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const NotificationsOnsite = () => {
  const dispatch = useDispatch();
  const loginToken = useSelector(getLoginToken);
  const user = useSelector(getAccountUser);
  const items = useSelector(getNotificationsRecentItems);

  useEffect(() => {
    if (loginToken) {
      dispatch(loadRecentNotifications());
    }
  }, [loginToken]);

  /* useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 5 * 1000);
  }, []);
  button onClick={() => { setCount(count + 1); }}>Show</button>
  */

  // if (!show) return null;

  return (
    <div className={"notifications-onsite"}>
      <LanguageNotification />
      {!!user?.onsiteEnabled || !user
        ? items.map((x) => <NotificationOnsite key={`onsite-${x._id}`} item={x} />)
        : null}
    </div>
  );
};

export default NotificationsOnsite;
