import Loadable from "react-loadable";

import Loading from "components/common/Loaders/Loading.tsx";

const LazyLandingCasinoGamesPage = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "landing-casino-games-page"*/ "./LandingCasinoGamesPage.tsx"
    ),
  loading: Loading,
  modules: ["landing-casino-games-page"],
});

export default LazyLandingCasinoGamesPage;
