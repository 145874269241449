import Loadable from "react-loadable";

import Loading from "components/common/Loaders/Loading.tsx";

import PromoGrid from "./Grid";

const LazyPromoCards = Loadable({
  loader: () => import(/* webpackChunkName: "promo-cards"*/ "./PromoCards"),
  loading: Loading,
  modules: ["promo-cards"],
});

export default PromoGrid;
export { LazyPromoCards };
