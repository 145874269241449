import { createSelector } from "reselect";

export const getNotifications = (state) => state.notifications;
export const getNotificationsCounter = createSelector(
  getNotifications,
  (notifications) => notifications?.counter
);
export const getNotificationsRecent = createSelector(
  getNotifications,
  (notifications) => notifications?.recent
);
export const getNotificationsRecentItems = createSelector(
  getNotificationsRecent,
  (recent) => recent?.items
);
export const getNotificationsRecentLastId = createSelector(
  getNotificationsRecent,
  (recent) => recent?.lastId
);
export const getNotificationsRecentLoading = createSelector(
  getNotificationsRecent,
  (recent) => !!recent?.loading
);
export const getUnreadNotificationsCount = createSelector(
  getNotificationsRecent,
  (recent) => recent?.notifications
);
export const getUnreadNotifications = createSelector(
  getNotifications,
  (notifications) => notifications?.unread
);
export const getUnreadNotificationsLoading = createSelector(
  getUnreadNotifications,
  (unread) => !!unread?.loading
);
export const getAllNotifications = createSelector(
  getNotifications,
  (notifications) => notifications?.all
);
export const getAllNotificationsLoading = createSelector(
  getAllNotifications,
  (all) => !!all?.loading
);
