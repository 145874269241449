import { apiType, getApi } from "./apiFactory";

export const addAuth = (token, config) => {
  if (!token) throw new Error("Not authorized");
  if (!config) config = {};
  if (!config.headers) config.headers = {};
  config.headers["Authorization"] = "Bearer " + token;
  return config;
};

export const getAuthToken = () => {
  try {
    if (typeof window !== "undefined") {
      const authData = localStorage.getItem("auth");
      const auth = JSON.parse(authData);
      return auth.token;
    }
  } catch (e) {
    return null;
  }
  return null;
};

export const getError = (e) => {
  if (typeof e === "string") {
    try {
      const json = JSON.parse(e);
      // eslint-disable-next-line no-console
      console.error(json);
      if (json.error) {
        const details = json.error.details;
        if (details && details.length && details[0].message) return details[0].message;
      }
      return json.message || e;
    } catch (e2) {
      return e;
    }
  }
  const data = e.response && e.response.data;
  if (typeof data === "string") return data || e.message;
  if (typeof data?.error === "string") return data.error || e.message;

  return e.message;
};

const api = getApi(apiType.api);

export default api;
