import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "reactstrap";

import { getLoginProgress } from "selectors/loginSelectors";

import { showLoginPopup } from "actions/loginActions.ts";

const SignInButton = ({ className }) => {
  const logininProgress = useSelector(getLoginProgress);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <Button
      size="sm"
      className={`signin-btn ${className}`}
      onClick={() => dispatch(showLoginPopup())}
    >
      <span>{logininProgress ? t("Signing in...") : t("Sign In")}</span>
    </Button>
  );
};

export default SignInButton;
