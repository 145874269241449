import {
  USER_EMAIL_ERROR,
  USER_EMAIL_SAVED,
  USER_EMAIL_SAVING,
  USER_EMAIL_TOGGLE,
  USER_RESET_NOTIFICATIONS_SETTINGS,
} from "actions/actionTypes";

const initialState = {
  expanded: false,
  error: null,
  loading: false,
  saved: false,
};

const emailReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_EMAIL_SAVING:
      return { ...state, loading: true, error: null, saved: false };
    case USER_EMAIL_SAVED:
      return { ...state, loading: false, saved: true };
    case USER_EMAIL_ERROR:
      return { ...state, loading: false, error: action.payload?.error };
    case USER_EMAIL_TOGGLE:
      return { ...state, expanded: !!action.payload?.expanded };
    case USER_RESET_NOTIFICATIONS_SETTINGS:
      return initialState;
    default:
      return state;
  }
};

export default emailReducer;
