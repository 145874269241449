import { createSelector } from "reselect";

export const getUnsubscribe = (state) => state.unsubscribe;
export const getUnsubscribeCode = createSelector(
  getUnsubscribe,
  (unsubscribe) => unsubscribe.code
);
export const getUnsubscribeEmail = createSelector(
  getUnsubscribe,
  (unsubscribe) => unsubscribe.email
);
