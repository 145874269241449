import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getAccountUser } from "selectors/loginSelectors";
import { getUserStats } from "selectors/userStatsSelectors";

import { ZECHAT_LOADED } from "actions/actionTypes.js";

import { isBrowser } from "utils/browserUtils";
import {
  getTags,
  setTags,
  zeIdentify,
  zeIsLoaded,
  zeUpdateSettings,
} from "utils/zeChatUtils.ts";

import { useAdvancedInterval } from "./useInterval.ts";

export const useSetupZendesk = () => {
  const dispatch = useDispatch();
  const userAccount = useSelector(getAccountUser);
  const userStats = useSelector(getUserStats);

  const [zeChatLoaded, setZeChatLoaded] = useState(isBrowser() && !!window.zE);
  const [zeChatTick, setZeChatTick] = useState(0);

  const openChat = useCallback(() => {
    try {
      if (!!window.zE && zeChatLoaded) window.zE("webWidget", "open");
    } catch (e) {}
  }, [zeChatLoaded]);

  const updateChat = useCallback(() => {
    if (zeChatLoaded) return;
    if (zeIsLoaded()) {
      setZeChatLoaded(true);
      dispatch({
        type: ZECHAT_LOADED,
      });
    } else {
      setZeChatTick((t) => t + 1);
    }
  }, [zeChatLoaded]);

  useAdvancedInterval({ callback: updateChat, delay: 100 });

  useEffect(() => {
    if (!zeChatLoaded) return;
    zeUpdateSettings();

    let tags = getTags();

    try {
      tags?.length && window.zE("webWidget", "chat:removeTags", tags);
    } catch (e) {}

    tags = [];
    if (userAccount && !!userStats?.data) {
      zeIdentify(userStats.data?.title);

      if (userAccount.userId) tags.push(`ID:${userAccount.userId}`);
      if (userAccount.provider) tags.push(`loginMethod:${userAccount.provider}`);
      if (userAccount.eosAccount) tags.push(`eosAccount:${userAccount.eosAccount}`);

      if (userAccount.eosAccount) {
        const { deposits, bonuses } = userStats?.data ?? {};
        Object.entries(deposits?.addresses ?? {}).forEach(([key, value]) => {
          tags.push(`${key}:${value}`);
        });

        if (typeof deposits?.totalCount !== "undefined")
          tags.push(`deposits:${deposits?.totalCount}`);

        tags.push(`bonusUnderReview:${bonuses?.completedCount > 0 ? "Yes" : "No"}`);
      }

      tags?.length && setTags(tags);
    }
  }, [zeChatLoaded, userStats && userStats.data, userStats && userStats.error]);

  return {
    zeChatLoaded,
    openChat,
  };
};
