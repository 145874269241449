import { createSelector } from "reselect";

export const getEosAccount = () => {
  return null;
  // const user = state.login && state.login.user;
  // return user && user.eosAccount;
};

export const getUser = (state) => state.user;
/** Settings */
export const getUserSettings = createSelector(getUser, (user) => user.settings);
/** TFA Settings */
export const getUserTfaSettings = createSelector(getUser, (user) => user.tfaSettings);
export const getUserTfaEnabled = createSelector(
  getUserTfaSettings,
  (tfaSettings) => !!tfaSettings?.enabled
);
export const getUserTfaActivate = createSelector(
  getUserTfaSettings,
  (tfaSettings) => tfaSettings?.activate
);
export const getUserTfaDeactivate = createSelector(
  getUserTfaSettings,
  (tfaSettings) => tfaSettings?.deactivate
);
export const getUserTfaShowRecovery = createSelector(
  getUserTfaSettings,
  (tfaSettings) => tfaSettings?.showRecovery
);

/** TFA Confirm */
export const getUserTfaConfirm = createSelector(getUser, (user) => user?.tfaConfirm);

/** Change password */
export const getUserChangePassword = createSelector(
  getUser,
  (user) => user.changePassword
);

/** Exclude */
export const getUserExclude = createSelector(getUser, (user) => user.exclude);

/** Reactivate */
export const getUserReactivate = createSelector(getUser, (user) => user.reactivate);

export const getUserReactivateShown = createSelector(
  getUser,
  (reactivate) => !!reactivate?.shown
);

/** Email */
export const getUserEmail = createSelector(getUser, (user) => user.email);

/** Subscribe */
export const getUserSubscribe = createSelector(getUser, (user) => user.subscribe);

/** Onsite */
export const getUserOnsite = createSelector(getUser, (user) => user.onsite);

export const getUserResendConfirmation = createSelector(
  getUser,
  (user) => user.resendConfirmation
);
