import {
  SET_EVENT_GROUP_OPEN_STATE,
  SET_FIAT_CURRENCY,
  SET_HIDE_ZERO,
  SET_ODDS_FORMAT,
  SET_SHOW_BONUS,
  SIDE_MENU_TOGGLE,
} from "actions/actionTypes";

import { localStorageUtils } from "utils/browserUtils";

export const toggleSideMenu = () => ({
  type: SIDE_MENU_TOGGLE,
});

export const setFiatView = (visible) => {
  try {
    if (visible) {
      localStorageUtils.setItem("fiat_view", "1");
    } else {
      localStorageUtils.removeItem("fiat_view");
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}

  return {
    type: "SET_FIAT_VIEW",
    payload: { visible },
  };
};

export const setOddsFormat = (format) => async (dispatch) => {
  localStorageUtils.setItem("odds_format", format);
  dispatch({
    type: SET_ODDS_FORMAT,
    payload: format,
  });
};

export const setFiatCurrency = (currency) => async (dispatch) => {
  if (!currency) {
    localStorageUtils.removeItem("fiat_currency");
  } else {
    localStorageUtils.setItem("fiat_currency", currency);
  }
  if (!currency) {
    dispatch(setFiatView(false));
  } else {
    dispatch(setFiatView(true));
  }
  dispatch({
    type: SET_FIAT_CURRENCY,
    payload: currency,
  });
};

export const setHideZero = (hideZero) => async (dispatch) => {
  if (hideZero) {
    localStorageUtils.setItem("hide_zero", "1");
  } else {
    localStorageUtils.removeItem("hide_zero");
  }
  dispatch({
    type: SET_HIDE_ZERO,
    payload: hideZero,
  });
};

export const setShowBonus = (showBonus) => async (dispatch) => {
  if (showBonus) {
    localStorageUtils.setItem("show_bonus", "1");
  } else {
    localStorageUtils.removeItem("show_bonus");
  }
  dispatch({
    type: SET_SHOW_BONUS,
    payload: showBonus,
  });
};

export const setEventGroupsOpenState = (id, open) => ({
  type: SET_EVENT_GROUP_OPEN_STATE,
  payload: { id, open },
});

export const openZeChat = () => (dispatch, getState) => {
  try {
    const { loaded } = getState().zeChat;
    if (!!window.zE && loaded) window.zE("webWidget", "open");
  } catch (e) {}
};
