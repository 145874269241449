import {
  USER_RESET_NOTIFICATIONS_SETTINGS,
  USER_SUBSCRIBE_ERROR,
  USER_SUBSCRIBE_SAVED,
  USER_SUBSCRIBE_SAVING,
} from "actions/actionTypes";

const initialState = {
  error: null,
  loading: false,
  saved: false,
};

const subscribeReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_SUBSCRIBE_SAVING:
      return { ...state, loading: true, error: null, saved: false };
    case USER_SUBSCRIBE_SAVED:
      return { ...state, loading: false, saved: true };
    case USER_SUBSCRIBE_ERROR:
      return { ...state, loading: false, error: action.payload?.error };
    case USER_RESET_NOTIFICATIONS_SETTINGS:
      return initialState;
    default:
      return state;
  }
};

export default subscribeReducer;
