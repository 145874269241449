import {
  UNSUBSCRIBE_CLOSE,
  UNSUBSCRIBE_ERROR,
  UNSUBSCRIBE_INIT,
  UNSUBSCRIBE_LOADING,
  UNSUBSCRIBE_SUCCESS,
} from "actions/actionTypes";

const initialState = {
  email: null,
  code: null,
  loading: false,
  error: null,
  success: false,
};

const unsubscribeReducer = (state = initialState, action) => {
  switch (action.type) {
    case UNSUBSCRIBE_INIT:
      return {
        ...state,
        email: action.payload?.email,
        code: action.payload?.code,
        loading: false,
        error: null,
        success: false,
      };
    case UNSUBSCRIBE_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        success: false,
      };
    case UNSUBSCRIBE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload?.error,
        success: false,
      };
    case UNSUBSCRIBE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        success: true,
      };
    case UNSUBSCRIBE_CLOSE:
      return initialState;
    default:
      return state;
  }
};

export default unsubscribeReducer;
