import { faFacebookF, faGoogle, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
import { Button, Input, Label, Modal, ModalBody, ModalHeader } from "reactstrap";

import { getAccountUser, getLoginToken } from "selectors/loginSelectors";

import { logout, setUserMetaInfo, userAcceptLicense } from "actions/loginActions.ts";

import styles from "./LicenseAgreement.module.scss";

const faEos = {
  prefix: "fa",
  iconName: "EOSIcon",
  icon: [
    28,
    42,
    [],
    null,
    "M23.5532 13.5965L14.0006 0.136719L4.36835 13.6768L0.441406 33.1614L14.0001 41.5887L27.5598 33.1608L23.5532 13.5965ZM11.6945 36.8106L3.67269 31.7991L6.12565 19.541L11.6945 36.8106ZM14.0001 34.4173L7.50499 14.3648L14.0001 5.22322L20.4953 14.3648L14.0001 34.4173ZM24.2492 31.7991L16.2274 36.8106L21.7963 19.541L24.2492 31.7991Z",
  ],
};

const LicenseAgreement = ({ location: { pathname } }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(getAccountUser) || {};
  const authToken = useSelector(getLoginToken);

  const { push } = useHistory();

  useEffect(() => {
    if (user && authToken && (!user.licenseAccepted || user.provider === "scatter")) {
      dispatch(setUserMetaInfo());
    }
  }, [user, authToken]);

  const colors = {
    google: "#DB4437",
    twitter: "#1DA1F2",
    facebook: "#4267B2",
    scatter: "#121D4D",
  };
  const icons = {
    google: faGoogle,
    twitter: faTwitter,
    facebook: faFacebookF,
    scatter: faEos,
  };
  const terms = ["Accept license agreement"];
  const [acceptedTerms, setAcceptedTerms] = useState({});

  if (pathname === "/rules") return null;

  return user && user.provider && !user.licenseAccepted ? (
    <Modal
      className={styles["license-agreement"]}
      centered
      isOpen={true}
      toggle={() => {}}
      autoFocus={false}
    >
      <ModalHeader toggle={() => dispatch(logout())}>{t("Account Creation")}</ModalHeader>
      <ModalBody style={{ paddingTop: 0 }}>
        <div
          className={styles["license-agreement__modal-header"]}
          style={{ background: colors[user.provider] }}
        >
          <div className={styles["license-agreement__modal-header-icon"]}>
            <FontAwesomeIcon icon={icons[user.provider]} />
          </div>
        </div>
        {terms.map((term, termIndex) => (
          <Label
            className="text-left w-75 ml-4"
            key={`license-agreement-pt-${termIndex}`}
          >
            <Input
              type="checkbox"
              checked={!!acceptedTerms[termIndex]}
              onChange={() => {
                setAcceptedTerms({
                  ...acceptedTerms,
                  [termIndex]: !acceptedTerms[termIndex],
                });
              }}
            />{" "}
            <span>
              {t(term)}{" "}
              <a href={"/rules"} target="_blank">
                {t("Rules")}
              </a>{" "}
              {t("and")}{" "}
              <a href={"/PrivacyPolicyEN.pdf"} target="_blank">
                {t("Privacy Policy")}
              </a>
            </span>
          </Label>
        ))}
        <div className={styles["license-agreement__buttons"]}>
          <Button className="rounded w-50" onClick={() => dispatch(logout())}>
            {t("Decline")}
          </Button>
          <Button
            className="rounded w-50"
            disabled={
              Object.values(acceptedTerms).filter((selected) => !!selected).length !==
              terms.length
            }
            onClick={() => dispatch(userAcceptLicense(push))}
          >
            {t("Accept")}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  ) : null;
};

export default withRouter(LicenseAgreement);
