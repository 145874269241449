import React from "react";

import LanguageSelector from "containers/settings/LanguageSelector";

import FooterLink from "./FooterLink";
import { ColumnContentProps, ContentTypes, LinkType } from "./types";

const ExternalLink: React.FC<Partial<ColumnContentProps>> = (params) => (
  <FooterLink type={LinkType.external} {...params} />
);

const HashLinkContent: React.FC<Partial<ColumnContentProps>> = (params) => (
  <FooterLink type={LinkType.hash} {...params} />
);

const InnerLink: React.FC<
  Partial<ColumnContentProps> & { url?: string; title: string; iconPath: string }
> = (params) => <FooterLink type={LinkType.inner} {...params} />;

const Language = () => <LanguageSelector showLabel caret className="d-block" />;

const componentByType = (type: string) => {
  switch (type) {
    case ContentTypes.innerLink:
      return InnerLink;
    case ContentTypes.externalLink:
      return ExternalLink;
    case ContentTypes.hashLink:
      return HashLinkContent;
    case ContentTypes.lngSelect:
      return Language;
    default:
      return InnerLink;
  }
};

const ColumnContent: React.FC<ColumnContentProps> = ({
  type,
  title,
  href,
  iconPath,
  flag,
  restrict,
}) => {
  const Component = componentByType(type);
  return (
    <Component
      url={href}
      href={href}
      flag={flag}
      title={title}
      restrict={restrict}
      iconPath={iconPath ?? ""}
    />
  );
};

export default ColumnContent;
