import { createSelector } from "reselect";

import { getAccountIsVip } from "selectors/loginSelectors";
import {
  getEventsById,
  getLeaguesById,
  sortByOrderNameAndThreshold,
} from "selectors/sportsSelectors";

import { sports } from "constants/sports";

import { getPrimaryLeagueOrderThreshold } from "utils/eventUtils";

const groupTypes = {
  Upcoming: "Upcoming",
  Live: "LIVE",
};

export const getHomepage = (state) => state.homepage;
export const getHomepageMonitoring = createSelector(getHomepage, (hp) => hp.monitoring);
export const getHomepageSportId = createSelector(
  getHomepage,
  (homepage) => homepage.sport
);
export const getHomepageSport = createSelector(
  getHomepageSportId,
  getHomepage,
  (sportId, hp) => hp.sports[sportId]
);
export const getHomepagePrematchIds = createSelector(
  getHomepageSport,
  (sport) => sport.preEventIds
);
export const getHomepageLiveIds = createSelector(
  getHomepageSport,
  (sport) => sport.liveEventIds
);

export const getHomepageLoading = createSelector(getHomepageSport, (sport) =>
  sport.sports?.every((s) => s.loading)
);

export const getHomepageLoaded = createSelector(getHomepageSport, (sport) =>
  sport.sports?.every((s) => s.loaded)
);

const getHomepageEvents = (ids, isVip, eventsById) => {
  const result = ids.reduce((r, id) => {
    if (!eventsById[id]) return r;
    const event = eventsById[id];
    if (!event.bet) return r;
    if (!isVip && !event.odds) return r;
    r.push(event);
    return r;
  }, []);
  result.sort((a, b) => new Date(a.starts).getTime() - new Date(b.starts).getTime());
  return result;
};

export const getHomepagePrematchEvents = createSelector(
  getHomepagePrematchIds,
  getAccountIsVip,
  getEventsById,
  getHomepageEvents
  /*const targetType = live ? eventType.live : eventType.prematch;
    let result = Object.getOwnPropertyNames(eventsById)
      .map((x) => eventsById[x])
      .filter((x) => x.sport === sportId && x.type === targetType && x.bet);
    if (!isVip && targetType === eventType.prematch) {
      for (const event of result) {
        if (!event.odds) continue;
        event.odds = { ...event.odds };
        let bet = false;
        for (const market of Object.keys(event.odds)) {
          if (market === "bet") continue;
          const odd = event.odds[market];
          if (Array.isArray(odd)) {
            const oddPrepared = odd.filter(
              (x) => typeof x.vip === "undefined" || !x.vip
            );
            if (!oddPrepared.length) delete event.odds[market];
            if (oddPrepared.some((x) => !!x.bet)) bet = true;
            event.odds[market] = oddPrepared;
          } else if (odd.vip) {
            delete event.odds[market];
          } else if (odd.bet) {
            bet = true;
          }
        }

        if (!bet) {
          event.bet = false;
        }
      }
    }
    if (targetType === eventType.prematch) {
      result = result.filter(
        (x) =>
          !!x.bet &&
          !!x.odds &&
          (!!x.odds[1] ||
            !!x.odds[9] ||
            !!x.odds[46] ||
            !!x.odds[3] ||
            !!x.odds[10])
      );
    }
    result.sort(
      (a, b) => new Date(a.starts).getTime() - new Date(b.starts).getTime()
    );
    return result;*/
);
export const getHomepageLiveEvents = createSelector(
  getHomepageLiveIds,
  getAccountIsVip,
  getEventsById,
  getHomepageEvents
);
export const getHomepageLeagues = createSelector(
  getAccountIsVip,
  getLeaguesById,
  (isVip, state) => {
    const result = !isVip ? state.filter((x) => !x.vipOnly) : [...state];
    // sortByOrderName(result);
    return result;
  }
);

const sportsOrder = [
  sports.soccer,
  sports.basketball,
  sports.tennis,
  sports.baseball,
  sports.football,
  sports.hockey,
  sports.esports,
  sports.boxing,
  sports.volleyball,
  sports.tableTennis,
  sports.rugbyleague,
  sports.rugbyunion,
  sports.cricket,
  sports.afl,
];

export const getHomepageLiveLeagues = createSelector(getHomepageLeagues, (leagues) => {
  const result = [];
  const sortedLeagues = [...leagues];
  sortedLeagues.sort(function (a, b) {
    if (Math.abs(a.betRisk - b.betRisk) < 0.001) {
      return sportsOrder.indexOf(a.sport) - sportsOrder.indexOf(b.sport);
    }
    return parseFloat(b.betRisk) - parseFloat(a.betRisk);
  });

  while (sortedLeagues.length) {
    let league = sortedLeagues[0];
    result.push(league);
    sortedLeagues.splice(0, 1);
    while (sortedLeagues.findIndex((x) => x.sport === league.sport) !== -1) {
      const leagueIndex = sortedLeagues.findIndex((x) => x.sport === league.sport);
      result.push(sortedLeagues[leagueIndex]);
      sortedLeagues.splice(leagueIndex, 1);
    }
  }

  return result;
});

export const getHomepagePrematchLeagues = createSelector(
  getHomepageLeagues,
  (leagues) => {
    const result = [];
    const sortedLeagues = [...leagues];

    sortedLeagues.sort(function (a, b) {
      if (Math.abs(a.betRisk - b.betRisk) < 0.001) {
        return sportsOrder.indexOf(a.sport) - sportsOrder.indexOf(b.sport);
      }
      return b.betRisk - a.betRisk;
    });

    while (sortedLeagues.length) {
      let league = sortedLeagues[0];
      result.push(league);
      sortedLeagues.splice(0, 1);
      while (sortedLeagues.findIndex((x) => x.sport === league.sport) !== -1) {
        const leagueIndex = sortedLeagues.findIndex((x) => x.sport === league.sport);
        result.push(sortedLeagues[leagueIndex]);
        sortedLeagues.splice(leagueIndex, 1);
      }
    }

    return result;
  }
);

const sortLeagues = (leagues, isUpcoming) => {
  const sortedLeagues = [...leagues];

  sortedLeagues.sort((a, b) => {
    return sportsOrder.indexOf(a.sport) - sportsOrder.indexOf(b.sport);
  });

  const leaguesBySport = new Map();
  sortedLeagues.forEach((league) => {
    if (!leaguesBySport.has(league.sport)) {
      leaguesBySport.set(league.sport, []);
    }
    leaguesBySport.get(league.sport).push(league);
  });

  const result = [];
  leaguesBySport.forEach((leagues) => {
    result.push(...leagues);
  });

  if (isUpcoming) {
    const upcomingResult = [];
    leaguesBySport.forEach((leagues) => {
      leagues.sort((a, b) => a.nearestEventByStarts - b.nearestEventByStarts);
      upcomingResult.push(...leagues);
    });
    return upcomingResult;
  }

  return result;
};

const getHomepageView =
  (live = false, group) =>
  (events, leaguesData) => {
    const eventsByLeague = events.reduce((p, x) => {
      if (p[x.league]) {
        p[x.league].push(x);
      } else {
        p[x.league] = [x];
      }
      return p;
    }, {});
    const leagues = sortLeagues(
      leaguesData
        .filter((x) => !!eventsByLeague[x._id]?.length)
        .map((x) => {
          const nearestEventByStarts = eventsByLeague[x._id].reduce((p, x) => {
            if (!p || new Date(x.starts) < new Date(p.starts)) {
              return x;
            }
            return p;
          }, null).starts;

          return { ...x, nearestEventByStarts: nearestEventByStarts.getTime() };
        }),
      group === groupTypes.Upcoming
    );

    const leaguesByGroup = leagues.reduce((p, x) => {
      if (p[group]) {
        p[group].push(x);
      } else {
        p[group] = [x];
      }
      return p;
    }, {});

    const groups = Object.entries(leaguesByGroup)
      .map(([group, groupLeagues]) => {
        const leagues = groupLeagues
          .map((league) => {
            let events = eventsByLeague[league._id];

            return {
              league,
              events,
            };
          })
          .filter((c) => c.events && c.events.length);

        const count = leagues.reduce((p, x) => p + x.events.length, 0);
        return {
          name: group,
          order: leagues.length ? leagues[0].league.order : undefined,
          leagues,
          count,
        };
      })
      .filter((x) => x.count);

    sortByOrderNameAndThreshold(groups, getPrimaryLeagueOrderThreshold(live));

    return groups;
  };

export const getHomepagePrematchView = createSelector(
  getHomepagePrematchEvents,
  getHomepageLeagues,
  getHomepageView(false, groupTypes.Upcoming)
);

export const getHomepageLiveView = createSelector(
  getHomepageLiveEvents,
  getHomepageLeagues,
  getHomepageView(true, groupTypes.Live)
);
