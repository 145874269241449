import {
  RISK_FREE_CLEAR,
  RISK_FREE_LOADED,
  RISK_FREE_UNSET_MODAL,
} from "actions/actionTypes";

const initialState = {
  promoSum: null,
  loading: false,
  promoId: null,
  type: null,
  options: null,
  status: null,
  createdAt: null,
  modal: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RISK_FREE_LOADED: {
      const { promoId, type, options, status, createdAt, modal, info, currencies } =
        action.payload;
      return {
        ...state,
        promoId,
        type,
        options,
        status,
        createdAt,
        currencies,
        modal: info?.modal ?? state.modal ?? modal,
        loading: false,
      };
    }
    case RISK_FREE_CLEAR: {
      return { ...initialState, modal: state.modal };
    }

    case RISK_FREE_UNSET_MODAL: {
      return { ...state, modal: null };
    }
    default:
      return state;
  }
};
