export const AB_COOKIE_NAME = "_ab_test";
const DIVIDER = "-";

const parseCookie = (str) =>
  str
    .split(";")
    .filter((o) => o)
    .map((v) => v.split("="))
    .reduce((acc, v) => {
      acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim());
      return acc;
    }, {});

const mapExperiment = (experiment) => {
  const [eid, vid] = experiment.split(DIVIDER);
  return {
    eid,
    vid,
  };
};

export const parseExperiments = (cookie) => {
  let variations = [];
  if (cookie) {
    const abCookie = parseCookie(cookie)[AB_COOKIE_NAME];
    if (abCookie) {
      variations = JSON.parse(abCookie).map(mapExperiment);
    }
  }

  return variations;
};
