import cx from "classnames";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Nav } from "reactstrap";

import { getEventsCountByLive, getLiveStreamFilter } from "selectors/sportsSelectors";

import { setSportLiveStreamFilter } from "actions/sportActions";

import Checkbox from "components/common/Checkbox";

const SportLiveStreamFilter = ({ className }) => {
  const [open, setOpen] = useState(false);
  const liveStreamFilter = useSelector(getLiveStreamFilter);
  const liveStreamFilterValue = liveStreamFilter ? "Yes" : "All";
  const eventsCount = useSelector(getEventsCountByLive);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const filterMobile = (filter) => (
    <DropdownItem
      onClick={() => {
        dispatch(
          setSportLiveStreamFilter(
            filter === liveStreamFilterValue || filter === "All" ? false : true
          )
        );
      }}
      className={cx({
        active:
          (!liveStreamFilter && filter === "All") ||
          (liveStreamFilter && filter === "Yes"),
      })}
    >
      <Checkbox
        onChange={() => {
          dispatch(
            setSportLiveStreamFilter(
              filter === liveStreamFilterValue || filter === "All" ? false : true
            )
          );
          setOpen(false);
        }}
        defaultChecked={
          (!liveStreamFilter && filter === "All") ||
          (liveStreamFilter && filter === "Yes")
        }
      />
      <span>{t(filter || "All")}</span>
      <span className={"sport-date-filter__count"}>{eventsCount[filter || "All"]}</span>
    </DropdownItem>
  );

  return (
    <div className={cx("sport-date-filter", className)}>
      <Nav pills>
        <Dropdown nav isOpen={open} toggle={() => setOpen(!open)}>
          <DropdownToggle nav caret className={liveStreamFilter ? "active" : null}>
            {t("Stream")}: {t(liveStreamFilter ? "Yes" : "All")}
          </DropdownToggle>
          <DropdownMenu className={"dropdown-menu-filter"}>
            {filterMobile("Yes")}
            {filterMobile("All")}
          </DropdownMenu>
        </Dropdown>
      </Nav>
    </div>
  );
};

SportLiveStreamFilter.propTypes = {
  className: PropTypes.string,
};

export default SportLiveStreamFilter;
