import ClientSideRenderer from "hoc/ClientSideRenderer.tsx";

import NavLink from "components/common/NavLink.tsx";

import { TokenIcon, TokenNames } from "../common/TokenIcon/index.ts";

const iconList = [
  TokenNames.BITCOIN,
  TokenNames.TETHER,
  TokenNames.ETHEREUM,
  TokenNames.LITECOIN,
  TokenNames.EOS,
  TokenNames.SBET,
  TokenNames.SOLANA,
];

export const CoinsWidget = () => {
  return (
    <div className="row mt-4 overflow-hidden">
      <div className="col-12">
        <div className="coins-widget">
          {iconList.map((iconName) => {
            const linkPath = [TokenNames.BITCOIN, TokenNames.SBET].includes(iconName)
              ? "/"
              : `/${iconName}`;

            return (
              <div key={iconName}>
                <NavLink to={linkPath}>
                  <span className="sr-only">{iconName}</span>
                  <ClientSideRenderer>
                    <TokenIcon iconName={iconName} />
                  </ClientSideRenderer>
                </NavLink>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
