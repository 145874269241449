import {
  LEADERBOARD_LOADED,
  LEADERBOARD_LOADING,
  LEADERBOARD_LOAD_ERROR,
} from "actions/actionTypes";

const initialState = {
  loading: false,
  error: null,
  data: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LEADERBOARD_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };
    case LEADERBOARD_LOADED:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload,
      };
    case LEADERBOARD_LOAD_ERROR:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return state;
  }
};
