import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons/faAngleUp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  InputGroup,
  InputGroupAddon,
  InputGroupButtonDropdown,
} from "reactstrap";

import { findBonusToken } from "containers/popups/Wallet/helpers.ts";

import { getAccountCurrencyMetadata, getEosAccount } from "selectors/loginSelectors";
import {
  getIsFiatCurrency,
  getIsHideZero,
  getSettingsFiatCurrency,
  getViewFiat,
} from "selectors/uiSelectors";
import { getWalletBalances, getWalletCasinoBalances } from "selectors/walletSelectors";

import { changeCurrency } from "actions/currencyActions";
import { setFiatView, setHideZero } from "actions/uiActions";
import { walletLoadBalances } from "actions/walletActions";

import { currencies, currenciesOrderSocial } from "constants/currencies.ts";

import Sum, { BalanceSum } from "components/common/Sum";

import { getSavedCurrency } from "utils/browserUtils";
import { currencyAmountAvailable, isMBtc } from "utils/currencyUtils";

import { ReactComponent as WalletIcon } from "img/header-icons/wallet-icon.svg";

const tokenPickerTabs = {
  main: "main",
  casino: "casino",
};

const UserBalance = ({ className }) => {
  const { pathname } = useLocation();

  const { t } = useTranslation();
  const [isDropdownOpen, setisDropdownOpen] = useState(false);
  const [tab, setTab] = useState(tokenPickerTabs.main);
  const currencyMetadata = useSelector(getAccountCurrencyMetadata);
  const eosAccount = useSelector(getEosAccount);
  const { data: mainBalances, isLoading } = useSelector(getWalletBalances);

  const dispatch = useDispatch();
  const { data: casinoBalances } = useSelector(getWalletCasinoBalances);
  const isHideZero = useSelector(getIsHideZero);
  const isFiat = useSelector(getIsFiatCurrency);
  const viewFiat = useSelector(getViewFiat);
  const fiatCurrency = useSelector(getSettingsFiatCurrency);
  const history = useHistory();

  const balances = tab === tokenPickerTabs.main ? mainBalances : casinoBalances;

  const bonusTokenAvailable = useMemo(() => {
    if (mainBalances && casinoBalances) {
      return findBonusToken(mainBalances) || findBonusToken(casinoBalances);
    }
    return false;
  }, [balances, casinoBalances, tab]);

  const balance = useMemo(() => {
    return !isLoading && balances?.[currencyMetadata?.name]
      ? balances[currencyMetadata.name] * (isMBtc(currencyMetadata.name) ? 1000 : 1)
      : "Loading...";
  }, [tab, balances, currencyMetadata]);

  useEffect(() => {
    if (!eosAccount) return;
    dispatch(walletLoadBalances(eosAccount));
  }, [eosAccount]);
  const currencyName = currencyMetadata.name;
  const hasError = currencyMetadata.hasError;
  const balanceValue = balance;
  const toggleFiat = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      dispatch(setFiatView(!viewFiat));
      if (!isFiat && !viewFiat && !fiatCurrency) {
        history.replace({ hash: "#currency" });
      }
    },
    [dispatch, isFiat, pathname, viewFiat, fiatCurrency]
  );
  const toggleHideZero = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      dispatch(setHideZero(!isHideZero));
    },
    [dispatch, isHideZero]
  );

  const changeBalanceViaPathname = (isCasino = false) => {
    const tab = isCasino ? tokenPickerTabs.casino : tokenPickerTabs.main;
    const balances = isCasino ? casinoBalances : mainBalances;
    const currencies = currenciesOrderSocial;
    const selectedCurrency = getSavedCurrency();
    setTab(tab);
    if (isCasino && Object.values(balances).every((value) => !value)) {
      history.push("#wallet/transfer");
    }
    const availableToken = currencyAmountAvailable({
      balances,
      currencies,
      selectedCurrency,
    });
    availableToken && dispatch(changeCurrency(availableToken[0]));
  };

  useEffect(() => {
    const isCasinoPage = pathname.includes("/casino");
    if (isLoading || !casinoBalances || !balances) return;
    if (isCasinoPage && tab !== tokenPickerTabs.casino) {
      changeBalanceViaPathname(isCasinoPage);
    }
    if (!isCasinoPage && tab !== tokenPickerTabs.main) {
      changeBalanceViaPathname(isCasinoPage);
    }
  }, [pathname, isLoading, casinoBalances, casinoBalances]);

  if (hasError) {
    return <div />;
  }

  return (
    <InputGroup
      className={cx(`user-balance-container`, className, "user-balance-conversion")}
      size="sm"
    >
      <InputGroupButtonDropdown
        addonType="prepend"
        isOpen={isDropdownOpen}
        toggle={() => setisDropdownOpen(!isDropdownOpen)}
      >
        <DropdownToggle>
          <BalanceSum
            value={isMBtc(currencyName) ? balanceValue / 1000 : balanceValue}
            token={currencyName}
            tokenCaption={false}
            fiatCaption={true}
          />
          <FontAwesomeIcon
            className="button-icon-end"
            icon={isDropdownOpen ? faAngleUp : faAngleDown}
          />
        </DropdownToggle>
        <DropdownMenu>
          <div
            className="btn-group btn-group-toggle currency-type-toggle"
            data-toggle="buttons"
          >
            <label
              className={`btn btn-secondary ${
                tab === tokenPickerTabs.main ? "selected" : ""
              }`}
              onClick={() => setTab(tokenPickerTabs.main)}
            >
              <input type="radio" name="options" id="real" autoComplete="off" />
              {t("Main")}
            </label>
            <label
              className={`btn btn-secondary ${
                tab === tokenPickerTabs.casino ? "selected" : ""
              }`}
              onClick={() => setTab(tokenPickerTabs.casino)}
            >
              <input type="radio" name="options" id="bonus" autoComplete="off" />
              {t("Casino")}
            </label>
          </div>
          {currenciesOrderSocial.map((currencyCode) => {
            const curr = currencies?.[currencyCode];
            const value = balances?.[curr.name] ?? 0;

            // hide empty values
            if (isHideZero && !value) {
              return null;
            }

            return (
              <DropdownItem
                onClick={() => dispatch(changeCurrency(curr.name))}
                key={curr.name}
                className={cx({ active: currencyName === curr.name })}
              >
                <Sum
                  value={value}
                  token={curr.realName || curr.name}
                  tokenCaption={true}
                  balanceView={true}
                />
              </DropdownItem>
            );
          })}
          {bonusTokenAvailable && (
            <>
              <div className="separator" />
              <div style={{ padding: "0.25rem 0.9rem" }}>
                <img src="/img/gift.svg" alt="Bonus Icon" style={{ width: "15px" }} />{" "}
                <span style={{ fontSize: 14, color: "#A4B3CC", marginLeft: 3 }}>
                  Bonus balance
                </span>
              </div>
              <DropdownItem
                onClick={() => dispatch(changeCurrency(bonusTokenAvailable))}
                className={cx({ active: currencyName === bonusTokenAvailable })}
              >
                <Sum
                  value={balances?.[bonusTokenAvailable] ?? 0}
                  token={currencies[bonusTokenAvailable]?.realName}
                  tokenCaption={true}
                  balanceView={true}
                />
              </DropdownItem>
            </>
          )}
          <div className="separator" />
          <div className="balance-picker-filters">
            <div className="balance-picker-filter" onClick={toggleFiat}>
              <label className="switch" htmlFor={"toggle-fiat-checkbox"}>
                <input
                  type="checkbox"
                  id={"toggle-fiat-checkbox"}
                  checked={!!viewFiat}
                  onChange={() => {}}
                />
                <span className="slider" />
              </label>
              <span className="balance-picker-filter-name">{t("View in $/€/₺")}</span>
            </div>
            <div className="balance-picker-filter" onClick={toggleHideZero}>
              <label className="switch" htmlFor={"hide-zeros-checkbox"}>
                <input
                  type="checkbox"
                  id="hide-zeros-checkbox"
                  checked={!!isHideZero}
                  onChange={() => {}}
                />
                <span className="slider" />
              </label>
              <span className="balance-picker-filter-name">
                {t("Hide zero balances")}
              </span>
            </div>
          </div>
        </DropdownMenu>
      </InputGroupButtonDropdown>
      <InputGroupAddon addonType="append" className="btn-separated-addon">
        <NavLink
          aria-label="Open wallet"
          to={pathname + "#wallet"}
          className="btn btn-sm btn-secondary"
        >
          <WalletIcon />
        </NavLink>
      </InputGroupAddon>
    </InputGroup>
  );
};

export default UserBalance;
