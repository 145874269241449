import { ReactElement } from "react";

export type ColContent = Array<ColumnContentProps & { text?: string }>;

export interface NavigationProps {
  columns: {
    title: string;
    content: ColContent;
  }[];
}

export type NavColProps = {
  title: string;
  children: React.ReactNode;
};

export type Mark = {
  icon: ReactElement;
  alt: string;
  height: number;
  width: number;
  href?: string;
};

export type FooterLinkProps = {
  type: string;
  url?: string;
  flag?: string;
  title?: string;
  iconPath?: string;
  internal?: boolean;
  restrict?: Partial<RestrictType>;
};

export type RestrictType = {
  browserOnly: boolean;
  tabIndex: number;
  liveChat?: boolean;
  registeredOnly?: boolean;
};

export enum LinkType {
  inner = "inner",
  external = "external",
  hash = "hash",
}

export type ColumnContentProps = {
  type: string;
  title: string;
  href?: string;
  iconPath?: string;
  icon?: string;
  flag?: string;
  restrict?: Partial<RestrictType>;
};

export enum ContentTypes {
  innerLink = "innerLink",
  externalLink = "externalLink",
  hashLink = "hashLink",
  lngSelect = "lngSelect",
}
