const eosConfig = {
  signTransactionBy: {
    name: "aaaaaaaaaabf",
    authority: "active",
  },
};

const networkConfigBase = {
  blockchain: "eos",
  protocol: "https",
  port: 443,
};

export const appName = "sportbet.one";

export const networkConfig = {
  ...networkConfigBase,
  // host: "nodes.get-scatter.com",
  host: "eos.greymass.com",
  chainId: "aca376f206b8fc25a6ed44dbdc66547c36c6c33e3a119ffbeaef943642f0e906",
};

export default eosConfig;
