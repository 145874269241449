import cx from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { Alert } from "reactstrap";

const NoDataMsg = ({ className, children }) => {
  return <Alert color={cx("info", className)}>{children}</Alert>;
};

NoDataMsg.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};

export default NoDataMsg;
