import Loadable from "react-loadable";

import Loading from "components/common/Loaders/Loading.tsx";

const LazyPage404 = Loadable({
  loader: () => import(/* webpackChunkName: "404-page"*/ "./Page404.tsx"),
  loading: Loading,
  modules: ["404-page"],
});

export default LazyPage404;
