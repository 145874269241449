import classNames from "classnames";
import usePrevious from "hooks/usePrevious.ts";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";

import { getBetBets } from "selectors/betSelectors";
import { getAccountName, getAccountUser } from "selectors/loginSelectors";
import { getOddsFormat } from "selectors/uiSelectors";

import { addBet, removeBet } from "actions/betActions";
import { showLoginPopup } from "actions/loginActions.ts";

import { isBrowser } from "utils/browserUtils";
import { formatOdd } from "utils/eventUtils";

import { Bet } from "./types";

type Props = {
  bet: Bet;
  text: string;
  className: string;
  details: string;
  short: boolean;
  onClick?: () => void;
};

const BetButton: React.FC<Props> = ({
  bet,
  text,
  className,
  details,
  short,
  onClick,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const browserView = isBrowser();

  const tickRef = useRef(0);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const prevBet = usePrevious(bet);

  const oddsFormat = useSelector(getOddsFormat);
  const user = useSelector(getAccountUser);
  const accountName = useSelector(getAccountName);
  const bets = useSelector(getBetBets);

  const [, setReRenders] = useState(0);

  const activeBet = bets.find(
    (x: Bet) =>
      !!bet &&
      x.event === bet.event &&
      x.market === bet.market &&
      x.side === bet.side &&
      x.value === bet.value
  );

  const enabled = !!bet?.bet;
  const active = !!activeBet;

  const clearTickTimeout = () => {
    if (timerRef.current !== null) clearTimeout(timerRef.current);
    timerRef.current = null;
  };

  const handleTrendTick = (tick: number) => {
    tickRef.current = tick;
    setReRenders((r) => r + 1);

    if (tick !== 0) {
      clearTickTimeout();
      timerRef.current = setTimeout(() => {
        if (tickRef.current === tick) handleTrendTick(0);
        if (timerRef.current) clearTimeout(timerRef.current);
      }, 10000);
    }
  };

  const clearTickTimer = () => {
    clearTickTimeout();
    handleTrendTick(0);
  };

  useEffect(() => {
    if (!!prevBet && bet?.event !== prevBet?.event) clearTickTimer();
  }, []);

  useEffect(() => {
    if (bet && prevBet && bet?.event === prevBet?.event) {
      const odd = bet.odd;
      const oldOdd = prevBet.odd;
      const tick = odd > oldOdd ? 1 : odd < oldOdd ? -1 : 0;
      if (tick && tickRef.current !== tick) handleTrendTick(tick);
    }
  }, [bet]);

  const placeBet = (betData: Bet) => {
    if (!accountName) {
      dispatch(showLoginPopup());
      return;
    }

    if (browserView && user.provider !== "scatter" && !user.eosAccount) {
      history.push("#wallet");
      return;
    }
    dispatch(addBet(betData, history));
  };

  const betPlaceUnplace = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (typeof onClick === "function") {
      onClick();
    }
    if (active) {
      dispatch(removeBet(activeBet));
    } else {
      placeBet(bet);
    }
  };

  if (!bet) return null;

  const classes = classNames("bet-button", className, {
    details: !!details,
    active: !!active,
    short: !!short,
  });

  if (!browserView) {
    return (
      <div className={classes} style={{ minHeight: short ? 21 : 29 }}>
        {" "}
      </div>
    );
  }

  return (
    <Button size="sm" name="button" className={classes} onClick={betPlaceUnplace}>
      {text && <span className="bet-text">{text}</span>}
      <span className={text ? "bet-odd-text" : "bet-odd"}>
        {enabled ? formatOdd(bet.odd, oddsFormat) : "N/A"}
      </span>
      {enabled && tickRef.current !== 0 && (
        <span
          className={classNames({
            "tick-up": tickRef.current > 0,
            "tick-down": tickRef.current < 0,
          })}
        >
          {tickRef.current > 0 ? "▲" : "▼"}
        </span>
      )}
    </Button>
  );
};

export default BetButton;
