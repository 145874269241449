import Header from "layout/desktop/Header";
import Footer from "layout/desktop/footer/Footer.tsx";
import { FC, ReactNode } from "react";
import Loadable from "react-loadable";
import { Container } from "reactstrap";

import Only from "components/common/Only.tsx";

const SideMenu = Loadable({
  loader: () => import(/* webpackChunkName: "sidemenu"*/ "./SideMenu"),
  loading: () => null,
  modules: ["sidemenu"],
});
const SchemaBreadcrumbs = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "layout-breadcrumbs"*/ "components/common/Breadcrumbs/schemaBreadCrumbs.tsx"
    ),
  loading: () => null,
  modules: ["layout-breadcrumbs"],
});

const Layout: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <div className="main d-flex flex-column">
      <Header />
      <SchemaBreadcrumbs />
      <Container>
        <main className="min-vh-100 flex-fill">{children}</main>
      </Container>
      <Only browser mobile>
        <SideMenu />
      </Only>
      <Footer />
    </div>
  );
};

export default Layout;
