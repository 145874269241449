import { faCheckCircle } from "@fortawesome/free-solid-svg-icons/faCheckCircle";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons/faExclamationCircle";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons/faExclamationTriangle";
import { faGift } from "@fortawesome/free-solid-svg-icons/faGift";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons/faInfoCircle";
import { faNewspaper } from "@fortawesome/free-solid-svg-icons/faNewspaper";
import { faWallet } from "@fortawesome/free-solid-svg-icons/faWallet";
import { notificationType as notificationItemType } from "@sbetdev2/dbmodel/enums";

import { betStatus } from "constants/bets";

export const getBetStatus = (bet) => {
  let status = betStatus.placed;
  if (Array.isArray(bet?.legs)) {
    for (const leg of bet.legs) {
      const legStatus =
        leg.status > 1
          ? leg.status
          : leg.result && leg.result.status
          ? leg.result.status
          : leg.status;
      if (
        [betStatus.win, betStatus.winHalf].includes(legStatus) &&
        [betStatus.placed, betStatus.win, betStatus.winHalf].includes(status)
      ) {
        status = betStatus.win;
      } else if ([betStatus.lose, betStatus.loseHalf].includes(legStatus)) {
        status = betStatus.lose;
      } else if (![betStatus.lose, betStatus.loseHalf].includes(status)) {
        status = legStatus;
      }
    }
  }
  return status;
};

export const getBetStatusText = (status) => {
  switch (status) {
    case betStatus.win:
    case betStatus.winHalf:
      return "Win";
    case betStatus.lose:
    case betStatus.loseHalf:
      return "Lose";
    case betStatus.stakeRefund:
      return "Refund";
    case betStatus.cancelled:
      return "Cancelled";
    case betStatus.placed:
    default:
      return "Unknown";
  }
};

export const notificationTypes = {
  success: "success",
  warning: "warning",
  error: "error",
  deposit: "deposit",
  withdraw: "withdraw",
  bonus: "bonus",
  info: "info",
  announcement: "announcement",
};

export const notificationType = (x) => {
  switch (x.type) {
    case notificationItemType.betRejected:
      return notificationTypes.warning;
    case notificationItemType.activateEmail:
    case notificationItemType.bonusCanceled:
    case notificationItemType.selfExcludeEmail:
      return notificationTypes.info;
    case notificationItemType.emailActivated:
      return notificationTypes.success;
    case notificationItemType.bonusWelcome:
    case notificationItemType.promoCodeActivated:
    case notificationItemType.bonusActivated:
    case notificationItemType.bonusCredited:
      return notificationTypes.bonus;
    case notificationItemType.betCompleted:
      const status = getBetStatus(x?.betData || {});
      switch (status) {
        case betStatus.win:
        case betStatus.winHalf:
          return notificationTypes.success;
        case betStatus.loseHalf:
        case betStatus.lose:
          return notificationTypes.error;
        default:
          return notificationTypes.info;
      }

    default:
      return notificationTypes.info;
  }
};

export const getBetRejectedMessageShort = (x) => {};

export const getBetRejectedMessage = (x) => {};

export const notificationIcons = {
  [notificationTypes.success]: { icon: faCheckCircle, color: "#73E698" },
  [notificationTypes.warning]: {
    icon: faExclamationTriangle,
    color: "#FFC107",
  },
  [notificationTypes.error]: { icon: faExclamationCircle, color: "#E4606D" },
  [notificationTypes.deposit]: { icon: faWallet, color: "#73E698" },
  [notificationTypes.withdraw]: { icon: faWallet, color: "#E4606D" },
  [notificationTypes.bonus]: { icon: faGift, color: "#73E698" },
  [notificationTypes.info]: { icon: faInfoCircle, color: "#D8DADF" },
  [notificationTypes.announcement]: { icon: faNewspaper, color: "white" },
};

export const notificationsCategories = {
  wallet: "wallet",
  bets: "bets",
  casino: "casino",
  promo: "promo",
  announcements: "announcements",
};
