import {
  LOGOUT_COMPLETE,
  TFA_CONFIRM_CHANGE_CODE,
  TFA_CONFIRM_ERROR,
  TFA_CONFIRM_LOADING,
  TFA_CONFIRM_LOCK,
  TFA_CONFIRM_TOGGLE_RECOVERY,
  TFA_CONFIRM_UNLOCK,
} from "actions/actionTypes";

const initialState = {
  locked: false,
  code: "",
  error: null,
  loading: false,
  recovery: false,
};

const userTfaReducer = (state = initialState, action) => {
  switch (action.type) {
    case TFA_CONFIRM_LOCK:
      return { ...initialState, locked: true };
    case TFA_CONFIRM_UNLOCK:
      return initialState;
    case TFA_CONFIRM_ERROR:
      return { ...state, loading: false, error: action.payload?.error };
    case TFA_CONFIRM_TOGGLE_RECOVERY:
      return { ...state, code: "", recovery: !state.recovery, error: null };
    case TFA_CONFIRM_CHANGE_CODE:
      return {
        ...state,
        error: null,
        code: action.payload?.code,
      };
    case TFA_CONFIRM_LOADING:
      return { ...state, error: null, loading: true };
    case LOGOUT_COMPLETE:
      return initialState;
    default:
      return state;
  }
};

export default userTfaReducer;
