import { marketType } from "@sbetdev2/dbmodel/enums";
import cx from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

import { sports } from "constants/sports";

import DateList from "components/data/DateList";
import EventShort from "components/sports/EventShort";
import NoEventsPlaceholder from "components/sports/NoEventsPlaceholder.tsx";

import { getSportMainMarket } from "utils/eventUtils";

import { getEventUrl } from "../../utils/urlUtils.ts";
import NavLink from "../common/NavLink";

const sortingWithLiveAndStarts = (a, b) => {
  switch (true) {
    case !!a.liveStream && !!b.liveStream && !!a.starts && !!b.starts:
      return new Date(a.starts) - new Date(b.starts);
    case !!a.liveStream:
      return -1;
    case b.liveStream:
      return 1;
    default:
      return 0;
  }
};

const DateHeader = ({ date, sportId, t, isExpanded }) => {
  const winner3 = getSportMainMarket(sportId) === marketType.winner3Match;

  const mlHeader = [
    sports.esports,
    sports.cricket,
    sports.hockey,
    sports.rugbyleague,
  ].includes(sportId)
    ? t("ML")
    : winner3
    ? "1x2"
    : "H/A";

  return (
    <>
      {isExpanded && (
        <div className="events-container">
          <div className="events-date">{date}</div>
          <div className="bet-ml">{t(mlHeader)}</div>
          <div className="bet-hdp">{t("Handicap")}</div>
          <div className="bet-live-stream" />
          <div className="bet-total">{t("Total")}</div>
        </div>
      )}
    </>
  );
};

DateHeader.propTypes = {
  date: PropTypes.string,
  sportId: PropTypes.number,
  t: PropTypes.func,
};

const EventsList = ({ sportId, events, live, isExpanded, isEmptyExpanded }) => {
  const { t } = useTranslation();

  if (isEmptyExpanded) return <NoEventsPlaceholder />;

  return (
    <DateList
      asc
      items={events}
      getDate={(x) => x.starts}
      customSorting={live ? sortingWithLiveAndStarts : undefined}
      renderHeader={(d) =>
        isExpanded && (
          <DateHeader
            sportId={sportId}
            key={d}
            date={!live ? d : ""}
            t={t}
            isExpanded={isExpanded}
          />
        )
      }
      render={(e) =>
        isExpanded ? (
          <EventShort key={e._id} event={e} t={t} />
        ) : (
          <NavLink
            key={e._id}
            to={getEventUrl(e)}
            relatedIds={{
              sportId: e.sport,
              leagueId: e.league,
              eventId: e._id,
            }}
            className={cx("event-short", "unvisible")}
          />
        )
      }
    />
  );
};

EventsList.propTypes = {
  sportId: PropTypes.number,
  events: PropTypes.array,
  live: PropTypes.bool,
};

export default EventsList;
