import { Tokens } from "@sbetdev2/sbet-utils/constants";
import { eosApiEstimatedFee } from "api/eosApi2";
import {
  fetchAccountAddresses,
  fetchAccountBalances,
  fetchExchangeRates,
  fetchFiatExchangeRates,
  fetchPTokenWalletAddress,
} from "api/walletApi";

import { getEosAccount } from "selectors/loginSelectors";
import { getWalletAddresses, getWalletBalances } from "selectors/walletSelectors";

import {
  PTOKEN_ADDRESS_ERROR,
  PTOKEN_ADDRESS_LOADED,
  PTOKEN_ADDRESS_LOADING,
  WALLET_ADDRESSES_LOADED,
  WALLET_ADDRESSES_LOADING,
  WALLET_ADDRESSES_LOAD_ERROR,
  WALLET_BALANCES_LOADED,
  WALLET_BALANCES_LOADING,
  WALLET_BALANCES_LOAD_ERROR,
  WALLET_CASINO_BALANCES_LOADED,
  WALLET_EXCHANGE_RATES,
  WALLET_EXCHANGE_RATES_FIAT,
  WALLET_SET_ACTIVE_CURRENCY,
  WALLET_SET_ACTIVE_TAB,
} from "actions/actionTypes";
import { changeCurrency } from "actions/currencyActions";
import { setShowBonus } from "actions/uiActions";

import { bonusCurrenciesOrder, currenciesOrderSocial } from "constants/currencies.ts";

import { getSavedCurrency } from "utils/browserUtils";
import { currencyAmountAvailable } from "utils/currencyUtils";

export const loadPTokenAddress = (currency) => async (dispatch, getState) => {
  const walletAddress = getEosAccount(getState());
  if (!walletAddress) return;
  dispatch({ type: PTOKEN_ADDRESS_LOADING });
  try {
    const address = await fetchPTokenWalletAddress(walletAddress, currency);
    dispatch({ type: PTOKEN_ADDRESS_LOADED, payload: { currency, address } });
  } catch (e) {
    dispatch({ type: PTOKEN_ADDRESS_ERROR, payload: e && e.message });
  }
};

export const loadExchangeRates = () => async (dispatch) => {
  const data = await fetchExchangeRates();
  if (!data) return;
  const { fiat, crypto } = data;
  const rates = { fiat: {}, crypto: {}, cryptoUSDT: {} };
  Array.isArray(fiat) &&
    fiat.forEach((item) => {
      rates.fiat[item.currency] = item.rate;
    });
  Array.isArray(crypto) &&
    crypto.forEach((item) => {
      rates.crypto[item.currency] = item.rate;
      rates.cryptoUSDT[item.currency] = item.rateUSDT;
    });

  dispatch({
    type: WALLET_EXCHANGE_RATES,
    payload: { rates },
  });
};

export const loadFiatExchangeRates = () => async (dispatch) => {
  const data = await fetchFiatExchangeRates();
  if (!data) return;
  const rates = {};
  data.forEach((item) => {
    rates[item.currency] = item.rate;
  });

  dispatch({
    type: WALLET_EXCHANGE_RATES_FIAT,
    payload: { rates },
  });
};

export const walletSetActiveCurrency = (currency) => ({
  type: WALLET_SET_ACTIVE_CURRENCY,
  payload: { currency },
});

export const walletSetActiveTab = (tab) => ({
  type: WALLET_SET_ACTIVE_TAB,
  payload: { tab },
});

export const walletLoadBalances = () => async (dispatch, getState) => {
  const accountName = getEosAccount(getState());
  const pathname = window?.location?.pathname;
  const isCasinoPage = pathname.includes("casino");
  const { loading } = getWalletBalances(getState());
  if (loading || !accountName) return;
  const currencies = currenciesOrderSocial;
  dispatch({ type: WALLET_BALANCES_LOADING });
  try {
    const { balances: mainBalances, casinoBalance } = await fetchAccountBalances(
      accountName
    );
    const balances = isCasinoPage ? casinoBalance : mainBalances;
    loadFiatExchangeRates(balances);
    const selectedCurrency = getSavedCurrency();
    const basicCurrenciesAvailable = currencyAmountAvailable({ balances, currencies });
    const bonusCurrenciesAvailable = currencyAmountAvailable({
      balances,
      currencies: bonusCurrenciesOrder,
    });

    //select bonus as active currency if main currencies is empty
    if (balances && !basicCurrenciesAvailable.length && bonusCurrenciesAvailable.length) {
      dispatch(changeCurrency(bonusCurrenciesAvailable[0]));
      dispatch(setShowBonus(true));
    }
    //select main balance if bonus was selected, but bonus amount is empty now
    if (
      balances &&
      bonusCurrenciesOrder.includes(selectedCurrency ?? "") &&
      !bonusCurrenciesAvailable.length
    ) {
      dispatch(changeCurrency(basicCurrenciesAvailable[0] || currencies[0]));
      dispatch(setShowBonus(false));
    }
    dispatch({ type: WALLET_BALANCES_LOADED, payload: mainBalances });
    dispatch({ type: WALLET_CASINO_BALANCES_LOADED, payload: casinoBalance });
  } catch (e) {
    dispatch({
      type: WALLET_BALANCES_LOAD_ERROR,
      payload: "Cannot load balances",
    });
  }
};

export const walletLoadAddresses = () => async (dispatch, getState) => {
  const accountName = getEosAccount(getState());
  const { loading } = getWalletAddresses(getState());
  if (loading || !accountName) return;
  dispatch({ type: WALLET_ADDRESSES_LOADING });

  try {
    const addresses = await fetchAccountAddresses(accountName);
    dispatch({ type: WALLET_ADDRESSES_LOADED, payload: addresses });
  } catch (e) {
    dispatch({
      type: WALLET_ADDRESSES_LOAD_ERROR,
      payload: "Cannot load addresses",
    });
  }
};

export const loadEstimatedFee = async ({
  amount = 0,
  currencyCode = "",
  responseUnits,
  cb = () => {},
}) => {
  const getBaseToken = (token) => {
    switch (token) {
      case Tokens.PBTC:
        return Tokens.BTC;
      case Tokens.PLTC:
        return Tokens.LTC;
      case Tokens.PETH:
        return Tokens.ETH;
      default:
        return token;
    }
  };

  try {
    const token = getBaseToken(currencyCode);
    const { fee } = await eosApiEstimatedFee(amount, token, responseUnits);

    cb(fee);
  } catch (e) {
    console.error(e);
    return null;
  }
};
