import { FC, useEffect, useState } from "react";

import { ReactComponent as BestBtcCasino } from "img/marks/bestBtcCasino.svg";
import { ReactComponent as Cgf } from "img/marks/cgf.svg";
import { ReactComponent as Disclaimer } from "img/marks/disclaimer.svg";
import { ReactComponent as License } from "img/marks/license.svg";

import { Mark } from "./types";

const marksList: Mark[] = [
  {
    icon: <Cgf />,
    alt: "Verified operator",
    height: 36,
    width: 73,
    href: "https://cryptogambling.org/",
  },
  {
    icon: <BestBtcCasino />,
    alt: "Best bitcoin casino award",
    height: 36,
    width: 173,
    href: "",
  },
  {
    icon: <License />,
    alt: "License",
    height: 36,
    width: 88,
  },
  {
    icon: <Disclaimer />,
    alt: "Disclaimer",
    height: 32,
    width: 32,
    href: "https://www.responsiblegambling.org/",
  },
];

export const Marks: FC = () => {
  const [marks, setMarks] = useState<Array<Mark> | []>([]);

  useEffect(() => {
    if (!marks.length) {
      const licenseIdx = marksList.findIndex((mark) => mark.alt === "License");
      if (licenseIdx !== -1 && !!window) {
        marksList[licenseIdx].href = `${window?.location?.origin}/licensecertificate.pdf`;
      }
      setMarks(marksList);
    }
  }, []);

  return (
    <div className="row mt-4">
      <div className="col-12">
        <div
          className="d-flex align-items-center justify-content-center flex-wrap"
          style={{ gap: "24px" }}
        >
          {marks.map(({ icon, alt, href }) => (
            <a
              key={alt}
              href={href || "javascript:void(0);"}
              target={!href ? "_self" : "_blank"}
              rel="noreferrer"
            >
              {icon}
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};
