import Loadable from "react-loadable";

import Loading from "components/common/Loaders/Loading.tsx";

const LazyPromoPage = Loadable({
  loader: () => import(/* webpackChunkName: "promo-page"*/ "./PromoPage.tsx"),
  loading: Loading,
  modules: ["promo-page"],
});

export default LazyPromoPage;
