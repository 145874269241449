import {
  BTC_ADDRESS_LOADED,
  BTC_ADDRESS_LOADING,
  BTC_ADDRESS_LOAD_ERROR,
  BTC_WITHDRAWAL_ERROR,
  BTC_WITHDRAWAL_STARTED,
  BTC_WITHDRAWAL_SUCCESS,
  PTOKEN_ADDRESS_ERROR,
  PTOKEN_ADDRESS_LOADED,
  PTOKEN_ADDRESS_LOADING,
  PTOKEN_WITHDRAWAL_ERROR,
  PTOKEN_WITHDRAWAL_STARTED,
  PTOKEN_WITHDRAWAL_SUCCESS,
  WALLET_ADDRESSES_LOADED,
  WALLET_ADDRESSES_LOADING,
  WALLET_ADDRESSES_LOAD_ERROR,
  WALLET_BALANCES_CLEAR,
  WALLET_BALANCES_LOADED,
  WALLET_BALANCES_LOADING,
  WALLET_BALANCES_LOAD_ERROR,
  WALLET_CASINO_BALANCES_LOADED,
  WALLET_EXCHANGE_RATES,
  WALLET_EXCHANGE_RATES_CRYPTO,
  WALLET_EXCHANGE_RATES_FIAT,
  WALLET_SET_ACTIVE_CURRENCY,
  WALLET_SET_ACTIVE_TAB,
} from "actions/actionTypes";

const initialState = {
  ptokenAddress: {
    addresses: {},
    isLoading: false,
    isLoaded: false,
    hasError: false,
  },
  ptokenWithdrawal: {
    isInProgress: false,
    isSuccessful: false,
    hasError: false,
    errMessage: null,
  },
  btcAddress: {
    address: null,
    isLoading: false,
    isLoaded: false,
    hasError: false,
  },
  btcWithdrawal: {
    isInProgress: false,
    isSuccessful: false,
    hasError: false,
    errMessage: null,
  },
  exchangeRates: {
    fiat: null,
    crypto: null,
    cryptoUSDT: null,
  },
  currency: null,
  tab: null,
  balances: {
    loading: false,
    error: null,
    data: null,
  },
  addresses: {
    loading: false,
    error: null,
    data: null,
  },
  casinoBalances: {
    loading: false,
    error: null,
    data: null,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case BTC_ADDRESS_LOADING: {
      return {
        ...state,
        btcAddress: {
          ...state.btcAddress,
          isLoading: true,
          hasError: false,
          isLoaded: false,
          address: null,
        },
      };
    }
    case PTOKEN_ADDRESS_LOADING: {
      return {
        ...state,
        ptokenAddress: {
          ...state.ptokenAddress,
          isLoading: true,
          hasError: false,
          isLoaded: false,
          // addresses: {},
        },
      };
    }
    case BTC_ADDRESS_LOADED:
      return {
        ...state,
        btcAddress: {
          isLoading: false,
          hasError: false,
          isLoaded: true,
          address: action.payload,
        },
      };
    case PTOKEN_ADDRESS_LOADED: {
      return {
        ...state,
        ptokenAddress: {
          isLoading: false,
          hasError: false,
          isLoaded: true,
          addresses: {
            ...state.ptokenAddress.addresses,
            [action.payload.currency]: action.payload.address,
          },
        },
      };
    }
    case BTC_ADDRESS_LOAD_ERROR:
      return {
        ...state,
        btcAddress: {
          ...state,
          isLoading: false,
          hasError: true,
          isLoaded: false,
          address: null,
        },
      };
    case PTOKEN_ADDRESS_ERROR: {
      return {
        ...state,
        ptokenAddress: {
          ...state,
          isLoading: false,
          hasError: true,
          isLoaded: false,
          // addresses: {},
        },
      };
    }
    case BTC_WITHDRAWAL_STARTED:
      return {
        ...state,
        btcWithdrawal: {
          ...state.btcWithdrawal,
          isInProgress: true,
          isSuccessful: false,
          hasError: false,
          errMessage: null,
        },
      };
    case PTOKEN_WITHDRAWAL_STARTED:
      return {
        ...state,
        ptokenWithdrawal: {
          ...state.ptokenWithdrawal,
          isInProgress: true,
          isSuccessful: false,
          hasError: false,
          errMessage: null,
        },
      };
    case BTC_WITHDRAWAL_SUCCESS:
      return {
        ...state,
        btcWithdrawal: {
          ...state.btcWithdrawal,
          isInProgress: false,
          isSuccessful: true,
          hasError: false,
          errMessage: null,
        },
      };
    case PTOKEN_WITHDRAWAL_SUCCESS:
      return {
        ...state,
        ptokenWithdrawal: {
          ...state.ptokenWithdrawal,
          isInProgress: false,
          isSuccessful: true,
          hasError: false,
          errMessage: null,
        },
      };
    case BTC_WITHDRAWAL_ERROR:
      return {
        ...state,
        btcWithdrawal: {
          ...state.btcWithdrawal,
          isInProgress: false,
          isSuccessful: false,
          hasError: true,
          errMessage: action.payload,
        },
      };
    case PTOKEN_WITHDRAWAL_ERROR:
      return {
        ...state,
        ptokenWithdrawal: {
          ...state.ptokenWithdrawal,
          isInProgress: false,
          isSuccessful: false,
          hasError: true,
          errMessage: action.payload,
        },
      };
    case WALLET_EXCHANGE_RATES:
      return {
        ...state,
        exchangeRates: action.payload.rates,
      };
    case WALLET_EXCHANGE_RATES_FIAT:
      return {
        ...state,
        exchangeRates: {
          ...state.exchangeRates,
          fiat: action.payload.rates,
        },
      };
    case WALLET_EXCHANGE_RATES_CRYPTO:
      return {
        ...state,
        exchangeRates: {
          ...state.exchangeRates,
          crypto: action.payload.rates,
        },
      };
    case WALLET_SET_ACTIVE_CURRENCY:
      return {
        ...state,
        currency: action.payload.currency,
      };
    case WALLET_SET_ACTIVE_TAB:
      return {
        ...state,
        tab: action.payload.tab,
      };
    case WALLET_BALANCES_LOADING:
      return {
        ...state,
        balances: {
          ...state.balances,
          loading: true,
          error: null,
          // data: null,
        },
      };
    case WALLET_BALANCES_LOADED:
      return {
        ...state,
        balances: {
          loading: false,
          error: null,
          data: action.payload,
        },
      };
    case WALLET_BALANCES_LOAD_ERROR:
      return {
        ...state,
        balances: {
          ...state.balances,
          loading: false,
          error: action.payload,
          // data: null,
        },
      };
    case WALLET_CASINO_BALANCES_LOADED:
      return {
        ...state,
        casinoBalances: {
          loading: false,
          error: null,
          data: action.payload,
        },
      };
    case WALLET_BALANCES_CLEAR:
      return {
        ...state,
        // balances: initialState.balances
      };
    case WALLET_ADDRESSES_LOADING:
      return {
        ...state,
        addresses: {
          ...state.addresses,
          loading: true,
          error: null,
        },
      };
    case WALLET_ADDRESSES_LOADED:
      return {
        ...state,
        addresses: {
          loading: false,
          error: null,
          data: action.payload,
        },
      };
    case WALLET_ADDRESSES_LOAD_ERROR:
      return {
        ...state,
        addresses: {
          ...state.addresses,
          loading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};
