import { combineReducers } from "redux";

import game from "./game";
import gameList from "./gameList";
import gameLobby from "./gameLobby";
import history from "./history";

const reducer = combineReducers({
  gameList,
  gameLobby,
  game,
  history,
});

export default reducer;
