import denverLogo from "images/login/landing/teams/denver.png";
import lakersLogo from "images/login/landing/teams/lakers.png";
import bonusUEFA from "images/login/landing/uefa/bonusUEFA.png";
import { ReactComponent as RightLogoUEFA } from "images/login/landing/uefa/header/rightLogo.svg";
import liveUEFA from "images/login/landing/uefa/live.mp4";
import englandLogo from "images/login/landing/uefa/teams/england.png";
import sloveniaLogo from "images/login/landing/uefa/teams/slovenia.png";
import liveNBA from "images/login/promo/live.mp4";
import { ReactComponent as LogoUEFA } from "images/logo-short.svg";

export const landingVariants = {
  basketball: {
    signupButtonText: "BET NOW",
  },
  soccer: {
    signupButtonText: "Sign up",
  },
  hockey: {
    signupButtonText: "GRAB A BONUS",
  },
  cybersport: {
    signupButtonText: "Sign up WITH BONUS",
  },
  nba1: {
    custom: true,
    withCta: true,
    extraFixture: true,
    signupButtonText: "BET NOW",
  },
  nba2: {
    centered: true,
    withCta: true,
    extraFixture: true,
    signupButtonText: "JOIN WITH BONUS",
  },
  nbalive: {
    live: true,
    centered: true,
    withCta: true,
    extraFixture: true,
    signupButtonText: "BET WITH BONUS",
    packId: "653a4b52fe7fdc671616b897",
    liveVideo: liveNBA,
    teams: [
      {
        img: lakersLogo,
        title: "LA Lakers",
        score: "+208",
      },
      {
        img: denverLogo,
        title: "Denver Nuggets",
        score: "-238",
      },
    ],
    customCheckboxList: [
      { text: "LIVE streams" },
      { text: "No account verification" },
      { text: "Instant payouts" },
      { text: "Various payment methods" },
    ],
  },
  uefalive: {
    live: true,
    centered: true,
    extraFixture: true,
    extraFixtureTitle: "Platform #1 \n based on \n user reviews",
    signupButtonText: "JOIN WITH BONUS",
    packId: "653a4b52fe7fdc671616b897",
    customBonusImage: bonusUEFA,
    liveVideo: liveUEFA,
    CustomHeaderLogo: LogoUEFA,
    customHeaderLogoStyles: {
      viewBox: "12 23 62 40",
      width: "40",
      height: "40",
    },
    CustomHeaderRightComponent: RightLogoUEFA,
    hasHeaderSeparateRowsMobile: false,
    teams: [
      {
        img: englandLogo,
        title: "England",
        score: "1.33",
      },
      {
        draw: "x \n 4.90",
      },
      {
        img: sloveniaLogo,
        title: "Slovenia",
        score: "9.31",
      },
    ],
    customCheckboxList: [
      { text: "LIVE streams" },
      { text: "No account verification" },
      { text: "Instant payouts" },
      { text: "Various payment methods" },
    ],
  },
};

export const mainCheckboxList = [
  { text: "No account verification" },
  { text: "Instant payouts" },
  { text: "Various payment methods" },
  { text: "24/7 customer support" },
];

export const footerLinkList = [
  { text: "Telegram EN", link: "https://t.me/SportBetOfficial" },
  { text: "Telegram RU", link: "https://t.me/SportBetOfficialRU" },
  { text: "Twitter", link: "https://twitter.com/SportBetOne" },
];
