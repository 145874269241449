import Cookies from "js-cookie";

import { HydrationState } from "utils/generalUtils.ts";

const useIsLoggedIn = () => {
  const state = HydrationState.getInstance().getState();
  return state ? state.isLoggedIn : !!Cookies.get("auth_token");
};

export default useIsLoggedIn;
