import { walletLoadBalances } from "actions/walletActions";

export default (dispatch) => {
  const monitor = async () => {
    try {
      await dispatch(walletLoadBalances());
    } catch {
      // do nothing
    } finally {
      setTimeout(monitor, 30000);
    }
  };
  monitor();
};
