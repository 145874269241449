import { FC } from "react";
import ContentLoader from "react-content-loader";

type Props = Record<string, string | number> | undefined;

const SkeletonLoader: FC<Props> = (props) => (
  <ContentLoader
    speed={2}
    width={298}
    height={48}
    viewBox="0 0 298 48"
    backgroundColor="#354361"
    foregroundColor="#405071"
    {...props}
  >
    <rect x="0" y="0" rx="4" ry="4" width="298" height="48" />
  </ContentLoader>
);

export default SkeletonLoader;
