import {
  BONUS_ACTIVE_ERROR,
  BONUS_ACTIVE_LOADED,
  BONUS_ACTIVE_LOADING,
} from "actions/actionTypes";

const initialState = {
  bonus: null,
  loading: false,
  loaded: false,
  error: null,
};

const activeBonusReducer = (state = initialState, action) => {
  switch (action.type) {
    case BONUS_ACTIVE_LOADING:
      return { ...state, loading: true };
    case BONUS_ACTIVE_LOADED:
      return { ...state, loaded: true, loading: false, bonus: action.payload?.bonus };
    case BONUS_ACTIVE_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload?.error,
      };
    default:
      return state;
  }
};

export default activeBonusReducer;
