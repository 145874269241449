import React, { useState } from "react";

const Checkbox = ({ id, label, onChange, defaultChecked = false, disabled = false }) => {
  const [checked, setChecked] = useState(defaultChecked);

  const handleChange = () => {
    if (disabled) return;
    setChecked(!checked);
    if (typeof onChange === "function") {
      onChange(!checked);
    }
  };

  return (
    <div className="checkbox" style={{ justifyContent: "center" }}>
      <div className="checkbox__input">
        <input
          id={id}
          type="checkbox"
          onChange={(e) => {
            e.stopPropagation();
            handleChange();
          }}
          checked={checked}
        />
        <span
          className="checkbox__checkmark"
          onClick={(e) => {
            e.stopPropagation();
            handleChange();
          }}
        />
      </div>
      {label ? (
        <label
          htmlFor={id}
          onClick={(e) => {
            e.stopPropagation();
            handleChange();
          }}
        >
          {label}
        </label>
      ) : null}
    </div>
  );
};

export default Checkbox;
