import Loadable from "react-loadable";

const LazySignInPopup = Loadable({
  loader: () => import(/* webpackChunkName: "signin-popup"*/ "./SignInPopup"),
  loading: () => null,
  modules: ["signin-popup"],
});

const LazySignUpPopup = Loadable({
  loader: () => import(/* webpackChunkName: "signup-popup"*/ "./SignUpPopup"),
  loading: () => null,
  modules: ["signup-popup"],
});

export { LazySignInPopup, LazySignUpPopup };
