import { LOCATION_CHANGE } from "connected-react-router";

import {
  SET_EVENT_GROUP_OPEN_STATE,
  SET_FIAT_VIEW,
  SIDE_MENU_TOGGLE,
} from "actions/actionTypes";

import { localStorageUtils } from "utils/browserUtils";

const initialState = {
  sideMenu: {
    isOpened: false,
  },
  viewFiat: !!+localStorageUtils.getItem("fiat_view"),
  eventGroups: {},
};

export default (state = initialState, action) => {
  const { sideMenu } = state;
  switch (action.type) {
    case LOCATION_CHANGE:
      return {
        ...state,
        sideMenu: {
          ...sideMenu,
          isOpened: false,
        },
      };
    case SIDE_MENU_TOGGLE:
      return {
        ...state,
        sideMenu: {
          ...sideMenu,
          isOpened: !sideMenu.isOpened,
        },
      };
    case SET_FIAT_VIEW:
      return {
        ...state,
        viewFiat: action.payload && action.payload.visible,
      };
    case SET_EVENT_GROUP_OPEN_STATE:
      return {
        ...state,
        eventGroups: {
          ...state.eventGroups,
          [action.payload.id]: !!action.payload.open,
        },
      };
    default:
      return state;
  }
};
