import { broadcastTransaction, eosApiGetRows, getEosBalance } from "api/eosApi";
import Eos from "eosjs";
import ScatterJS from "scatterjs-core";
import ScatterEOS from "scatterjs-plugin-eosjs";

import { appName, networkConfig } from "constants/eos";

import { formatAssetQty, getTokenAmount, getTokenName } from "utils/eosUtils";

import eosConfig from "../../constants/eos";

export { getTokenAmount, getTokenName, formatAssetQty };

const network = ScatterJS.Network.fromJson(networkConfig);

ScatterJS.plugins(new ScatterEOS());

let lastAccount = null;

const doConnect = async () => {
  let connected = await ScatterJS.connect(appName, { network });
  if (!connected) connected = await ScatterJS.connect(appName, { network });
  if (!connected) throw new Error("NO_EOS");
  const eos = ScatterJS.eos(network, Eos);
  return { eos };
};

let connectTask = null;
const connect = async () => {
  if (connectTask === null) connectTask = doConnect();
  try {
    return await connectTask;
  } catch (e) {
    connectTask = null;
    throw e;
  }
};

// ****************** GENERAL

export const getRows = async ({
  code,
  scope,
  table,
  lower_bound,
  upper_bound,
  limit,
  reverse = false,
}) => {
  //const { eos } = await connect();
  try {
    const result = await eosApiGetRows({
      code,
      scope: typeof scope !== "undefined" ? scope : code,
      table,
      limit,
      show_payer: false,
      reverse,
      lower_bound,
      upper_bound,
    });
    return result ? result.rows : [];
  } catch (e) {
    return [];
  }

  /* const result = await eosRpc.getTableRows({
    //(await rpc.get_table_rows({
    json: true,
    code,
    scope: typeof scope !== "undefined" ? scope : code,
    table,
    limit,
    show_payer: false,
    reverse,
    lower_bound,
    upper_bound,
  }); */
  // return result.rows;
};

export const getBalance = async (code, account, token) => {
  // const balance = await eosRpc.getCurrencyBalance(code, account, token);
  const balance = await getEosBalance(code, account, token);
  return balance ? getTokenAmount(balance) : 0;
};

export const logout = async () => {
  //await connect();
  lastAccount = null;
  if (!ScatterJS.forgetIdentity) throw new Error("Logout failed");
  await ScatterJS.forgetIdentity();
};

export const login = async () => {
  try {
    await connect();
    const id = await ScatterJS.login();
    if (!id) throw new Error("Login failed");
    return ScatterJS.account("eos");
  } catch (e) {
    return { error: e };
  }
};

export const init = async () => {
  try {
    await connect();
    const identity = await ScatterJS.scatter.getIdentity({ accounts: [network] });
    const account =
      identity &&
      identity.accounts &&
      identity.accounts.find((x) => x.blockchain === "eos");
    lastAccount = account;

    if (account && account.publicKey && !account.publicKey.startsWith("EOS")) {
      throw new Error(
        "Scatter init failed: wrong public key format: " + account.publicKey
      );
    }
    return account;
    /* if (ScatterJS.scatter && ScatterJS.scatter.getIdentity)
      await ScatterJS.scatter.getIdentity();
    return ScatterJS.account("eos"); */
  } catch (e) {
    return { error: e };
  }
};

const getCurrentAccount = async () => {
  const account = await init(); // await login();
  if (!account || !!account.error) throw new Error("Not Logged In");
  return account;
};

export const transact = async (trans, extend = {}) => {
  const { eos } = await connect();
  await ScatterJS.login();
  const account = lastAccount || (await getCurrentAccount());
  const action = typeof trans !== "function" ? { ...trans } : trans(account);
  const { authorization: extendAuthorization = [], options: extendOptions = {} } = extend;

  const options = {
    authorization: [
      ...extendAuthorization,
      `${account.name}@${account.authority || "active"}`,
    ],
    ...extendOptions,
  };

  if (!extendAuthorization.length) {
    const transferTo =
      // getPossibleAccounts().includes(action.account) &&
      action.name === "transfer" && action.data.from === account.name;

    if (transferTo) {
      const { from, to, quantity, memo } = action.data;

      if (action.account === "eosio.token") {
        return await eos.transfer(from, to, quantity, memo, options);
      } else {
        const tokenContract = await eos.contract(action.account);
        return await tokenContract.transfer(from, to, quantity, memo, options);
      }
    }
  }

  if (Array.isArray(action)) {
    action.map((x) => {
      x.authorization = [
        ...extendAuthorization.map((x) => {
          const split = x.split("@");
          return {
            actor: split[0],
            permission: split[1],
          };
        }),
        {
          actor: account.name,
          permission: account.authority || "active",
        },
      ];
      return x;
    });
  } else {
    action.authorization = [
      ...extendAuthorization.map((x) => {
        const split = x.split("@");
        return {
          actor: split[0],
          permission: split[1],
        };
      }),
      {
        actor: account.name,
        permission: account.authority || "active",
      },
    ];
  }

  console.log("eos.transaction", JSON.stringify(action), JSON.stringify(extendOptions));

  const result = await eos.transaction(
    { actions: Array.isArray(action) ? action : [action] },
    extendOptions
  );
  console.log("eos.transaction resp", JSON.stringify(result));

  return result;
};

export const makeTransaction = async (trx, broadcast = true, headers = {}) => {
  const transaction = await transact(trx, {
    authorization: [
      `${eosConfig.signTransactionBy.name}@${eosConfig.signTransactionBy.authority}`,
    ],
    options: { broadcast: false },
  });
  if (!broadcast) return transaction;
  const { result } = await broadcastTransaction(transaction.transaction, headers);
  return result;
};
