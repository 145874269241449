import Loadable from "react-loadable";

import Loading from "components/common/Loaders/Loading.tsx";

const LazyGamePage = Loadable({
  loader: () => import(/* webpackChunkName: "game-page"*/ "./GamePage.tsx"),
  loading: Loading,
  modules: ["game-page"],
});

export default LazyGamePage;
