import {
  USER_TFA_SETTINGS_ACTIVATE_ERROR,
  USER_TFA_SETTINGS_ACTIVATE_LOADED,
  USER_TFA_SETTINGS_ACTIVATE_LOADING,
  USER_TFA_SETTINGS_ACTIVATE_RESET,
  USER_TFA_SETTINGS_CLEAR,
  USER_TFA_SETTINGS_DEACTIVATE_ERROR,
  USER_TFA_SETTINGS_DEACTIVATE_LOADED,
  USER_TFA_SETTINGS_DEACTIVATE_LOADING,
  USER_TFA_SETTINGS_DEACTIVATE_RESET,
  USER_TFA_SETTINGS_ENABLE_ERROR,
  USER_TFA_SETTINGS_ENABLE_LOADED,
  USER_TFA_SETTINGS_ENABLE_LOADING,
  USER_TFA_SETTINGS_ERROR,
  USER_TFA_SETTINGS_LOADED,
  USER_TFA_SETTINGS_LOADING,
  USER_TFA_SETTINGS_RECOVERY_ERROR,
  USER_TFA_SETTINGS_RECOVERY_LOADED,
  USER_TFA_SETTINGS_RECOVERY_LOADING,
  USER_TFA_SETTINGS_RECOVERY_RESET,
  USER_TFA_SETTINGS_RESET_ERROR,
  USER_TFA_SETTINGS_RESET_LOADED,
  USER_TFA_SETTINGS_RESET_LOADING,
} from "actions/actionTypes";

const initialState = {
  enabled: false,
  activated: false,
  secret: null,
  recovery: null,
  loading: false,
  loaded: false,
  error: null,
  activate: {
    loading: false,
    error: null,
  },
  deactivate: {
    loading: false,
    error: null,
  },
  showRecovery: {
    loading: false,
    error: null,
  },
};

const userTfaSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_TFA_SETTINGS_LOADING:
    case USER_TFA_SETTINGS_ENABLE_LOADING:
    case USER_TFA_SETTINGS_RESET_LOADING:
      return { ...state, loading: true };
    case USER_TFA_SETTINGS_LOADED:
      return {
        ...state,
        loading: false,
        loaded: true,
        enabled: !!action.payload?.settings?.enabled,
        activated: !!action.payload?.settings?.activated,
        secret: action.payload?.settings?.secret,
      };
    case USER_TFA_SETTINGS_ENABLE_LOADED:
      return {
        ...state,
        loading: false,
        error: null,
        secret: action.payload?.tfaSecret,
      };
    case USER_TFA_SETTINGS_RESET_LOADED:
      return { ...initialState, loaded: true };
    case USER_TFA_SETTINGS_ERROR:
    case USER_TFA_SETTINGS_ENABLE_ERROR:
    case USER_TFA_SETTINGS_RESET_ERROR:
      return { ...initialState, error: action.payload?.error };
    case USER_TFA_SETTINGS_DEACTIVATE_LOADED:
    case USER_TFA_SETTINGS_CLEAR:
      return { ...initialState };
    case USER_TFA_SETTINGS_ACTIVATE_LOADING:
      return { ...state, activate: { loading: true, error: null } };
    case USER_TFA_SETTINGS_DEACTIVATE_LOADING:
      return { ...state, deactivate: { loading: true, error: null } };
    case USER_TFA_SETTINGS_RECOVERY_LOADING:
      return { ...state, showRecovery: { loading: true, error: null } };
    case USER_TFA_SETTINGS_ACTIVATE_LOADED:
      return {
        ...state,
        activate: { ...initialState.activate },
        activated: true,
        secret: null,
        recovery: action.payload?.recovery,
      };
    case USER_TFA_SETTINGS_RECOVERY_LOADED:
      return {
        ...state,
        showRecovery: { ...initialState.showRecovery },
        recovery: action.payload?.recovery,
      };
    case USER_TFA_SETTINGS_ACTIVATE_ERROR:
      return {
        ...state,
        activate: { loading: false, error: action.payload?.error },
      };
    case USER_TFA_SETTINGS_DEACTIVATE_ERROR:
      return {
        ...state,
        deactivate: { loading: false, error: action.payload?.error },
      };
    case USER_TFA_SETTINGS_RECOVERY_ERROR:
      return {
        ...state,
        showRecovery: { loading: false, error: action.payload?.error },
      };
    case USER_TFA_SETTINGS_ACTIVATE_RESET:
      return {
        ...state,
        activate: { ...initialState.activate },
      };
    case USER_TFA_SETTINGS_DEACTIVATE_RESET:
      return {
        ...state,
        deactivate: { ...initialState.deactivate },
      };
    case USER_TFA_SETTINGS_RECOVERY_RESET:
      return {
        ...state,
        showRecovery: { ...initialState.showRecovery },
      };
    default:
      return state;
  }
};

export default userTfaSettingsReducer;
