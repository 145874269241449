import api, { addAuth } from "api/api";

export const fetchRecentNotifications = async (token, lastId) => {
  const response = await api.get(
    `/notifications/recent${lastId ? `?lastId=${lastId}` : ""}`,
    addAuth(token)
  );
  return response?.data;
};

export const fetchUnreadNotifications = async (token) => {
  const response = await api.get("/notifications/unread", addAuth(token));
  return response?.data;
};

export const fetchReadNotification = async (token, id) => {
  await api.post("/notifications/read", { id }, addAuth(token));
};

export const fetchReadAllNotifications = async (token) => {
  await api.post("/notifications/read/all", {}, addAuth(token));
};

export const sendReadNotificationsByIds = async (token, ids) => {
  await api.post("/notifications/read/by-ids", { ids }, addAuth(token));
};

export const fetchAllNotifications = async (token) => {
  const response = await api.get("/notifications", addAuth(token));
  return response?.data;
};
