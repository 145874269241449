import betReducer from "reducers/betReducer";
import betsReducer from "reducers/betsReducer";
import bonusReducer from "reducers/bonus";
import casinoReducer from "reducers/casino";
import countryReducer from "reducers/countryReducer";
import dividendsReducer from "reducers/dividendsReducer";
import eventsReducer from "reducers/eventsReducer";
import experimentsReducer from "reducers/experimentsReducer";
import homepageReducer from "reducers/homepageReducer";
import leaderboardReducer from "reducers/leaderboardReducer";
import leaguesReducer from "reducers/leaguesReducer";
import loginReducer from "reducers/loginReducer";
import notificationsReducer from "reducers/notificationsReducer";
import pageReducer from "reducers/pageReducer";
import riskFreeReducer from "reducers/riskFreeReducer";
import settingsReducer from "reducers/settingsReducer";
import sliderReducer from "reducers/sliderReducer";
import sportsReducer from "reducers/sportsReducer";
import uiReducer from "reducers/uiReducer";
import unsubscribeReducer from "reducers/unsubscribeReducer";
import userReducer from "reducers/user";
import userStatsReducer from "reducers/userstats";
import walletReducer from "reducers/walletReducer";
import welcomeBonusReducer from "reducers/welcomeBonusReducer";
import zeChatReducer from "reducers/zeChatReducer.ts";

const reducers = {
  page: pageReducer,
  country: countryReducer,
  slider: sliderReducer,
  sports: sportsReducer,
  leagues: leaguesReducer,
  events: eventsReducer,
  wallet: walletReducer,
  ui: uiReducer,
  settings: settingsReducer,
  riskFree: riskFreeReducer,
  leaderboard: leaderboardReducer,
  dividends: dividendsReducer,
  bet: betReducer,
  bets: betsReducer,
  casino: casinoReducer,
  login: loginReducer,
  user: userReducer,
  unsubscribe: unsubscribeReducer,
  homepage: homepageReducer,
  notifications: notificationsReducer,
  welcomeBonus: welcomeBonusReducer,
  bonus: bonusReducer,
  userStats: userStatsReducer,
  experiments: experimentsReducer,
  zeChat: zeChatReducer,
};

export default reducers;
