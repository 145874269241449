import {
  USER_REACTIVATE_ERROR,
  USER_REACTIVATE_SAVED,
  USER_REACTIVATE_SAVING,
  USER_REACTIVATE_TOGGLE,
} from "actions/actionTypes";

const initialState = {
  shown: false,
  error: null,
  loading: false,
  saved: false,
};

const reactivateReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_REACTIVATE_SAVING:
      return { ...state, loading: true, error: null, saved: false };
    case USER_REACTIVATE_SAVED:
      return { ...state, loading: false, saved: true };
    case USER_REACTIVATE_ERROR:
      return { ...state, loading: false, error: action.payload?.error };
    case USER_REACTIVATE_TOGGLE:
      return { ...initialState, shown: !!action.payload?.shown };
    default:
      return state;
  }
};

export default reactivateReducer;
