import React from "react";

import { NavColProps } from "./types";

const NavColumn: React.FC<NavColProps> = ({ title, children }) => {
  return (
    <div className="col-6 col-md-3 p-0" style={{ minWidth: "150px" }}>
      <div className="footer-group-label mb-2">{title}</div>
      {children}
    </div>
  );
};

export default NavColumn;
