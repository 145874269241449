import "flag-icons/css/flag-icons.min.css";

const Factory = ({ code }) => {
  return (
    <span
      className={`fi fi-${code}`}
      style={{ width: "24px", height: "18px", lineHeight: "18px" }}
    />
  );
};

export default Factory;
