import React, { useEffect } from "react";

import { localStorageUtils } from "utils/browserUtils";

import PromoSignInPopup from "./PromoSignInPopup";

const PromoSignInPopup2 = () => {
  useEffect(() => {
    try {
      localStorageUtils.setItem("no-promo-modal", 1);
    } catch (e) {
      // do nothing
    }
  }, []);

  return <PromoSignInPopup path={"/promo-signin"} />;
};

export default PromoSignInPopup2;
