import { COUNTRY, SET_IS_RESTRICTED } from "actions/actionTypes";

import { compoundReducer, fetchReducer, getInitialFetchState } from "utils/reduxUtils";

const payloadKey = "payload";

const initialState = {
  isRestrictedLocation: false,
  currentCountryCode: "",
  [payloadKey]: getInitialFetchState(),
};

const countryReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_RESTRICTED: {
      return {
        ...state,
        isRestrictedLocation: action.payload.isRestrictedLocation,
        currentCountryCode: action.payload.country,
      };
    }
  }
};

export default compoundReducer(
  initialState,
  countryReducer,
  fetchReducer(COUNTRY, payloadKey)
);
