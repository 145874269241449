import { getAuthToken, getError } from "api/api";
import { userTfaRecovery, userTfaUnlock } from "api/userApi";

import { getLoginToken } from "selectors/loginSelectors";
import { getUserTfaConfirm } from "selectors/userSelectors";

import {
  TFA_CONFIRM_CHANGE_CODE,
  TFA_CONFIRM_ERROR,
  TFA_CONFIRM_LOADING,
  TFA_CONFIRM_LOCK,
  TFA_CONFIRM_TOGGLE_RECOVERY,
  TFA_CONFIRM_UNLOCK,
} from "actions/actionTypes";
import { getUserInfo, loginWithToken, logout } from "actions/loginActions.ts";

export const lockTfaConfirm = () => ({
  type: TFA_CONFIRM_LOCK,
});

export const unlockTfaConfirm = (history) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TFA_CONFIRM_LOADING,
    });
    const state = getState();
    const { code, recovery } = getUserTfaConfirm(state);
    let token = getLoginToken(state);
    let shouldLoginWithToken = false;
    if (!token) {
      token = getAuthToken();
      shouldLoginWithToken = true;
    }
    if (!token) {
      dispatch(logout());
      return;
    }
    if (recovery) {
      await userTfaRecovery(token, code);
    } else {
      await userTfaUnlock(token, code);
    }
    dispatch({
      type: TFA_CONFIRM_UNLOCK,
    });
    if (shouldLoginWithToken) {
      dispatch(loginWithToken(token, history));
    } else {
      dispatch(getUserInfo(history));
    }
  } catch (e) {
    const error = getError(e);
    dispatch({
      type: TFA_CONFIRM_ERROR,
      payload: { error },
    });
  }
};

export const toggleTfaConfirmRecovery = () => ({
  type: TFA_CONFIRM_TOGGLE_RECOVERY,
});

export const changeTfaConfirmCode = (code) => ({
  type: TFA_CONFIRM_CHANGE_CODE,
  payload: { code },
});
