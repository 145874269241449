import api from "api/api";
import cx from "classnames";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { Dropdown, DropdownItem, DropdownMenu } from "reactstrap";

import DropdownToggle from "components/common/DropdownToggle.tsx";
import FlagIcon from "components/common/FlagIcon";

import { localStorageUtils } from "utils/browserUtils";
import whitelabel from "utils/whitelabelUtils.ts";

const getCurrentLng = (i18n) => i18n.language || localStorageUtils.getItem("i18nextLng");

const LanguageSelector = ({
  color = "secondary",
  outline = false,
  nav = false,
  block = false,
  showLabel = false,
  right = true,
  caret = false,
  className,
}) => {
  const [open, setOpen] = useState(false);
  const { i18n } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    api.defaults.headers["client-language"] = i18n.language;
  }, [i18n.language]);

  const setLang = async (newLang) => {
    let newLocation =
      newLang === "en"
        ? location.pathname.replace(`/${i18n.language}`, "")
        : i18n.language === "en"
        ? `/${newLang}${location.pathname}`
        : location.pathname.replace(i18n.language, newLang);
    if (location.hash) newLocation += `${location.hash}`;
    history.push(newLocation);
    await i18n.changeLanguage(newLang);
  };

  const languages = whitelabel.languages();
  const current =
    languages.find((x) => x.code === getCurrentLng(i18n)) ||
    languages.find((x) => x.code === whitelabel.defaultLanguage());

  return (
    <Dropdown
      nav={nav}
      className={cx(className, "language-selector-container")}
      size="sm"
      isOpen={open}
      toggle={() => setOpen(!open)}
      direction="down"
    >
      <DropdownToggle
        block={block}
        caret={caret}
        outline={outline}
        color={color}
        caretStacked
        className="text-left"
      >
        <FlagIcon code={current.flag} />
        {showLabel && <span className="ml-2">{current.name}</span>}
      </DropdownToggle>
      <DropdownMenu right={right} className="full-width">
        {languages.map(({ code, name, flag }) => (
          <DropdownItem key={code} onClick={() => setLang(code)}>
            <FlagIcon code={flag} />
            <span className="ml-2">{name}</span>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default LanguageSelector;
