import { oddsFormat } from "constants/odds";

import { localStorageUtils } from "utils/browserUtils";

import {
  SET_FIAT_CURRENCY,
  SET_HIDE_ZERO,
  SET_ODDS_FORMAT,
  SET_SHOW_BONUS,
} from "../actions/actionTypes";

const initialState = {
  oddsFormat:
    Number.parseInt(localStorageUtils.getItem("odds_format"), 10) || oddsFormat.decimal,
  fiatCurrency: localStorageUtils.getItem("fiat_currency") || null,
  hideZero: !!+localStorageUtils.getItem("hide_zero") || null,
  showBonus: !!+localStorageUtils.getItem("show_bonus") || null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ODDS_FORMAT:
      return { ...state, oddsFormat: action.payload };
    case SET_FIAT_CURRENCY:
      return { ...state, fiatCurrency: action.payload };
    case SET_HIDE_ZERO:
      return { ...state, hideZero: action.payload };
    case SET_SHOW_BONUS:
      return { ...state, showBonus: action.payload };
    default:
      return state;
  }
};
