import { useEffect } from "react";

const useClickOutside = ({ ref, onClick, exceptClasses = [] }) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref && ref.current && !ref.current.contains(event.target)) {
        if (exceptClasses.length) {
          for (const className of exceptClasses) {
            if (event.target.classList.contains(className)) {
              return;
            }
          }
        }
        onClick();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref.current, onClick]);
};

export default useClickOutside;
