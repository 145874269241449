export const getCloseUrl = (match, path) => {
  const url = match.url || match.pathname;
  if (Array.isArray(path)) {
    path = path.find((x) => url.endsWith(x));
    if (!path) return url;
  } else if (!url.endsWith(path)) return url;
  return url.substring(0, url.length - path.length);
};

export const closePopup = (match, history, path) => {
  const url = match.url;
  const closeUrl = getCloseUrl(match, path);
  if (url !== closeUrl) {
    history.push(closeUrl);
  }
};
