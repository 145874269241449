import { localStorageUtils } from "./browserUtils";

const CHAT_TAGS_KEY = "chatTags";

type WidgetProps = {
  color: { theme: string; launcher: string; launcherText: string };
  position: { horizontal: string; vertical: string };
  launcher: { badge: { image: string } };
  answerBot: { avatar: { url: string } };
  chat: { concierge: { avatarPath: string } };
};

export type ZeChat = (
  action: string,
  value: string,
  updateSettings?:
    | {
        webWidget: WidgetProps;
      }
    | [],
  identify?: (data: Record<string, string>) => void,
  handler?: (callback: (data: string | number) => void) => void
) => void;

export const zeIsLoaded = () =>
  /* @ts-ignore */
  window && window.zE && typeof window.zE.identify === "function";

export const zeUpdateSettings = () => {
  try {
    if (!window.zE) return;

    window.zE("webWidget", "updateSettings", {
      webWidget: {
        color: { theme: "#73e698", launcher: "#73e698", launcherText: "#000917" },
        position: { horizontal: "right", vertical: "bottom" },
        launcher: {
          badge: {
            image: "https://sportbet.one/img/sbet-chat.png",
          },
        },
        answerBot: {
          avatar: {
            url: "https://sportbet.one/img/sbet-chat.png",
          },
        },
        chat: {
          concierge: {
            avatarPath: "https://sportbet.one/img/sbet-chat.png",
          },
        },
      },
    });
  } catch (e) {
    console.log("window.zE updateSettings error", e);
  }
};

export const zeIdentify = (userName: string) => {
  try {
    /* @ts-ignore */
    window.zE.identify({ name: userName ? userName : "", email: "" });
  } catch (e) {
    console.log("window.zE.identify error", e);
  }
};

/*
 ZENDESK CHAT TAGS
*/

export const getTags = () => {
  let tags = [];
  try {
    tags = JSON.parse(localStorageUtils.getItem(CHAT_TAGS_KEY) ?? "");
  } catch (e) {
    localStorageUtils.setItem(CHAT_TAGS_KEY, JSON.stringify([]));
  }
  return tags;
};

export const setTags = (tags: []) => {
  try {
    window.zE("webWidget", "chat:addTags", tags);
    localStorageUtils.setItem(CHAT_TAGS_KEY, JSON.stringify(tags));
  } catch (e) {
    console.log(e);
  }
};
