import emailReducer from "reducers/user/userEmailReducer";
import excludeReducer from "reducers/user/userExcludeReducer";
import onsiteReducer from "reducers/user/userOnsiteReducer";
import reactivateReducer from "reducers/user/userReactivateReducer";
import subscribeReducer from "reducers/user/userSubscribeReducer";
import userTfaReducer from "reducers/user/userTfaReducer";
import { combineReducers } from "redux";

import changePasswordReducer from "./changePasswordReducer";
import resendConfirmationReducer from "./userResendConfirmation";
import userSettingsReducer from "./userSettingsReducer";
import userTfaSettingsReducer from "./userTfaSettingsReducer";

export default combineReducers({
  settings: userSettingsReducer,
  tfaSettings: userTfaSettingsReducer,
  tfaConfirm: userTfaReducer,
  changePassword: changePasswordReducer,
  exclude: excludeReducer,
  reactivate: reactivateReducer,
  email: emailReducer,
  subscribe: subscribeReducer,
  onsite: onsiteReducer,
  resendConfirmation: resendConfirmationReducer,
});
