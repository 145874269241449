import {
  CHANGE_PASSWORD_ERROR,
  CHANGE_PASSWORD_LOAD,
  CHANGE_PASSWORD_LOADED,
  CHANGE_PASSWORD_RESET,
} from "actions/actionTypes";

const initialState = {
  error: null,
  loading: false,
  saved: false,
};

const changePasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_PASSWORD_LOAD:
      return { ...state, loading: true, error: null, saved: false };
    case CHANGE_PASSWORD_LOADED:
      return { ...state, loading: false, saved: true };
    case CHANGE_PASSWORD_ERROR:
      return { ...state, loading: false, error: action.payload?.error };
    case CHANGE_PASSWORD_RESET:
      return initialState;
    default:
      return state;
  }
};

export default changePasswordReducer;
