export const eventType = {
  unknown: 0,
  prematch: 1,
  live: 2,
};

export const eventStatus = {
  unknown: 0,
  pending: 1,
  live: 2,
  finished: 3,
  deleted: 7,
  cancelled: 8,
  ended: 9,
  noCoverage: 10,
};

export const eventStatusNames = {
  [eventStatus.unknown]: "Unknown",
  [eventStatus.pending]: "Pending",
  [eventStatus.live]: "Live",
  [eventStatus.finished]: "Finished",
  [eventStatus.deleted]: "Event Deleted",
  [eventStatus.cancelled]: "Event Cancelled",
  [eventStatus.ended]: "Ended",
  [eventStatus.noCoverage]: "Out of Coverage",
};

export const leaguesNamesExceptions = ["CS:GO"];

export const betSide = {
  // ml, hdp
  home: 0,
  away: 1,
  draw: 2,
  // total
  over: 0,
  under: 1,
  // odd/even
  even: 0,
  odd: 1,
  // double chance
  homedraw: 0,
  awaydraw: 1,
  homeaway: 2,
  // yes/no
  no: 0,
  yes: 1,
  // score
  none: 0,
};
