import Loadable from "react-loadable";

import Loading from "components/common/Loaders/Loading.tsx";

const LazyCasinoPage = Loadable({
  loader: () => import(/* webpackChunkName: "casino-page"*/ "./CasinoPage.tsx"),
  loading: Loading,
  modules: ["casino-page"],
});

export default LazyCasinoPage;
