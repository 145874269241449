import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { loadActiveBonus } from "../../actions/bonusActions";
import { getUserRiskFreeBonus } from "../../actions/riskFreeActions";
import { bonusStatus } from "../../containers/popups/BonusModal/bonus.utils";
import { getActiveBonus } from "../../selectors/bonus";
import { getAccountUser } from "../../selectors/loginSelectors";
import { getRiskFree } from "../../selectors/promoSelectors";
import { localStorageUtils } from "../../utils/browserUtils";
import DepositBonusStrip from "./DepositBonusStrip";
import RiskFreeBetBonusStrip from "./RiskFreeBetBonusStrip";

const BonusStrip = () => {
  const dispatch = useDispatch();
  const riskFree = useSelector(getRiskFree);
  const isRiskFreeActive = riskFree && riskFree.status === bonusStatus.active;
  const isBonusInfoHidden = localStorageUtils.getItem("hideBonusInfo");
  const { bonus } = useSelector(getActiveBonus);
  const isDepositBonusPending = bonus && bonus.status === bonusStatus.pending;
  const user = useSelector(getAccountUser);
  useEffect(() => {
    dispatch(loadActiveBonus());
    dispatch(getUserRiskFreeBonus());
  }, []);

  if (isBonusInfoHidden || !user) return null;

  if (isRiskFreeActive) {
    return <RiskFreeBetBonusStrip />;
  }

  if (isDepositBonusPending) {
    return <DepositBonusStrip bonus={bonus} />;
  }

  return null;
};

export default BonusStrip;
